import * as firebase from 'firebase';

export const initializeFirebase = () => {
    firebase.initializeApp({
        apiKey: "AIzaSyDZgX6y65w8rP9UaCrxVAyB2iODNr9eZgU",
        authDomain: "mpower-chat.firebaseapp.com",
        databaseURL: "https://mpower-chat.firebaseio.com",
        projectId: "mpower-chat",
        storageBucket: "mpower-chat.appspot.com",
        messagingSenderId: "71217564725",
        appId: "1:71217564725:web:0e7058caffc86399a2757c"
    });


}
export const askForPermissionToReceiveNotification = async() => {
    const messaging = firebase.messaging();
    messaging.requestPermission()
        .then(function() {
            return messaging.getToken()
        })
        .then(function(token) {
            console.log(token);
            localStorage.setItem('notification-token', token)
        })
        .catch(function(err) {
            console.log(err);
        })
}

export const data = firebase;