import React, { Component } from 'react';
import '../css/App.css';
import API from '../config_api'
import axios from 'axios'
import Sidenav from './Sidenav';
import FormInput from '../component/FormInput';
import IdleTimerLogout from './IdleTimerLogout';
class SearchUnit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };
this.handleSubmit=this.handleSubmit.bind(this);
    }

    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }
    }
    btnAction(data) {
        this.props.history.push('/overview', {data})
    }
    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append('token_id', localStorage.getItem('token'))
        formData.append('program_search_key',event.target[0].value)
        axios({
            method: 'post',
            url: API.SearchUnit,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
               if (res.data.status === 'success'){
                this.setState({ data: res.data.data })
               }
               else{
                   alert(res.data.message)
               }
            });
        
    }
    render() {
        return (
            <div className="">
                <IdleTimerLogout/>
                <Sidenav type={'second'} url={'/dashboard'}/>

                <div className="container">
                <div style={{position:'fixed',left:0,right:0,zIndex:1,}}>
                        <div className="container">
                        <div className="row">
                           
                            <div className="col-md-12 text-center" style={{ padding: 15 }}>
                                <h5 style={{color:'white'}}className="size-text">Search</h5>
                            </div>
                        </div>
                      </div>
                      </div>
                      <div style={{height:60}}></div>
                    <form className="col-12 text-center"
                        onSubmit={this.handleSubmit} >
                        <div class="flex search mt-4">
                            <div class="form-group" style={{ width: '100%' }}>
                                <div class="input-group input-group-merge input-group-alternative">
                                    <FormInput name={"old"} placeholder={"Search..."} type={"text"} />
                                </div>
                            </div>
                            <button class="form-control" type="submit" style={{ width: 50, color: '#000', background: '#dcdcdc' }} ><i class="fa fa-search"></i></button>
                        </div>
                    </form>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="trans">
                                {this.state.data.map((data, i) =>
                                    <div className="card justify-content-center row" style={{ padding: 10, margin: 10, height: 55 }} key={i} onClick={() => this.btnAction(data)}>
                                        <div className="col-sm-11 col-xs-11 pad_z">{data.program_title}</div>
                                        <div className="col-sm-1 col-xs-1 pad_z"><i className="material-icons">
                                            keyboard_arrow_right
</i></div>

                                    </div>)}
                            </div></div>
                    </div></div></div>
        )
    }
}
export default SearchUnit;