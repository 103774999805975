export default {
    Enterprise: 'https://app.acuizen.com/company_screens/',
    Login: 'https://app.acuizen.com/userlogin_beta',
    CheckParallel: 'https://app.acuizen.com/check_device_token_beta',
    Area: 'https://app.acuizen.com/assigned_users',
    Fetch: 'https://app.acuizen.com/fetch_all_microstep_beta',
    Dashboard: 'https://app.acuizen.com/webservice/getDashboardData',
    ActionUpdate: 'https://app.acuizen.com/action_msg_update',
    ActionList:'https://app.acuizen.com/action_msg_list ',
    ActionArchive:'https://app.acuizen.com/action_msg_achived_list',
    
    MyAccount: 'https://app.acuizen.com/save_profile',
    AboutUs: 'https://app.acuizen.com/about-us',
    Privacy: 'https://app.acuizen.com/privacy-policy',
    ChangePassword: 'https://app.acuizen.com/change_my_password',
    LogOut: 'https://app.acuizen.com/logout_user',
    Forgot: 'https://app.acuizen.com/userforgotpassword',
    Check: 'https://app.acuizen.com/assigned_checklist',
    Observation: 'https://app.acuizen.com/assigned_observationlist',
    Suggestion: 'https://app.acuizen.com/assigned_suggestionlist',
    Groups: 'https://app.acuizen.com/get_network_list_for_user',
    GroupsTopicList: 'https://app.acuizen.com/knowledge_topic_list',
    UserReport: 'https://app.acuizen.com/user_report',
    TopArea: 'https://app.acuizen.com/top_knowledge_units',
    NotAttentedUnits: 'https://app.acuizen.com/not_attempted_units_by_user',
    UserAreaDropDown: 'https://app.acuizen.com/user_area_dropdown',
    AreaLevelInsight: 'https://app.acuizen.com/user_report_area',
    SubmitUnit: 'https://app.acuizen.com/save_time_spent_multi_beta',
    SubmitPauseUnit: 'https://app.acuizen.com/save_pause_session_multi',
    PauseUnit: 'https://app.acuizen.com/fetch_pause_session',
    PauseSession: 'https://app.acuizen.com/fetch_pause_session_details',
    DeletePauseUnit: 'https://app.acuizen.com/delete_pause_session',
    SearchUnit: 'https://app.acuizen.com/list_programs',
    Notification: 'https://app.acuizen.com/broadcast_msg_list',
    VideoConference: 'https://app.acuizen.com/video_notification',
    CheckVideoConference: 'https://app.acuizen.com/check_video_notification',
    NotificationPollUpdate: 'https://app.acuizen.com/update_poll_broadcast',
    NotificationUpdate: 'https://app.acuizen.com/broadcast_msg_update',
    Group_Notification: 'https://app.acuizen.com/group_notification',

}