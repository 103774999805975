import React, { Component } from "react";
import "../css/App.css";
import API from "../config_api";
import axios from "axios";
import Sidenav from "./Sidenav";
import IdleTimerLogout from "./IdleTimerLogout";
class Checklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentWillMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "https://app.acuizen.com";
    }
    let formData = new FormData();
    formData.append("token_id", localStorage.getItem("token"));
    axios({
      method: "post",
      url: API.Check,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      console.log(res.data.data);

      this.setState({ data: res.data.data });
    });
  }
  startUnit(program_id) {
    this.props.history.push("/units/" + program_id, { checklist: false });
  }
  render() {
    return (
      <div className="">
        <IdleTimerLogout />
        {/* <Sidenav type={'second'} url={'/dashboard'}/> */}

        <nav class="navbar navbar-top navbar-expand navbar-dark  border-bottom">
          <div class="container pad_z">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="col-sm-3 pad_z ">
                <i
                  className="material-icons back-btn grey-text"
                  onClick={() => this.props.history.goBack()}
                  style={{ cursor: "pointer", color: "#005284" }}
                >
                  arrow_back
                </i>
              </div>
              <div
                className="col-sm-6 text-center pad_z"
                style={{
                  fontSize: "16px",
                  color: "#005284",
                  fontWeight: "700",
                }}
              >
                Checklist
              </div>
              <div className="col-sm-3 text-right  pad_z vertical-align justify-content-end"></div>
            </div>
          </div>
        </nav>

        <div className="container">
          {/* <div style={{ position: "fixed", left: 0, right: 0, zIndex: 1 }}>
            <div className="container">
              <div className="row"> */}
          {/* <div className="col-md-12 text-center" style={{ padding: 15 }}>
                                <h5 style={{color:'white'}} className="size-text">Checklists</h5>
                            </div> */}
          {/* </div>
            </div>
          </div> */}
          <div style={{ height: 60 }}></div>
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                {/* <div className="col-12">
              <div className="card m-0 mb-3">
                <p className="m-0" style={{ fontSize: 14 }}>
                  Click and select the relevant checklist for your reporting
                </p>
              </div>
            </div> */}
                <div
                  className="p-4 mt-4"
                  style={{ background: "#ffffff", borderRadius: "22px" }}
                >
                  <div className="row az-hover">
                    {this.state.data.map((data, index) => (
                      <div
                        className="col-12 "
                        style={{ marginTop: "28px" }}
                        key={index}
                      >
                        <div
                          className="card py-4 az-hover-bg"
                          style={{ background: "#f3f3f3", cursor: "pointer" }}
                          onClick={() => this.startUnit(data.program_id)}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div>
                              <p
                                className="mb-0"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#005284",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.title}
                              </p>
                            </div>
                            <div
                              style={{
                                border: ".75px solid #3c3c3c",
                                color: "#3c3c3c",
                                borderRadius: "50%",
                                height: "25px",
                                width: "25px",
                              }}
                            >
                              <i className="material-icons">
                                keyboard_arrow_right
                              </i>
                            </div>
                          </div>
                        </div>

                        {/* <div className="trans">
                  <div
                    className="unit_assign col-sm-9 "
                    key={index}
                    onClick={() => this.startUnit(data.program_id)}
                    style={{ borderRadius: 10, margin: "auto", height: "50px" }}
                  >
                    <div className="col-sm-11 col-xs-11 pad_z">
                      {data.title}
                    </div>
                    <div
                      className="text-right"
                      style={{
                        border: "1px solid",
                        borderRadius: "50%",
                        height: "25px",
                        width: "25px",
                      }}
                    >
                      <i className="material-icons">keyboard_arrow_right</i>
                    </div>
                  </div>
              </div> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Checklist;
