import React, { Component } from 'react';
import '../css/App.css';
import axios from 'axios';
import API from '../config_api'
// import { confirmAlert } from 'react-confirm-alert'; // Import
// import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { connect } from 'react-redux'
import { fetchLoginData } from '../redux/loginData/LoginAction'
import Loader from '../component/Loader';
import Cookies from 'js-cookie';
class UserLogin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        
    }
    
    componentWillMount() {

        if (localStorage.getItem('token')) {
            this.props.history.push('/dashboard')
        }
        
        // if (!localStorage.getItem('company_id')) {
        //     this.props.history.push('/')
        // }
       
        let formData1 = new FormData();
        formData1.append('company_id', Cookies.get('company'))
        axios({
            method: 'post',
            url: API.Enterprise,
            data: formData1,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res)
                if (res.data.status === 'success') {

                    localStorage.setItem('logo', res.data.data.logo);
                    localStorage.setItem('company_id', this.state.value);
                    // this.props.history.push('/splash');
                }
                else {
                    alert(res.data.message)
                }
            });

            const messaging = this.props.firebase.messaging();
            messaging.requestPermission()
            .then(function() {
                return messaging.getToken()
            })
            .then(function(token) {
                console.log(token);
                localStorage.setItem('notification-token', token)
            })
            .catch(function(err) {
                console.log(err);
            })
            setTimeout(() => {
            const email = Cookies.get('email');
            const password = Cookies.get('password');
            let formData = new FormData();
            formData.append('user_email', Cookies.get('email'));
            formData.append('user_password', Cookies.get('password'));
            formData.append('company_id', Cookies.get('company'));
            formData.append('device_type', '1');
            formData.append('device_token', localStorage.getItem('notification-token'));
    
            axios({
                method: 'post',
                url: API.CheckParallel,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors'
            })
                .then(response => {
                    if (response.status === 'success') {
                       
                        this.props.loginData(email, password,Cookies.get('company'),this.props);
                    } else if (response.status === 'invalid') {
    
                        setTimeout(() => {
                            alert('Invalid credentials')
    
                        }, 100)
    
                    } else {
                        this.props.loginData(email, password,Cookies.get('company'),this.props);
                    }
                });
            },2000)
    }
   
    handleSubmit(event) {
        const email = event.target[0].value;
        const password = event.target[1].value;
        event.preventDefault();
        if (email === '') {
            alert('Please enter Email/AcuiZen Id ')
        } else if (password === '') {
            alert('Please enter password')
        } else {

            let formData = new FormData();
            formData.append('user_email', email);
            formData.append('user_password', password);
            formData.append('company_id', localStorage.getItem('company_id'));
            formData.append('device_type', '1');
            formData.append('device_token', localStorage.getItem('notification-token'));

            axios({
                method: 'post',
                url: API.CheckParallel,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors'
            })
                .then(response => {
                    if (response.status === 'success') {
                       
                        this.props.loginData(email, password,this.props);
                    } else if (response.status === 'invalid') {

                        setTimeout(() => {
                            alert('Invalid credentials')

                        }, 100)

                    } else {
                        this.props.loginData(email, password, this.props);
                    }
                });

            this.setState({ value: event.target[0].value });
        }
    }
    getBack() {
        localStorage.removeItem("company_id");
        window.location.reload();

    }
    getForget() {
        this.props.history.push('/forgotpassword')
    }
    render() {
        console.log(this.props)
        return (
            <div className="main-content h-h align-item-center">

                {/* <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-7">
                            <div className="card border-0 mb-0 pad_z">

                                <div className="card-body px-lg-5 py-lg-5">
                                    <div className="text-center">
                                        <img src={localStorage.getItem('logo')} alt="az_logo" className="img-responsive mb-40" style={{ height: 100 }} />
                                    </div>
                                    <div className="text-center text-muted mb-4">
                                        <small>Lets get Started</small>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group mb-3">
                                            <div className="input-group input-group-merge ">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                                                </div>
                                                <FormInput name={"username"} placeholder={"EMail/AcuiZen ID"} type={"text"} />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="input-group input-group-merge">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="ni ni-lock-circle-open"></i></span>
                                                </div>
                                                <FormInput name={"password"} placeholder={"Password"} type={"password"} />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Button color={"btn-primary az-color"} type={"submit"} variant={"my-4"} size={"large"}>Sign In</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-6">
                                    <small onClick={() => this.props.history.push('/forgotpassword')}>Forgot password?</small>
                                </div>
                                <div className="col-6 text-right">
                                    <small onClick={() => this.getBack()}>Change Enterprise ID</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {this.props.loader && <Loader />}
            </div> 
          


        );
    }
}
const mapStateToProps = state => {
    return {
        data: state.login.data,
        loader: state.login.loading,
        error: state.login.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        loginData: (email, password, props) => dispatch(fetchLoginData(email, password, props))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(UserLogin);