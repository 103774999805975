import React, { Component } from "react";
import "../css/App.css";
import API from "../config_api";
import axios from "axios";
import Sidenav from "./Sidenav";
import { Tab, Tabs } from "react-bootstrap";
import { GiftedChat } from "react-gifted-chat";
import SlackMessage from "./SlackMessage";
import IosEaselOutline from "react-ionicons/lib/IosEaselOutline";
import Cookies from "js-cookie";
import Loader from "../component/Loader";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import "@ionic/pwa-elements";
import { Modal } from "react-bootstrap";
import { ReactMic } from "react-mic";
const { Camera } = Plugins;

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      record: false,
      loader: false,
      messageText: "",
    };
    this.renderMessage = this.renderMessage.bind(this);
    this.onSubmitMessage = this.onSubmitMessage.bind(this);
    this.listenForNotificationRequests =
      this.listenForNotificationRequests.bind(this);
    this.messagesRef = this.props.firebase
      .database()
      .ref(
        `messageGroup/${this.props.match.params.id}/messages/${this.props.match.params.id}`
      );
    this.unReadMsgRef = this.props.firebase
      .database()
      .ref(
        `messageGroup/${this.props.match.params.id}/unReadCountData/${this.props.match.params.id}`
      );
    this.unitRef = this.props.firebase
      .database()
      .ref(`messageGroup/${this.props.match.params.id}/unitOrderByTime`);
    this.unreadRef = this.props.firebase
      .database()
      .ref(
        `messageGroup/${this.props.match.params.id}/unReadMsgData/${this.props.match.params.id}`
      );
    this.groupRef = this.props.firebase.database().ref(`groupOrderByTime`);
    this.groupChildRef = this.props.firebase
      .database()
      .ref(`messageGroup/${this.props.match.params.id}/totalUnRead`);
  }

  componentWillMount() {
    this.setState({
      userId: this.userID(),
      username:
        localStorage.getItem("firstname") +
        " " +
        localStorage.getItem("lastname"),
    });
    this.loadDataContent();
    let formData = new FormData();
    formData.append("userid", localStorage.getItem("token"));
    formData.append("groupid", this.props.match.params.id);

    axios({
      method: "post",
      url: API.GroupsTopicList,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      console.log(res.data.data);

      this.setState({ data: res.data.data });
    });
  }
  loadDataContent() {
    this.setState({
      isLoading: true,
    });
    // this.messagesRef.limitToLast(12).on('value', (data)=>{
    this.messagesRef.on("value", (data) => {
      var items = [];

      data.forEach((child) => {
        items.push(child.val());
      });
      console.log(items);
      this.getContent(items);
    });

    var self = this;
    var userId = this.state.userId;
    this.unreadRef.child(`${userId}`).once("value", function (snapshot) {
      var unreadChild = self.unreadRef.child(`${userId}`);
      unreadChild.set(0);
    });
    var unreadChild = this.groupChildRef.child(`${userId}`);
    unreadChild.set(0);
    // this.groupChildRef.child(`${userId}`).once("value", function(snapshot){

    // })
  }
  getContent = (datas) => {
    console.log(datas);
    return Promise.all(
      datas.map((data) => {
        if (data.message_type === "image") {
          let storage = this.props.firebase.storage();
          let gsReference = storage.refFromURL(data.message);
          return new Promise((resolve, reject) => {
            gsReference
              .getDownloadURL()
              .then((url) => {
                let obj = {
                  _id: data.date,
                  text: "",
                  createdAt: new Date(data.date),
                  type: "image",
                  user: {
                    _id: data.sender_id,
                    name: data.sender_name,
                  },
                  image: data.message_type === "image" ? url : "",
                  audio: data.message_type === "audio" ? "" : "",
                  url: url,
                };
                resolve(obj);
              })
              .catch((err) => reject(err));
          });
        } else if (data.message_type === "audio") {
          let storage = this.props.firebase.storage();
          let gsReference = storage.refFromURL(data.message);
          return new Promise((resolve, reject) => {
            gsReference
              .getDownloadURL()
              .then((url) => {
                let obj = {
                  _id: data.date,
                  text: "",
                  createdAt: new Date(data.date),
                  type: "audio",
                  user: {
                    _id: data.sender_id,
                    name: data.sender_name,
                  },
                  image: data.message_type === "image" ? url : "",
                  audio: data.message_type === "audio" ? "" : "",
                  url: url,
                };

                resolve(obj);
              })
              .catch((err) => reject(err));
          });
        } else if (data.message_type === "video") {
          let storage = this.props.firebase.storage();
          let gsReference = storage.refFromURL(data.message);
          return new Promise((resolve, reject) => {
            gsReference
              .getDownloadURL()
              .then((url) => {
                let obj = {
                  _id: data.date,
                  text: "",
                  createdAt: new Date(data.date),
                  type: "video",
                  user: {
                    _id: data.sender_id,
                    name: data.sender_name,
                  },
                  image: data.message_type === "image" ? url : "",
                  audio: data.message_type === "audio" ? "" : "",
                  url: url,
                };
                resolve(obj);
              })
              .catch((err) => reject(err));
          });
        } else if (data.message_type === "text") {
          return new Promise((resolve, reject) => {
            let obj = {
              _id: data.date,
              text: data.message,
              createdAt: new Date(data.date),
              type: "text",
              user: {
                _id: data.sender_id,
                name: data.sender_name,
              },
              image: "",
              audio: "",
            };

            // setTimeout(() => {
            resolve(obj);
            // },100)
          });
        }
      })
    )
      .then((data) => {
        this.setState({
          isLoading: false,
        });

        let vl = data.reverse();
        // let vl = data
        setTimeout(() => {
          this.setState({
            messages: [...vl],
          });
          this.setState(this.state);
        }, 100);

        // console.log(data, " ", this.state.isLoading)
      })
      .catch((err) => {
        console.log("Error", err);
        this.setState({
          isLoading: false,
        });
        this.forceUpdate();
      });
  };
  firebaseFunc() {
    var childRef = this.unitRef.child(
      `${this.props.location.state.data.unique_id}`
    );
    childRef.set(this.props.firebase.database.ServerValue.TIMESTAMP);
    // this.unitRef.set({ unique_id : firebase.database.ServerValue.TIMESTAMP });
    var groupChild = this.groupRef.child(`${this.props.match.params.id}`);
    groupChild.set(this.props.firebase.database.ServerValue.TIMESTAMP);

    var members = this.props.location.state.data.members_list.split(",");
    // console.log(members)
    var self = this;
    for (let i of members) {
      this.groupChildRef.child(i).once("value", function (snapshot) {
        if (self.state.userId !== snapshot.key) {
          if (snapshot.exists()) {
            let unreadChild = self.groupChildRef.child(`${snapshot.key}`);
            let unread = snapshot.val() + 1;
            unreadChild.set(unread);
          } else {
            // console.log("else")
            let unreadChild = self.groupChildRef.child(`${snapshot.key}`);
            let unread = 1;
            unreadChild.set(unread);
          }
        }
      });
    }
  }

  onSubmitMessage() {
    // console.log('enter text')
    this.sendMessage([]);
  }
  sendMessage(messages) {
    //   for (var i = 0; i < message.length; i++) { //firebase.database.ServerValue.TIMESTAMP
    if (this.state.messageText) {
      let obj = {
        message: this.state.messageText,
        date: this.props.firebase.database.ServerValue.TIMESTAMP,
        message_type: "text",
        sender_id: this.state.userId,
        sender_name: this.state.username,
        status: "sent",
      };
      console.log(obj);
      this.messagesRef.push(obj);

      this.setState({ messageText: "" });
      this.firebaseFunc();
    }
    // console.error(obj)
    this.listenForNotificationRequests(this.props);
  }
  listenForNotificationRequests(props) {
    this.messagesRef
      .endAt()
      .limitToLast(1)
      .on("child_added", function (snapshot) {
        // all records after the last continue to invoke this function
        // console.log(snapshot.val());
        var request = snapshot.val();
        let message = "";
        if (request.message_type === "image") {
          message = "Received a new image.";
        } else if (request.message_type === "audio") {
          message = "Received a new audio message.";
        } else {
          message = request.message;
        }
        const userID = localStorage.getItem("token");
        const ID = userID.split("@");

        let formData = new FormData();
        formData.append("group_id", props.location.state.data.group_id);
        formData.append("user_id", ID[0]);
        formData.append("message", message);
        formData.append("message_type", request.message_type);
        formData.append("token", localStorage.getItem("token"));
        formData.append("unit_id", "");
        axios({
          method: "post",
          url: API.Group_Notification,
          data: formData,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          mode: "no-cors",
        }).then((res) => {
          console.log(res.data.data);
        });
      });
  }
  dataURItoFile = (dataURI) => {
    var byteString = atob(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }

    // write the ArrayBuffer to a blob, and you're done
    return new File([ab], { type: mimeString });
  };
  async takePicture() {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      quality: 90,
      allowEditing: true,
      source: CameraSource.Camera,
    });
    let value = this.dataURItoFile(image.dataUrl, "");
    let data = { uri: value, fileName: "", type: "image" };
    this.uploadImage(data);
  }
  messageIdGenerator() {
    // generates uuid.
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
      let r = (Math.random() * 16) | 0,
        v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  uploadImage({ uri, fileName, type }, mime = "application/octet-stream") {
    let finalFileName;
    console.log(type);
    if (type === "image") {
      console.log(type);
      finalFileName = `${this.messageIdGenerator()}.jpg`;
    } else {
      finalFileName = fileName;
    }

    console.log(finalFileName);
    return new Promise((resolve, reject) => {
      const imagePath = uri;
      var sessionId = new Date().getTime();

      const imageRef = this.props.firebase
        .storage()
        .ref("AcuizenImage")
        .child(finalFileName);
      // let mime = 'image/jpg';

      imageRef
        .put(imagePath, { contentType: mime })
        .then(() => {
          return imageRef.getDownloadURL();
        })
        .then((url) => {
          resolve(url);
          this.storageReferance(url, sessionId, type);
        })
        .catch(reject);
    });
  }
  storageReferance = (url, sessionId, type) => {
    // console.log(url, type)

    var obj = {
      message: url,
      date: this.props.firebase.database.ServerValue.TIMESTAMP,
      message_type: type,
      sender_id: this.state.userId,
      sender_name: this.state.username,
      status: "sent",
    };
    // console.error(obj)
    this.messagesRef.push(obj);

    this.firebaseFunc();
    this.listenForNotificationRequests(this.props);
  };
  handleChange = (e) => {
    if (e.target.files.length) {
      let value = e.target.files[0];
      let data = { uri: value, fileName: "", type: "image" };
      this.uploadImage(data);
    }
  };

  renderMessage(props) {
    console.log(props);
    const {
      currentMessage: { text: currText },
    } = props;
    // const { params } = this.props.navigation.state;
    // console.log("CurrentText", currText);
    let messageTextStyle;

    //Make "pure emoji" messages much bigger than plain text.
    if (currText) {
      //} && emojiUtils.isPureEmojiString(currText)) {
      messageTextStyle = {
        fontSize: 14,
        fontWeight: "400",
        marginBottom: 0,
        color:
          this.props.location.state.data.facilitator_id ===
          props.currentMessage.user._id
            ? "#005284"
            : "#000",
      };
    }
    // console.log(params.facilitator_id)
    return (
      <SlackMessage
        {...props}
        fid={this.props.location.state.data.facilitator_id}
        messageTextStyle={messageTextStyle}
      />
    );
  }

  customInputToolbar() {
    return (
      <div
        className="flex justify-content-md-around bg-white align-item-center"
        style={{
          padding: "18px",
          background: "#ccc",
          borderBottomLeftRadius: "4px",
          borderBottomRightRadius: "4px",
        }}
      >
        <span
          class="material-icons"
          style={{ cursor: "pointer" }}
          onClick={() => this.takePicture()}
        >
          camera_alt
        </span>
        <div>
          <label htmlFor="upload-button" style={{ marginBottom: "0px" }}>
            <span class="material-icons" style={{ cursor: "pointer" }}>
              insert_photo
            </span>
          </label>
          <input
            type="file"
            id="upload-button"
            style={{ display: "none" }}
            accept="image/*"
            onChange={(e) => this.handleChange(e)}
          />
        </div>

        <span
          class="material-icons"
          style={{ cursor: "pointer" }}
          onClick={() => this.takeAudio()}
        >
          mic
        </span>
        {this.state.loader && <Loader />}
      </div>
    );
  }
  takeAudio() {
    this.setState({
      audioVisible: true,
    });
  }
  closeAudioModal() {
    this.setState({
      audioVisible: false,
    });
  }
  stopRecording = () => {
    this.setState({
      record: false,
    });
  };
  startRecording = () => {
    this.setState({
      record: true,
    });
  };
  onData(recordedBlob) {
    console.log("chunk of real-time data is: ", recordedBlob);
  }
  onStop = (recordedBlob) => {
    console.log(recordedBlob);
    if (!this.state.record) {
      this.setState({
        audioVisible: false,
      });
      var value = new File([recordedBlob.blob], {
        type: "audio/mp3",
        lastModified: Date.now(),
      });
      const fileName = `${this.messageIdGenerator()}.aac`;
      let data = { uri: value, fileName: fileName, type: "audio" };
      this.uploadImage(data);
    }
  };
  saveAudio() {
    if (!this.state.record) {
      this.setState({
        audioVisible: false,
      });
    }
  }

  renderChat() {
    return (
      <GiftedChat
        messages={this.state.messages}
        alwaysShowSend={true}
        ScrollToTop={true}
        renderAccessory={() => this.customInputToolbar()}
        user={{
          _id: this.state.userId,
          name: this.state.username,
        }}
        onSend={(messages) => this.sendMessage(messages)}
        renderMessage={this.renderMessage}
        multiline={false}
        textInputProps={{ onSubmitEditing: this.onSubmitMessage }}
        onInputTextChanged={(text) => this.setState({ messageText: text })}
        text={this.state.messageText}
      />
    );
  }

  btnAction(data) {
    console.log(data);
    this.props.history.push("/chat/" + this.props.match.params.id, {
      data,
      value: this.props.location.state.data,
    });
  }
  videoCall(e) {
    let formData = new FormData();
    formData.append("token", localStorage.getItem("token"));
    formData.append("group_id", this.props.match.params.id);
    formData.append(
      "facilitator_name",
      this.props.location.state.data.facilitator_name
    );
    formData.append(
      "facilitator_id",
      this.props.location.state.data.facilitator_id
    );
    formData.append("notification", localStorage.getItem("notification-token"));
    axios({
      method: "post",
      url: API.VideoConference,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      console.log(res.data.data);
      window.open("/video/" + res.data.data.roomName, "_blank");
      // this.props.history.push('/video/' + res.data.data.roomName)
    });
  }
  // whiteBoard() {
  //      Cookies.set('sdsession', 'test', {path:'/', domain: '.acuizen.com'});
  //     this.setState({
  //         loader: true
  //     })
  //     axios({
  //         method: 'post',
  //         url: 'https://board.acuizen.com/api/sessions',
  //         data: {
  //             email: this.props.location.state.data.email,
  //             password: this.props.location.state.data.password
  //         },
  //         headers: {
  //             'Access-Control-Allow-Origin': '*',
  //             'Content-Type': 'application/json; charset=UTF-8'
  //         },
  //         mode: 'no-cors',
  //     })
  //         .then(res => {
  //             this.setState({
  //                 loader: false
  //             })
  //             console.log(res.data)
  //             Cookies.set('sdsession', res.data.token, { path: '/', domain: 'acuizen.com' });
  //             window.open('https://board.acuizen.com/spaces')
  //             this.currentUser(res.data)
  //         });
  // }
  // currentUser(data) {

  //     axios({
  //         method: 'get',
  //         url: 'https://board.acuizen.com/api/users/current',

  //         headers: {
  //             'x-spacedeck-auth': data.token,
  //             // 'Cookie': 'sdsession='+data.token
  //             // 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  //         },
  //         mode: 'no-cors',
  //     })
  //         .then(res => {
  //             console.log(res.data)
  //             //this.props.history.push('/spaces',{data:res.data})
  //             // this.getSpaces(res.data)
  //             // this.props.history.push('/spaces/' + res.data.data.roomName)

  //         });
  // }
  userID() {
    const userID = localStorage.getItem("token");
    const ID = userID.split("@");
    console.log(ID[0]);
    return ID[0];
  }
  render() {
    console.log(this.props);
    return (
      <div className="">
        <Sidenav type={"second"} url={"/groups"} />

        <div className="container">
          <div className="row align-items-center" style={{ padding: 15 }}>
            <div className="col-md-3"></div>

            {/* <div className="col-md-6 text-center">
                            <h5 className="size-text" style> {this.props.location.state.data.group_name}</h5>
                        </div> */}

            {/* <div className="col-md-3 text-center flex align-item-center justify-content-around">
                            {this.props.location.state.data.facilitator_id === this.userID() ?
                                <img src={require('../images/video_chat3.png')} alt="test" style={{ width: 50, height: 50, cursor: 'pointer' }} onClick={(e) => this.videoCall(e)} />
                                : ''}
                            {this.props.location.state.data.email ?
                                <IosEaselOutline fontSize="50px" color="#005284" onClick={() => this.whiteBoard()} style={{ cursor: 'pointer' }} /> : ''}
                        </div> */}
          </div>
          <Tabs
            defaultActiveKey="team"
            id="uncontrolled-tab-example"
            style={styles.navbarTab}
          >
            <Tab eventKey="team" title="Team" className="team-group">
              <div style={styles.container}>
                <div style={styles.chat}>{this.renderChat()}</div>
              </div>
              <Modal
                size="sm"
                show={this.state.audioVisible}
                onHide={() => this.closeAudioModal()}
                backdrop="static"
                centered
                animation
                aria-labelledby="example-modal-sizes-title-sm"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="example-modal-sizes-title-sm">
                    Audio Recording
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="flex flex-column align-item-center justify-content-between">
                    <ReactMic
                      record={this.state.record}
                      className="sound-wave"
                      onStop={this.onStop}
                      onData={this.onData}
                      strokeColor="#000000"
                      backgroundColor="#adb5bd"
                      mimeType="audio/mp3"
                    />
                    <div className="">
                      {this.state.record ? (
                        <img
                          onClick={() => this.stopRecording()}
                          style={{ width: 60, cursor: "pointer" }}
                          src={require("../images/stop.png")}
                          alt="stop"
                          className="img-responsive"
                        />
                      ) : (
                        <img
                          onClick={() => this.startRecording()}
                          style={{ width: 60, cursor: "pointer" }}
                          src={require("../images/recording.png")}
                          alt="start"
                          className="img-responsive"
                        />
                      )}
                    </div>{" "}
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="flex justify-content-between">
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => this.closeAudioModal()}
                    >
                      Cancel
                    </span>
                    {this.state.record ? (
                      ""
                    ) : (
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => this.saveAudio()}
                      >
                        Done
                      </span>
                    )}
                  </div>
                </Modal.Footer>
              </Modal>
            </Tab>

            <Tab eventKey="topic" title="Topic">
              <div className="row justify-content-center">
                <div className="col-12 ">
                  <div
                    className="p-4 mt-4"
                    style={{ background: "#ffffff", borderRadius: "22px" }}
                  >
                    <div className="trans az-hover">
                      {this.state.data.map((data, i) => (
                        <div
                          className="card justify-content-center az-hover-bg"
                          style={{
                            padding: 26,
                            margin: 10,
                            cursor: "pointer",
                            background: "#f3f3f3",
                          }}
                          key={i}
                          onClick={() => this.btnAction(data)}
                        >
                          <div className="d-flex align-items-center justify-content-between">
                            <div className="d-flex align-items-center">
                              <img
                                src={require("../images/chat_yellow (1).png")}
                                alt="test"
                                style={{
                                  width: 30,
                                  height: 30,
                                  cursor: "pointer",
                                }}
                                onClick={(e) => this.videoCall(e)}
                              />
                              <p
                                className="mb-0 pl-4"
                                style={{
                                  fontSize: "15px",
                                  fontWeight: "600",
                                  color: "#005284",
                                  textTransform: "capitalize",
                                }}
                              >
                                {data.name}
                              </p>
                            </div>
                            <div className="pr-4 pl-4">
                              <i
                                className="material-icons"
                                style={{ color: "#3c3c3c" }}
                              >
                                keyboard_arrow_right
                              </i>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
          </Tabs>
          {this.state.loader && <Loader />}
        </div>
      </div>
    );
  }
}
const styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    height: "69vh",
    background: "#f5f5f5",
    // borderRadius: "10px",
  },
  channelList: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  chat: {
    display: "flex",
    flex: 3,
    flexDirection: "column",
    borderWidth: "1px",
    borderColor: "#ccc",
    // borderRightStyle: "solid",
    // borderLeftStyle: "solid",
  },
  settings: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  navbarTab: {
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    padding: "12px 16px",
  },
};
export default Group;
