
import React from 'react';
import {Avatar, MessageText, MessageImage, Time, utils } from 'react-gifted-chat';
import BigPicture from "react-bigpicture";
// import Icon from 'react-native-vector-icons/Ionicons';
const { isSameUser, isSameDay } = utils;

export default class Bubble extends React.Component {

  
  constructor(props) {
    super(props);
    this.state = {
      playAudio: false,
      playState:false, //playing, paused
      playSeconds:0,
      duration:0
    }
    this.sliderEditing = false;
    // this.onLongPress = this.onLongPress.bind(this);
    // this.onMsgPress = this.onMsgPress.bind(this);
    // getTokenUserid().then((value)=>{
    //   console.log(value)
    //   let formData = new FormData();
    //   formData.append('userid',value)
    //   this.props.learnerAssignedCall(formData)

    // }).catch(err=>console.log(err))

  }

  componentDidMount(){
    // this.play();
    
    // this.timeout = setInterval(() => {
    //     if(this.sound && this.sound.isLoaded() && this.state.playState === 'playing' && !this.sliderEditing){
    //         this.sound.getCurrentTime((seconds, isPlaying) => {
    //             this.setState({playSeconds:seconds});
    //         })
    //     }
    // }, 100);
  }
  componentWillUnmount(){
      if(this.sound){
          this.sound.release();
          this.sound = null;
      }
      // if(this.timeout){
      //     clearInterval(this.timeout);
      // }
  }
  
  // onLongPress() {
  //   if (this.props.onLongPress) {
  //     this.props.onLongPress(this.context, this.props.currentMessage);
  //   } else {
  //     if (this.props.currentMessage.text) {
  //       const options = [
  //         'Copy Text',
  //         'Cancel',
  //       ];
  //       const cancelButtonIndex = options.length - 1;
  //       this.context.actionSheet().showActionSheetWithOptions({
  //         options,
  //         cancelButtonIndex,
  //       },
  //       (buttonIndex) => {
  //         switch (buttonIndex) {
  //           case 0:
  //             Clipboard.setString(this.props.currentMessage.text);
  //             break;
  //         }
  //       });
  //     }
  //   }
  // }

  

  renderMessageText() {
    if (this.props.currentMessage.text) {
      const { containerStyle, wrapperStyle, messageTextStyle, ...messageTextProps } = this.props;
      if (this.props.renderMessageText) {
        return this.props.renderMessageText(messageTextProps);
      }
      return (
        <div style={{paddingLeft: 5, marginLeft: 5, borderRadius: 5, marginVertical: 10, backgroundColor: '#fff'}}>
          <MessageText
            {...messageTextProps}
            textStyle={{
              left: [styles.standardFont,  styles.slackMessageText, messageTextProps.textStyle, messageTextStyle],
            }}
          />
          {this.renderTime()}
        </div>
      );
    }
    return null;
  }

  renderMessageImage() {
    if (this.props.currentMessage.image) {
      const { containerStyle, wrapperStyle, ...messageImageProps } = this.props;
      if (this.props.renderMessageImage) {
        return this.props.renderMessageImage(messageImageProps);
      }
      return (
        
          <MessageImage {...messageImageProps} imageStyle={[styles.slackImage, messageImageProps.imageStyle]} />
      
      )
    }
    return null;
  }

  renderTicks() {
    const { currentMessage } = this.props;
    // console.log(currentMessage)
    if (this.props.renderTicks) {
      return this.props.renderTicks(currentMessage);
    }
    if (currentMessage.user._id !== this.props.user._id) {
      return null;
    }
    if (currentMessage.sent || currentMessage.received) {
      return (
        <div style={Object.assign(styles.headerItem, styles.tickView)}>
          {currentMessage.sent && <span style={[styles.standardFont, styles.tick, this.props.tickStyle]}>✓</span>}
          {currentMessage.received && <span style={[styles.standardFont, styles.tick, this.props.tickStyle]}>✓</span>}
        </div>
      );
    }
    return null;
  }

  renderUsername() {
    let username = this.props.currentMessage.user.name;
    
    let facilitator_color;
    if (username) {
      const { fid, containerStyle, wrapperStyle, ...usernameProps } = this.props;
      if (this.props.renderUsername) {
        return this.props.renderUsername(usernameProps);
      }
      if(fid === this.props.currentMessage.user._id) {
        facilitator_color = { color: '#be1f24', fontWeight: '800', }
        // username = username + <Icon name="shield" />
      }
      //#be1f24 AZ color code red
      // #005284
      //#c94e57
      username = username.charAt(0).toUpperCase() + username.slice(1);
      // console.log(username)
      return (
        <span style={styles.standardFont, styles.headerItem, styles.username, this.props.usernameStyle, facilitator_color}>
          {/* <Icon name="shield-account" style={{}} /> */}
          {username+" "}
          { fid === this.props.currentMessage.user._id ? '' : null }
        </span>
      );
    }
    return null;
  }

  renderTime() {
    if (this.props.currentMessage.createdAt) {
      const { containerStyle, wrapperStyle, ...timeProps } = this.props;
      // console.log(timeProps)
      if (this.props.renderTime) {
        // console.log('Render Time')
        return this.props.renderTime(timeProps);
      }
      // Add lineHeight in the node_modules Time.js under giftedChat { lineHeight: 25 };
      return (
        <Time 
          {...timeProps}
          containerStyle={{ right: [styles.timeContainer] }}
        />
        // <Time
        //   {...timeProps}
        //   containerStyle={{ right: [styles.timeContainer] }}
        //   textStyle={{ right: [ styles.headerItem, timeProps.textStyle, styles.time, timeHeight] }}
        // />
      );
    }
    return null;
  }

  renderAvatar() {
    const avatarProps = this.getInnerComponentProps();
    return (
      <Avatar
        {...avatarProps}
        imageStyle={{ left: [styles.slackAvatar, avatarProps.imageStyle] }}
      />
    );
  }

  // onSliderEditStart = () => {
  //   this.sliderEditing = true;
  // }
  // onSliderEditEnd = () => {
  //     this.sliderEditing = false;
  // }
  // onSliderEditing = value => {
  //     if(this.sound){
  //         this.sound.setCurrentTime(value);
  //         this.setState({playSeconds:value});
  //     }
  // }
  getAudioTimeString(seconds){
    const h = parseInt(seconds/(60*60));
    const m = parseInt(seconds%(60*60)/60);
    const s = parseInt(seconds%60);
    if(h !== 0) {
      return ((h<10?'0'+h:h) + ':' + (m<10?'0'+m:m) + ':' + (s<10?'0'+s:s));
    }
    return ((m<10?'0'+m:m) + ':' + (s<10?'0'+s:s));
  }

  play= async(url) => {
    // this.sound = new Audio(url)
   
 
  //     this.sound.pause();
  
  // console.log('test')
  //   this.setState({playState:'paused'});
   
    this.sound = new Audio(url)
    this.sound.play();
    this.setState({playState:true});
   this.sound.onended=()=>{
    this.setState({playState:false});
    console.log('failed to load the sound');
   }
  
}
 

  pause = () => {
      if(this.sound){
          this.sound.pause();
      }
      this.setState({playState:false});
  }
   renderAudio() { 
    // console.log(this.props.currentMessage)
    if(this.props.currentMessage.type === 'audio') {
    return (
      <div style={{ backgroundColor: '#fff', borderRadius: 5, marginRight: 0, paddingLeft: 10, marginLeft: 5 }}>
        <div style={{ alignSelf: 'center', flexDirection: 'row', marginTop: 10}}>
        <BigPicture
            type="audio"
            src={this.props.currentMessage.url}
            loop={false}
        >
            <div className="flex justify-content-center">
             <img src={require('../images/1200px-Speaker_Icon.png')} style={{width:'50px'}} alt="test"/>
             </div>
        </BigPicture>
          {/* {this.state.playState  ?
          <span class="material-icons"  onClick={() => this.pause()}>
          pause
          </span>
          :
          <span class="material-icons"  onClick={() =>this.play(this.props.currentMessage.url)}>
play_arrow
</span>} */}
            
           
            </div>
            {this.renderTime()}
            </div>
           
        );
  }
  };

  renderCustomView() {
    if (this.props.renderCustomView) {
      return this.props.renderCustomView(this.props)
    }
    return null;
  }

  render() {
    const isSameThread = isSameUser(this.props.currentMessage, this.props.previousMessage)
      && isSameDay(this.props.currentMessage, this.props.previousMessage);
    // console.log(this.props.currentMessage)
    const messageHeader = isSameThread ? null : (
      <div style={Object.assign(styles.headerView)}>
        {/* {this.renderAvatar()} */}
        {this.renderUsername()}
        {/* {this.renderTime()} */}
        {this.renderTicks()}
      </div>
    );

    return (
      <div style={Object.assign(styles.container, this.props.containerStyle)}>
        
         {/* // onClick={this.onLongPress}
          // accessibilityTraits="text"
          // {...this.props.touchableProps} */}
        
          <div style={Object.assign(styles.wrapper,this.props.wrapperStyle)}>
          
              
          {messageHeader}
              {/* {this.renderTime()} */}
              {/* <View style={{ flexDirection: 'row' }}> */}
                {this.renderMessageImage()}
                {this.renderCustomView()} 
                
                {this.props.currentMessage.type !== 'text' && this.props.currentMessage.type !== 'audio' ? <div style={{marginBottom: 10}}>{this.renderTime()}</div> : null }
              {/* </View> */}
              
              {this.renderMessageText()}
              {this.renderAudio()}
            </div>
          </div>
     
    );
  }

}

// Note: Everything is forced to be "left" positioned with this component.
// The "right" position is only used in the default Bubble.
const styles = {
  shadow: {
    shadowRadius: 3,
    shadowOpacity:0.6,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowColor: '#8e8e8e',
    elevation: 5,
  },
  standardFont: {
    fontSize: 15,
    
  },
  slackMessageText: {
    marginLeft: 0,
    marginRight: 0
  },
  container: {
    flex: 1,
    alignItems: 'flex-start',
    marginRight: 25
  },
  wrapper: {
   /// marginRight: 60,
    minHeight: 20,
    justifyContent: 'flex-end',
  },
  username: {
    fontSize: 14,
  
    fontWeight: '600',
    color: '#8e8e8e'
  },
  time: {
    textAlign: 'right',
    fontSize: 12,
   
  },
  timeContainer: {
    marginLeft: 10,
    marginRight: 0
  },
  headerItem: {
    marginRight: '10px',
  },
  headerView: {
    // Try to align it better with the avatar on Android.
    marginTop: 0,
    flexDirection: 'row',
    alignItems: 'baseline',
  },

  tick: {
    backgroundColor: 'transparent',
    color: 'white',
  },
  
  tickView: {
    flexDirection: 'row',
  },
  slackImage: {
    borderRadius: 5,
    marginLeft: 5,
    marginTop: 10,
    // width: width/1.5,
    // height: (width/1.5)/1.5
  },
  slackAvatar: {
    // The bottom should roughly line up with the first line of message text.
    height: 40,
    width: 40,
    borderRadius: 3,
    color: '#fff',
    fontWeight: '700'
  }
}


