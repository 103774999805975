import React, { Component } from 'react';
import '../css/App.css';
import Sidenav from './Sidenav';
import axios from 'axios';
import API from '../config_api';
import IdleTimerLogout from './IdleTimerLogout';
import { Hidden } from '@material-ui/core';
import BigPicture from "react-bigpicture";
import moment from 'moment'
class Action extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pendingList: [],
            archiveList: [],
            updateList: [],

        }

    }

    componentWillMount() {
        this.updateActionList();
        // this.markAction();

    }
    // componentDidUpdate(prevProps){
    //     if(prevProps.actionList.length != this.props.actionList.length){
    //         this.setState({ localActionList: this.props.actionList })
    //     }

    // }

    convertDate(date) {
        const d = new Date(date);
        return d.toString().split(" ")[2] + " " + d.toString().split(" ")[1] + " " + d.toString().split(" ")[3]

    }
    convertTime(times) {
        let time = times.slice(0, -3);

        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

        if (time.length > 1) {
            time = time.slice(1);
            time[5] = +time[0] < 12 ? ' AM' : ' PM';
            time[0] = +time[0] % 12 || 12;
        }
        return time.join('');
    }


    checkbox_icon(item) {
        // console.log(status === '2')
        if (item.status === '1') {

            return (

                <div onClick={(e) => { e.stopPropagation(); this.markAction(item, '2') }}
                    style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                    <img src={require('../images/unchecked_48dp.png')} alt="uncheck"

                        style={{ paddingRight: 10, height: 20 }} />
                    <h4>Mark as Read</h4>
                </div>
            )

        }
        else if (item.status === '2') {
            return (

                <div onClick={(e) => { e.stopPropagation(); this.markAction(item, '3') }}
                    style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <img src={require('../images/unchecked_48dp.png')} alt="uncheck"

                            style={{ paddingRight: 10, height: 20 }} />

                        <h4>Done</h4>

                    </div>

                    <div onClick={(e) => { e.stopPropagation(); this.markAction(item, '4') }}
                        style={{ display: 'flex', flexDirection: 'row' }}>
                        <img src={require('../images/unchecked_48dp.png')} alt="uncheck"

                            style={{ paddingRight: 10, height: 20 }} />

                        <h4>Ignored</h4>

                    </div>
                </div>

            )
        }

    }

    // checkDue(date) {
    //     var diff = moment(new Date(), 'MM/DD/YYYY h:mm A').diff(moment(date, 'MM/DD/YYYY h:mm A'))
    //     // console.log(diff)
    //     if (diff > 0) {
    //         // console.log('date past')
    //         return '#ffdfda'
    //     } else return '#fff'
    // }
    checkDue(date, archivedDate) {
        var d_date;
        if (archivedDate == null) {
            d_date = new Date()
        } else d_date = moment(archivedDate + 'Z', 'YYYY-MM-DD HH:mm:ss Z').format('MM/DD/YYYY hh:mm A')

        var diff = moment(d_date, 'MM/DD/YYYY, hh:mm A').diff(moment(date, 'MM/DD/YYYY h:mm A'))
        if (diff > 0) {
            return '#ffdfda'
        } else return '#fff'
    }

    renderCreatedDate(date, type) {
        if (type == 1) return moment(new Date(date), 'MM/DD/YYYY').format('DD MMM YYYY')
        else if (type == 2) return moment(date, 'MM/DD/YYYY h:mm A').format('hh:mm A')
        else if (type == 3) {
            var stillUtc = moment.utc(date).toDate();
            return moment(stillUtc).local().format('DD MMM YYYY, hh:mm A');
        }
        else {
            return moment(date + 'Z', 'YYYY-MM-DD HH:mm:ss z').format('DD MMM YYYY, hh:mm A')
        }
    }

    renderColor(status) {
        if (status == '3') {
            return '#10705d'
        } else if (status == '2') {
            return '#ffa500'
        } else return '#fff'
    }

    renderArchiveColor(status) {
        if (status == '4') {
            return '#be1f24'
        } else if (status == '3') {
            return '#7ed321'//10705d'
        } else if (status == '2') {
            return '#ffa500'
        } else return '#be1f24'
    }

    renderReceivedDate(date) {
        var stillUtc = moment.utc(date).toDate();
        var local = moment(stillUtc).local().format('DD MMM YYYY, hh:mm A');

        console.log(local);

        return local //moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD MMM YYYY,hh:mm A')
    }





    markAction(item, status) {

        // let formData= {"user_id" :  "9210@1660807053","msg_id":item.msg_id,"type":item.status}

        var formData = new FormData()
        formData.append('user_id', localStorage.getItem('token'))
        formData.append('msg_id', item.msg_id)
        formData.append('type', status)

        if (status == '3') {
            if (this.checkDue(item.dates + ' ' + item.time) == '#ffdfda') {
                // console.log('late_submission: true')
                formData.append('late_submission', true)
            } else formData.append('late_submission', false)
            var response_time = moment(new Date(), 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
            // console.log('responded_time', response_time)
            formData.append('responded_time', response_time)
        }

        console.log(JSON.stringify(formData), item, status)
        axios({
            method: 'post',
            url: API.ActionUpdate,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        }).then((res) => {
            console.log(res);
            if (res.data.status === 'success') {
                console.log(res);
                this.updateActionList()


            }
        })


    }

    updateActionList() {
        let formData = new FormData();
        formData.append(
            'user_id',
            localStorage.getItem('token')
        )

        axios({
            method: 'post',
            url: API.ActionList,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        }).then((res) => {
            if (res.data.status === 'success') {
                this.setState({ pendingList: res.data.data })
                console.log(res);
            }

        })

        axios({
            method: 'post',
            url: API.ActionArchive,
            data: formData,
            headers: {
                'content-type': 'multipart/form-data'

            },
            mode: 'no-cors',
        }).then((res) => {
            if (res.data.status === 'success') {
                this.setState({ archiveList: res.data.data })
                console.log(res);
            }

        })
    }

    attachment(json) {
        let item = json.attachment;
        if (item === undefined) {
            return (
                <></>
            )
        }
        if (item.name === 'youtube') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="youtube"
                                src={"https://www.youtube.com/watch/v=" + item.value}
                            >
                                <div className="flex" style={{ position: 'relative' }}>
                                    <img src={"https://img.youtube.com/vi/" + item.value + "/0.jpg"} className="img-responsive" style={{ width: '100%' }} alt="test" />
                                    <div className="overlay"> <img src={require('../images/youtube.png')} style={{ width: '70px' }} alt="test" /></div>

                                </div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            );
        }

        else if (item.name === 'image') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content text-center">
                            <BigPicture
                                type="image"
                                src={item.value}
                            >
                                <div className="flex justify-content-center"><img src={item.value} alt="step" className="img-responsive" /></div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'video') {

            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">

                            {/* <FancyVideo
                        source={item.name}
                        poster={require('../images/white.jpg')}
                        id={Math.random().toString(36).substring(7)} /> 
                     <video src={item.name} controls style={{ width: '100%' }}>
                        Your browser does not support the video tag.
                        </video> */}
                            <BigPicture
                                type="video"
                                src={item.value}
                            >
                                <div className="flex" style={{ position: 'relative' }}>
                                    {item.preview ? <img src={item.preview} className="img-responsive" style={{ width: '100%' }} alt="test" /> : <img src={require('../images/black.jpg')} className="img-responsive" style={{ width: '100%', height: 200 }} alt="test" />
                                    }
                                    <div className="overlay"> <img src={require('../images/cinema.png')} style={{ width: '70px' }} alt="test" /></div>


                                </div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'embed') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <div className="embed" dangerouslySetInnerHTML={{ __html: item.value }} />
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'weblink') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content text-center">
                            <a href={item.value} target="_blank" rel="noopener noreferrer" style={{
                                padding: '8px 20px', display: 'inline-block', color: '#fff',
                                background: '#920067',
                                borderRadius: 3, fontSize: 15, margin: 10
                            }}>Click Here</a>
                            <p>This will Open on a separate tab</p>
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'pdfs') {


            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="iframe"
                                src={item.value}
                            >
                                <div className="flex justify-content-center" style={{ position: 'relative' }}>
                                    {item.preview ? <img src={item.preview} className="img-responsive" style={{ width: 320, height: 400 }} alt="test" /> : <img src={require('../images/black.jpg')} className="img-responsive" style={{ width: '100%', height: 200 }} alt="test" />
                                    }
                                    <div className="overlay"> <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" /></div>


                                </div>
                            </BigPicture>
                            {/* <iframe src={item.name} width='100%' height="400px" title="red" allowFullScreen/> */}


                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'audio') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="audio"
                                src={item.value}
                            >
                                <div className="flex justify-content-center">
                                    <img src={require('../images/1200px-Speaker_Icon.png')} style={{ width: '70px' }} alt="test" />
                                </div>
                            </BigPicture>
                            {/* <audio controls>
                        <source src={item.name}></source>
                    </audio> */}
                        </div>

                    </div>
                </div>

            )
        }
    }

    onExpand(action) {
        action.expanded = action.expanded === "1" ? "0" : "1"
        this.forceUpdate()
    }

    render() {
        return (
            <div className='container'>
                <Sidenav type={'second'} url={'/dashboard'} />
                <div className="container">
                    <div style={{ position: 'fixed', left: 0, right: 0, zIndex: 1, }}>
                        <div className="container">
                            <div className="row">

                                <div className="col-md-12 text-center" style={{ padding: 15 }}>
                                    <h5 className="size-text" style={{ color: 'white' }}>Action</h5>
                                </div>
                            </div>
                            {/* <div className="row">
                        <div className="col-sm-6 ">
                          
                                <div className="row" style={{ margin: '10px 0' }} >
                                    <div className="card mZero flex-row align-item-center col-md-12"   >
                                      
                                    </div>
                                </div>
                            
                        </div>
                    </div> */}
                            <div style={{ display: 'flex' }}>
                                <div className='col-6' style={{ border: '1px solid white', borderRadius: 5, height: 500, overflow: 'hidden' }}>
                                    <div style={{ margin: 10 }} > <h4 style={{ color: 'white', textAlign: 'center' }}> Pending </h4></div>
                                    <div style={{ overflow: 'scroll', maxHeight: '100%', height: 440 }}>

                                        {this.state.pendingList.length !== 0 &&

                                            this.state.pendingList.map((item, data) => {
                                                return (
                                                    <div className='card' style={{
                                                        width: '80%', margin: '0 auto', marginBottom: 10,
                                                        background: this.checkDue(item.date + ' ' + item.time, null), borderLeft: '10px solid', borderLeftColor: this.renderColor(item.status) == '#ffa500' ? '#ffa500' : this.checkDue(item.date + ' ' + item.time) == '#ffdfda' ? '#ffdfda' : '#fff'
                                                    }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div> <h4 style={{ color: 'brown' }}>Due date: {this.convertDate(item.date)} </h4></div>
                                                            <div ><h4 style={{ color: 'brown' }}>Due time: {this.convertTime(item.time)}</h4> </div>
                                                        </div>

                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} onClick={() => this.onExpand(item)}>
                                                            <div style={{ fontSize: 18 }}>
                                                                {item.title}
                                                            </div>
                                                            <div>  {item.expanded === "1" ? <span className="material-icons">expand_less</span> : <span className="material-icons">expand_more</span>}</div>
                                                        </div>
                                                        {item.expanded === '1' &&
                                                            <div>
                                                                <div>
                                                                    {this.attachment(item.attachment)}
                                                                </div>


                                                            </div>
                                                        }
                                                        <div style={{ maxHeight: 100, overflow: 'scroll', fontSize: '12px !important', color: '#808080 !important' }} dangerouslySetInnerHTML={{ __html: item.message }}>
                                                            </div>
                                                        <div >
                                                            {this.checkbox_icon(item)}
                                                        </div>


                                                        <div style={{ textAlign: 'end', }}>
                                                            <h4 style={{ color: 'brown' }}>Received: {this.renderCreatedDate(item.created_at, 3)}</h4>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }


                                    </div>
                                </div>

                                <div className='col-6' style={{ border: '1px solid white', borderRadius: 5, marginLeft: 5, height: 500, overflow: 'hidden' }}>
                                    <div style={{ margin: 10 }} > <h4 style={{ color: 'white', textAlign: 'center' }}> Archive </h4></div>
                                    <div style={{ overflow: 'scroll', maxHeight: '100%', height: 440 }}>

                                        {this.state.archiveList.length !== 0 &&

                                            this.state.archiveList.map((item) => {
                                                return (
                                                    <div className='card' style={{ width: '80%', margin: '0 auto', marginBottom: '10px', background: this.checkDue(item.date + ' ' + item.time, item.updated_at), borderLeft: '10px solid', borderLeftColor: this.renderArchiveColor(item.status) }}>
                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div> <h4 style={{ color: 'brown' }}>Due date: {this.convertDate(item.date)} </h4></div>
                                                            <div ><h4 style={{ color: 'brown' }}>Due time: {this.convertTime(item.time)}</h4> </div>
                                                        </div>
                                                        {/* <div>{item.title}</div>
                                                        <div dangerouslySetInnerHTML={{ __html: item.message }} /> */}
                                                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }} onClick={() => this.onExpand(item)}>
                                                            <div>{item.title}</div>
                                                            <div>  {item.expanded === "1" ? <span className="material-icons">expand_less</span> : <span className="material-icons">expand_more</span>}</div>
                                                        </div>
                                                        {item.expanded === '1' &&
                                                            <div>
                                                                <div>
                                                                    {this.attachment(item.attachment)}
                                                                </div>


                                                            </div>
                                                        }
                                                        <div style={{ maxHeight: 100, overflow: 'scroll' }} dangerouslySetInnerHTML={{ __html: item.message }} />

                                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <div>
                                                                <h4 style={{ color: 'brown' }}>Recevied:</h4>
                                                                <h4> {this.renderReceivedDate(item.created_at)}</h4>
                                                            </div>
                                                            <div style={{ textAlign: 'end', }}>
                                                                <h4 style={{ color: 'brown' }}>Archived: </h4>
                                                                <h4>{this.renderCreatedDate(item.updated_at)}</h4>
                                                            </div>
                                                        </div>
                                                    </div>

                                                )
                                            })
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        );

    }
}
export default Action;