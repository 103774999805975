import React, { Component } from "react";
import "../css/App.css";
import API from "../config_api";
import axios from "axios";
import { Doughnut } from "react-chartjs-2";
import { CircularProgressbar } from "react-circular-progressbar";
import ProgressBar from "@ramonak/react-progress-bar";
import "react-circular-progressbar/dist/styles.css";
import Collapse from "@kunukn/react-collapse";
import Sidenav from "./Sidenav";
import { ActionSheetOptionStyle } from "@capacitor/core";
class Insight extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data1: [],
      data2: [],
      insights: "",
      actionindex: "",
      red: "",
      blue: "",
      orange: "",
      green: "",
      percentage: "",
      isOpen: false,
      isOpen1: false,
      actions: "",
    };
  }

  componentWillMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "https://app.acuizen.com";
    }
    let formData2 = new FormData();
    formData2.append("user_id", this.userID());
    formData2.append("date", this.getDate());
    formData2.append(
      "time",
      new Date().toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })
    );
    axios({
      method: "post",
      url: API.UserReport,
      data: formData2,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      // this.setState({ red: res.data.data.insights.red })
      // this.setState({ green: res.data.data.insights.green })
      // this.setState({ red: res.data.data.insights.blue })
      // this.setState({ orange: res.data.data.insights.orange })
      this.setState({ insights: res.data.data.insights });
      this.setState({ percentage: res.data.data.overall_percentage });
      this.setState({ actionindex: res.data.data.action.acion_index });
      this.setState({ actions: res.data.data.action });
    });
    let formData1 = new FormData();
    formData1.append("user_id", localStorage.getItem("token"));
    formData1.append("company_id", localStorage.getItem("company_no"));
    axios({
      method: "post",
      url: API.TopArea,
      data: formData1,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      this.setState({ data: res.data.data });
    });
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("token"));
    axios({
      method: "post",
      url: API.NotAttentedUnits,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      this.setState({ data1: res.data.data });
    });
    axios({
      method: "post",
      url: API.UserAreaDropDown,
      data: formData,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      mode: "no-cors",
    }).then((res) => {
      console.log(res.data.data);

      this.setState({ data2: res.data.data });
    });
  }
  getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };
  getTime = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };
  userID = () => {
    const userID = localStorage.getItem("token");
    const ID = userID.split("@");
    console.log(ID[0]);
    return ID[0];
  };
  btnAction(data) {
    console.log(data);
    this.props.history.push("/units/" + data.program_id);
    localStorage.setItem("unit_name", data.title);
  }
  Onpress() {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  }
  Onpress1() {
    this.setState((prevState) => ({
      isOpen1: !prevState.isOpen1,
    }));
  }
  selectOption(event) {
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("token"));
    if (event.target.value !== "0") {
      formData.append("area_id", event.target.value);
      axios({
        method: "post",
        url: API.AreaLevelInsight,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        mode: "no-cors",
      }).then((res) => {
        console.log(res.data.data);

        // this.setState({ red: res.data.data.insights.red })
        // this.setState({ green: res.data.data.insights.green })
        // this.setState({ red: res.data.data.insights.blue })
        // this.setState({ orange: res.data.data.insights.orange })
        this.setState({ insights: res.data.data.insights });
        this.setState({ percentage: res.data.data.overall_percentage });
        this.setState({ actionindex: res.data.data.action.acion_index });
        this.setState({ actions: res.data.data.action });
      });
    } else {
      axios({
        method: "post",
        url: API.UserReport,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        mode: "no-cors",
      }).then((res) => {
        // this.setState({ red: res.data.data.insights.red })
        // this.setState({ green: res.data.data.insights.green })
        // this.setState({ red: res.data.data.insights.blue })
        // this.setState({ orange: res.data.data.insights.orange })
        this.setState({ insights: res.data.data.insights });
        this.setState({ percentage: res.data.data.overall_percentage });
        this.setState({ actionindex: res.data.data.action.acion_index });
        this.setState({ actions: res.data.data.action });
      });
    }
  }
  render() {
    return (
      <div className="">
        <Sidenav type={"second"} url={"/dashboard"} />

        <div className="container">
          <div
            className="p-4 mt-4"
            style={{ background: "#ffffff", borderRadius: "22px" }}
          >
            <div className="row">
              <div className="col-12">
                <div>
                  <label
                    style={{
                      fontSize: "16px",
                      color: "#005284",
                      fontWeight: "700",
                    }}
                  >
                    Select Topic
                  </label>
                  <select
                    class="form-control"
                    style={{
                      border: 0,
                      border: ".75px solid #005284",
                      marginBottom: 20,
                      borderRadius: 4,
                    }}
                    onChange={(event) => this.selectOption(event)}
                  >
                    {this.state.data2.map((data, i) => (
                      <option value={data.value} key={i}>
                        {data.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                {/* knowlege index chart */}
                <div className="card flex horizontal mb-0 h-100">
                  <p
                  className="mb-0"
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#005284",
                    }}
                  >
                    Knowledge Index
                  </p>

                  <div className="flex justify-content-center">
                    <div
                      style={{
                        width: 320,
                        height: "auto",
                        position: "relative",
                      }}
                    >
                      <div
                        className="index"
                        style={{ fontSize: 40, bottom: 25, fontWeight: 500 }}
                      >
                        {this.state.insights.insight_index === null ||
                        this.state.insights.insight_index === " " ||
                        this.state.insights.insight_index === 0 ||
                        this.state.insights.insight_index === "0"
                          ? "No Data"
                          : Math.round(this.state.insights.insight_index)}
                      </div>
                      <Doughnut
                        data={{
                          labels: ["Low Risk", "Lucky", "Risky", "High Risk"],
                          datasets: [
                            {
                              backgroundColor: [
                                "#7ed321",
                                "#005284",
                                "#f5a623",
                                "#be1f24",
                              ],
                              hoverBackgroundColor: [
                                "#be1f24",
                                "#005284",
                                "#7ed321",
                                "#f5a623",
                              ],
                              borderWidth: 0,
                              data: [
                                Math.round(this.state.insights.red),
                                Math.round(this.state.insights.blue),
                                Math.round(this.state.insights.green),
                                Math.round(this.state.insights.orange),
                              ],
                            },
                          ],
                        }}
                        width={120}
                        height={100}
                        options={{
                          title: {
                            display: false,
                          },
                          legend: {
                            display: true,
                            position: "bottom",
                            labels: {
                              boxWidth: 10,
                            },
                          },
                          cutoutPercentage: 80,
                        }}
                      />
                    </div>
                  </div>


                        <div >
                          <h5 className="mb-0">Pull Rate :</h5>

                          <div className="d-flex align-items-center justify-content-start">
                            <div
                              style={{
                                width: "100%",
                                height: "100%",
                                border: "1px solid lightgrey",
                                borderRadius: 4,
                              }}
                            >
                              <div
                                style={{
                                  height: "12px",
                                  width: this.state.percentage + "%",
                                  background: "#005284",
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </div>

                            <p
                              className="mb-0 ml-3"
                              style={{
                                fontSize: 16,
                                fontWeight: 700,
                                color: "#005284",
                              }}
                            >{`${Math.round(
                              this.state.percentage
                            )}%`}</p>
                          </div>
                        </div>

                  {/* <div
                    style={{
                      flexDirection: "row",
                      padding: "5px",
                      marginBottom: 5,
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ minWidth: "6%", height: "100%" }}>
                      <h5 style={{ marginBottom: "0px" }}>Pull Rate :</h5>
                    </div>
                    <div
                      style={{
                        minWidth: "75%",
                        height: "100%",
                        border: "1px solid lightgrey",
                        borderRadius: 5,
                      }}
                    >
                      <div
                        style={{
                          height: "16px",
                          width: this.state.percentage + "%",
                          background: "#0083d2",
                          borderRadius: "4px",
                          padding: "5px",
                        }}
                      ></div>
                    </div>
                    <div style={{ minWidth: "7%", marginLeft: "5px" }}>
                      <h5 style={{ marginBottom: "0px" }}>{`${Math.round(
                        this.state.percentage
                      )}%`}</h5>
                    </div>
                  </div> */}
                </div>
                {/* knowledge index chart */}
              </div>
              <div className="col-12 col-md-6 mt-3 mt-md-0">
                {/* Action index chart */}
                <div className="card flex horizontal mb-0 h-100">
                  <p
                  className="mb-0"
                    style={{
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "#005284",
                    }}
                  >
                    Action Index
                  </p>
                  {/* <div>Pull Rate</div> */}
                  <div style={{ display: "flex" }}>
                    <div className="flex justify-content-center">
                      <div
                        style={{
                          width: 320,
                          height: "auto",
                          position: "relative",
                        }}
                      >
                        <div
                          className="index"
                          style={{ fontSize: 40, bottom: 25, fontWeight: 500 }}
                        >
                          {this.state.actionindex === null ||
                          this.state.actionindex === " " ||
                          this.state.actionindex === 0 ||
                          this.state.actionindex === "0"
                            ? "No Data"
                            : Math.round(this.state.actionindex)}
                        </div>
                        <Doughnut
                          data={{
                            labels: [
                              "Completed on time",
                              "Completed with delay",
                              "Incomplete",
                              "Inaction",
                            ],
                            datasets: [
                              {
                                backgroundColor: [
                                  "#7ed321",
                                  "#005284",
                                  "#f5a623",
                                  "#be1f24",
                                ],
                                hoverBackgroundColor: [
                                  "#be1f24",
                                  "#005284",
                                  "#7ed321",
                                  "#f5a623",
                                ],
                                borderWidth: 0,
                                data: [
                                  Math.round(this.state.actions.red),
                                  Math.round(this.state.actions.blue),
                                  Math.round(this.state.actions.green),
                                  Math.round(this.state.actions.orange),
                                ],
                              },
                            ],
                          }}
                          width={120}
                          height={100}
                          options={{
                            title: {
                              display: false,
                            },
                            legend: {
                              display: true,
                              position: "bottom",
                              // labels: {
                              //     boxWidth:10
                              // }
                            },
                            cutoutPercentage: 80,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Action index chart */}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-12">
              <label
                style={{
                  fontSize: "16px",
                  color: "#005284",
                  fontWeight: "700",
                }}
              >
                Select Topic
              </label>
              <select
                class="form-control"
                style={{
                  border: 0,
                  borderBottom: "1px solid",
                  marginBottom: 20,
                  borderRadius: 0,
                }}
                onChange={(event) => this.selectOption(event)}
              >
                {this.state.data2.map((data, i) => (
                  <option value={data.value} key={i}>
                    {data.label}
                  </option>
                ))}
              </select> */}

        {/* <div style={{ flexDirection: 'row', padding: '5px', marginBottom: 5, display: 'flex', alignItems: 'center' }}>
                                            <div style={{ minWidth: '18%', height: '100%' }}>
                                                <h5 style={{ marginBottom: '0px' }}>Participation :</h5>
                                            </div>
                                            <div style={{ minWidth: '75%', height: '100%', border: '1px solid lightgrey', borderRadius: 5 }} >
                                                <div style={{ height: '16px', width: this.state.percentage + '%', background: '#0083d2', borderRadius: '4px', padding: '5px' }} ></div>
                                            </div>
                                            <div style={{ minWidth: '7%', marginLeft: '5px' }}>
                                                <h5 style={{ marginBottom: '0px' }}>{`${Math.round(this.state.percentage)}%`}</h5>
                                            </div>
                                    </div> */}
        {/* </div> */}

        {/* <div className="horizontal" style={{ padding: 0 }}>
                                <div className="card row" style={{ padding: 10, margin: 0, height: 45 }} onClick={() => this.Onpress1()} >
                                    <div className="col-sm-11 col-xs-11 padZero flex align-space-between"><div>Units not Viewed</div>
                                        <span className="topic">{this.state.data1.length} Unit(s) </span>
                                    </div>
                                    <div className="col-sm-1 col-xs-1 pad_z"> {this.state.isOpen1 === true ?
                                        <i className="material-icons">
                                            keyboard_arrow_down
</i> : <i className="material-icons">
                                            keyboard_arrow_right
</i>}</div>
                                </div>
                                <Collapse isOpen={this.state.isOpen1} >
                                    {this.state.isOpen1 === true ?
                                        this.state.data1.map((data, i) =>
                                            <div className="unit_assign col-sm-12" key={i} onClick={() => this.btnAction(data)}>
                                                <div className="col-sm-11 col-xs-11 pad_z">{data.title}</div>
                                                <div className="col-sm-1 col-xs-1 pad_z"><i className="material-icons">
                                                    keyboard_arrow_right
</i></div>

                                            </div>) : ''}
                                </Collapse>
                            </div> */}
        {/* <div className="horizontal" style={{ padding: 0 }}>
                                <div className="card row " style={{ padding: 10, margin: 0, height: 45 }} onClick={() => this.Onpress()} >
                                    <div className="col-sm-11 col-xs-11 pad_z flex align-space-between"><div>Trending Units</div>
                                        <span className="topic">{this.state.data.length} Unit(s) </span></div> <div className="col-sm-1 col-xs-1 pad_z"> {this.state.isOpen === true ?
                                            <i className="material-icons">
                                                keyboard_arrow_down
</i> : <i className="material-icons">
                                                keyboard_arrow_right
</i>}</div>
                                </div>
                                <Collapse isOpen={this.state.isOpen} >
                                    {this.state.isOpen === true ?
                                        this.state.data.map((data, i) =>
                                            <div className="unit_assign col-sm-12" key={i} onClick={() => this.btnAction(data)}>
                                                <div className="col-sm-11 col-xs-11 pad_z">{data.title}</div>
                                                <div className="col-sm-1 col-xs-1 pad_z"><i className="material-icons">
                                                    keyboard_arrow_right
</i></div>

                                            </div>) : ''}
                                </Collapse>
                            </div> */}
      </div>
      //   </div>
      // </div>
      //   </div>
    );
  }
}
export default Insight;
