import React, {useState} from "react";

function FormInput(props) {
  const [inputType] = useState(props.type)
  const [inputValue, setInputValue] = useState('')
  const [inputName] = useState(props.name)
  const [inputPlaceHolder] = useState(props.placeholder)
  function handleChange(event){
    setInputValue(event.target.value);
    if(props.onChange) props.onChange(inputValue)
  }
  return (
    <>
      <input type={inputType} value={inputValue} name={inputName} onChange={handleChange} className="form-control" placeholder={inputPlaceHolder}/>
    </>
  );
}
export default FormInput;