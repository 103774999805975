import { combineReducers } from 'redux'
import DataReducer from './microData/MicroReducer'
import PauseReducer from './pauseData/pauseReducer'
import AssignReducer from './assignedData/AssignReducer'
import LoginReducer from './loginData/LoginReducer'
import Firebase from './firebase/FirebaseReducer'
import Notification from './notificationData/NotificationReducer'
const rootReducer = combineReducers({
    data: DataReducer,
    assign: AssignReducer,
    login: LoginReducer,
    firebaseData: Firebase,
    pause: PauseReducer,
    noti: Notification

})
export default rootReducer;