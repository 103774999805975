import React, { Component } from "react";
import Unity from 'react-unity-webgl'



class Test extends Component {
  constructor() {
    super();
    this.state = {
      messages: [],
     
    };
  }

  
 
  
  render() {
    return (
      <div >
      <Unity 
            src='webgl/index.html' 
            loader='webgl/Release/UnityLoader.js' />
      </div>
    );
  }
}



export default Test
