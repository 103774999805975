import React, { useState } from "react";
function Card(props) {
  const { customFont, customFontWeight, customCardHeight } = props;
  const [img] = useState(props.img);
  const [Title] = useState(props.Title);
  if (props.type === "1") {
    return (
      <div
        className="card flex justify-content-between"
        onClick={() => props.Push.push(props.Url)}
      >
        <div className=" flex justify-content-between align-item-center">
          <img src={img} alt="bulp" style={{ width: 50 }} />
          <h4 className="count">{props.Count}</h4>
        </div>
        <div className=" flex justify-content-between align-item-center">
          <h4 className="size-text">{Title}</h4>
          <span className="material-icons">keyboard_arrow_right</span>
        </div>
      </div>
    );
  } else if (props.type === "2") {
    return (
      <div className="card flex" onClick={() => props.Push.push(props.Url)}>
        <div className=" flex justify-content-between align-item-center">
          <div className="align-item-center">
            <img src={img} alt="bulp" style={{ width: 35, marginRight: 20 }} />
            <h4 className="size-text">{Title}</h4>
          </div>
          <div className="align-item-center">
            <h4 className="count">{props.Count}</h4>
            <span className="material-icons">keyboard_arrow_right</span>
          </div>
        </div>
      </div>
    );
  } else if (props.type === "3") {
    return (
      <div
        className={`card flex justify-content-center mb-0 `}
        style={{
          height:
            customCardHeight == "" || customCardHeight == null
              ? 70
              : customCardHeight,
        }}
        onClick={() => props.Push.push(props.Url)}
      >
        <div className=" flex justify-content-center align-item-center">
          <img
            src={img}
            alt="bulp"
            style={Title === "Observations" ? { width: 37 } : { width: 30 }}
          />
        </div>
        {Title && (
          <div className=" flex justify-content-center align-item-center">
            <h4
              className="m-0 mt-1"
              style={{ fontSize: customFont, fontWeight: customFontWeight }}
            >
              {Title}
            </h4>
          </div>
        )}
      </div>
    );
  } else if (props.type === "4") {
    return (
      <div
        className="card flex justify-content-between"
        onClick={() => props.Push.push(props.Url)}
      >
        <div className=" flex justify-content-center align-item-center">
          <img
            src={img}
            alt="bulp"
            style={Title === "Observations" ? { width: 37 } : { width: 30 }}
          />
        </div>
        {Title && (
          <div className=" flex justify-content-center align-item-center">
            <h4 className="m-0 mt-1">{Title}</h4>
          </div>
        )}
      </div>
    );
  }
}
export default Card;
