import React, { Component } from 'react';
import Sidenav from './Sidenav';
import '../css/App.css';
import IosArrowDown from 'react-ionicons/lib/IosArrowDown'
import axios from 'axios'
import { Modal, Button, Form } from 'react-bootstrap'

class Spaces extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            show: false,
            show1: false,
            show2: false,
            single: {}

        };
    }
    componentWillMount() {
        axios({
            method: 'get',
            url: 'https://board.acuizen.com/api/spaces?parent_space_id=' + this.props.location.state.data.home_folder_id,
            data: '',
            headers: {
                'x-spacedeck-api-token': 'secretkey',
            },
            mode: 'cors',
        })
            .then(res => {
                console.log(res.data)
                this.setState({ data: res.data })


            });
    }
    modalShow() {
        this.setState({
            show: true
        })
    }
    handleClose() {
        this.setState({
            show: false
        })
    }
    modalShow1() {
        this.setState({
            show1: true
        })
    }
    handleClose1() {
        this.setState({
            show1: false
        })
    }

    handleClose2() {
        this.setState({
            show2: false
        })
    }
    onSpaceData(e) {
        e.preventDefault();
        console.log(e.target[0].value)
        axios({
            method: 'post',
            url: 'https://board.acuizen.com/api/spaces',
            data: {
                artifacts: [],
                name: e.target[0].value,
                parent_space_id: this.props.location.state.data.home_folder_id,
                space_type: "space"
            },
            headers: {
                'x-spacedeck-api-token': 'secretkey',
            },
            mode: 'cors',
        })
            .then(res => {
                console.log(res.data)
                // this.setState({ data: res.data })
                window.location.reload(true);

            });
    }
    onFolderData(e) {
        e.preventDefault();
        console.log(e.target[0].value)
        axios({
            method: 'post',
            url: 'https://board.acuizen.com/api/spaces',
            data: {
                artifacts: [],
                name: e.target[0].value,
                parent_space_id: this.props.location.state.data.home_folder_id,
                space_type: "folder"
            },
            headers: {
                'x-spacedeck-api-token': 'secretkey',
            },
            mode: 'cors',
        })
            .then(res => {
                console.log(res.data)
                // this.setState({ data: res.data })
                window.location.reload(true);

            });
    }
    onDelete(id) {
        axios({
            method: 'delete',
            url: 'https://board.acuizen.com/api/spaces/' + id,
            data: '',
            headers: {
                'x-spacedeck-api-token': 'secretkey',
            },
            mode: 'cors',
        })
            .then(res => {
                console.log(res.data)
                // this.setState({ data: res.data })
                window.location.reload(true);

            });
    }
    onRename(e) {

        axios({
            method: 'put',
            url: 'https://board.acuizen.com/api/spaces/' + this.state.single._id,
            data: '',
            headers: {
                'x-spacedeck-api-token': 'secretkey',
            },
            mode: 'cors',
        })
            .then(res => {
                console.log(res.data)
                // this.setState({ data: res.data })


            });
    }
    openSpaces(data) {
        console.log(data)
        if (data.space_type === "folder") {
           
            window.open('https://board.acuizen.com/folders/' + data._id)
        }
        else {
            this.props.history.push('/singlespace/' + data._id)
           // window.open('https://board.acuizen.com/spaces/' + data._id)
        }
    }
    render() {
        console.log(this.props)
        return (
            <div className="" >
                <Sidenav type={'second'} Push={this.props.history} url={'back'} />
                <div className="container">
                    <div className="row">
                        <div className='row mt-4 mb-4'> <button class="btn create-spaces" onClick={() => this.modalShow1()}>Create Space</button>
                            <button class="btn create-folder" onClick={() => this.modalShow()}>
                                <span>Create Folder</span>
                            </button></div>

                        <div className="col-sm-12 text-center" id="folder-grid">
                            <div className="row">
                                {this.state.data.map((item, i) =>

                                    <div key={i} className={item.space_type === "folder" ? 'col-md-4 item folder' : 'col-md-4 item'} >
                                        <span onClick={() => this.openSpaces(item)}>
                                            <span className="item-thumbnail"></span>
                                        </span>
                                        <div class="item-meta flex align-item-center justify-content-between">
                                            <span class="item-title">{item.name}</span>
                                            <div class=" dropdown  light">
                                                <button type="button" className="btn pad_z" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <IosArrowDown />
                                                </button>

                                                <div class="dropdown-menu" role="menu">
                                                    <ul class="select-list">
                                                        <p onClick={() => this.modalShow2(item)}>Rename</p>
                                                        <p onClick={() => this.onDelete(item._id)}>Delete</p>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={this.state.show} onHide={() => this.handleClose()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Folder</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.onFolderData(e)}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Enter Folder Name" />
                            </Form.Group>
                            <Button variant="primary" type="submit" >
                                Submit
  </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.show1} onHide={() => this.handleClose1()}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Space</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form onSubmit={(e) => this.onSpaceData(e)}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Enter Space Name" />
                            </Form.Group>
                            <Button variant="primary" type="submit" >
                                Submit
  </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={this.state.show2} onHide={() => this.handleClose2()}>
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Create Space</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body>
                        <Form onSubmit={(e) => this.onRename(e)}>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control type="text" placeholder="Enter ReName" />
                            </Form.Group>
                            <Button variant="primary" type="submit" >
                                Submit
  </Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
        );
    }
}

export default Spaces;