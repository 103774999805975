import React, { Component } from 'react';
import '../css/App.css';
import API from '../config_api'
import axios from 'axios'
import FormInput from '../component/FormInput';
import Button from '../component/Button';
class ForGotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append('usremail', event.target[0].value);

        axios({
            method: 'post',
            url: API.Forgot,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                if (res.data.status === 'success') {
                    alert(res.data.message);

                } else {
                    alert(res.data.message);
                }
            });

    }

    render() {
        return (
        
            <div className="main-content h-h ">
            <div className="header py-7 py-lg-8 pt-lg-8">
                <div className="container">
                    <div className="header-body text-center mb-7">
                        <div className="row justify-content-center">
                            <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                                <h1>Forgot Password!</h1>
                                <p className="text-lead text-white"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt--9 pb-5">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-7">
                        <div className="card border-0 mb-0 pad_z">

                            <div className="card-body px-lg-5 py-lg-5">
                                <div className="text-center">
                                    <img src={localStorage.getItem('logo')} alt="wavemakers_logo" className="img-responsive mb-40" style={{height:100}}/>
                                </div>
                                {/* <div className="text-center text-muted mb-4">
                                    <small>Lets get Started</small>
                                </div> */}
                                <form onSubmit={this.handleSubmit}>
                                    <div className="form-group mb-3">
                                        <div className="input-group input-group-merge ">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="ni ni-email-83"></i></span>
                                            </div>
                                            <FormInput name={"email"} placeholder={"Your Email"} type={"email"} />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <Button color={"btn-primary az-color"} type={"submit"} variant={"my-4"} size={"large"}>Reset Password</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row mt-3">
                                <div class="col-6">
                                {window.location.href.includes('webapp.acuizen.com')?
                                 <a href="/login" style={{color:'#000'}}><small>	<i class="fas fa-arrow-left"></i> Back to Login</small></a>
            :
            <a href="/wavelogin" style={{color:'#000'}}><small>	<i class="fas fa-arrow-left"></i> Back to Login</small></a>
            }
                                   
                                </div>
                               
                            </div>
                    </div>
                </div>
            </div>

        </div>
        
        );
    }
}

export default ForGotPassword;