import React, { Component } from 'react';
import '../css/App.css';
import { fetchData } from '../redux/assignedData/AssignAction'
import { connect } from 'react-redux'
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import Sidenav from './Sidenav';
import Loader from '../component/Loader';
import IdleTimerLogout from './IdleTimerLogout';

class AreaAllocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
         
        };
       
    }
   
    componentWillMount() {
        if(!localStorage.getItem('token')){
            window.location.href ="https://app.acuizen.com";
        }
        
        this.props.getAreaData();
    }
    btnAction(models) {
        this.props.history.push('/unit',{data:models})
      }
    render() {
       console.log(this.props)
        return (
            <div className="">
                <IdleTimerLogout/>
              <Sidenav type={'second'} url={'/dashboard'}/>
                {/* <ReactCSSTransitionGroup
            transitionAppear={true}
            transitionAppearTimeout={600}
            transitionEnterTimeout={600}
            transitionLeaveTimeout={200}
            transitionName={this.props.match.path === '/area' ? 'SlideIn' : 'SlideOut'}
          > */}
                <div className="container">
                    
                    <div className="row">
                        <div className="col-sm-12 pad_z">

                            {this.props.data.map((item, i) => {
                                return (

                                    <div className="card justify-content-center row" style={{ padding: 10,margin:10, height: 55 }} key={i} onClick={()=>this.btnAction(item)}>
                                        <div className="col-sm-2 col-xs-2 pad_z">
                                            {item.image ?
                                                <img src={item.image} alt="logo" className="responsive-img" style={{ width: 35 }}/>
                                                : <span className="letter">{item.name.charAt(0)}</span>}
                                        </div>
                                            <div className="col-sm-9 col-xs-9 padZero flex align-space-between"><div>{item.name}</div> 
                                            <span className="topic">{item.models.length} Topic(s) </span>
                                            </div>
                                        <div className="col-sm-1 col-xs-1 pad_z text-right"><i className="material-icons">
                                            keyboard_arrow_right
</i></div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>

                </div>
                {/* </ReactCSSTransitionGroup> */}
                {this.props.loader && <Loader/>}
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        data: state.assign.data,
        loader: state.assign.loading,
        error:state.assign.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAreaData: () => dispatch(fetchData())
    }
}
export default  connect(mapStateToProps, mapDispatchToProps)(AreaAllocation);