import React, { Component } from "react";
import "../css/App.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import IdleTimerLogout from "./IdleTimerLogout";
class Overview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentWillMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "https://app.acuizen.com";
    }
    this.setState({ data: this.props.location.state });
    console.log(this.props.location.state);
  }
  _start(len) {
    if (len > 0) {
      this.props.history.push(
        "/units/" + this.props.location.state.data.program_id,
        { checklist: false }
      );
    } else {
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="row">
              <div
                className="col-12"
                style={{
                  width: 300,
                  background: "#fff",
                  padding: 20,
                  boxShadow: "0px 0px 12px 3px #dbdbdb",
                }}
              >
                <h3 className="">AcuiZen</h3>
                <p>Oops! There is no content here yet.</p>
                <div className="flex justify-content-end">
                  <span
                    style={{ color: "#005284", fontSize: 14 }}
                    onClick={() => {
                      this.props.history.push("/dashboard");
                      onClose();
                    }}
                  >
                    {" "}
                    OK{" "}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      });
    }
  }
  render() {
    const len = this.state.data.data.microstep_titles.length;
    const datalength = 100 / this.state.data.data.microstep_titles.length;
    console.log(len);
    return (
      <div className="">
        <IdleTimerLogout />
        <nav class="navbar navbar-top navbar-expand navbar-dark  border-bottom">
          <div class="container pad_z">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <div className="col-sm-3 pad_z ">
                <i
                  className="material-icons back-btn grey-text"
                  onClick={() => this.props.history.goBack()}
                  style={{ cursor: "pointer", color: "#005284" }}
                >
                  arrow_back
                </i>
              </div>
              <div
                className="col-sm-6 text-center pad_z"
                style={{
                  fontSize: "16px",
                  color: "#005284",
                  fontWeight: "700",
                }}
              >
                OverView
              </div>
              <div className="col-sm-3 text-right  pad_z vertical-align justify-content-end"></div>
            </div>
          </div>
        </nav>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-10">
              <div
                className="p-4 mt-4"
                style={{ background: "#ffffff", borderRadius: "22px" }}
              >
                <div className="card az-color text-white">
                  <h4
                    className="mb-0"
                    style={{
                      textTransform: "capitalize",
                      fontSize: "14px",
                      letterSpacing: ".5px",
                      fontWeight: "500",
                      color: "#ffffff"
                    }}
                  >
                    {this.state.data.data.program_title}
                  </h4>
                </div>
                <div
                  style={{
                    display: "flex",
                    height: "50vh",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    background: "#E1EBED",
                    borderRadius: "8px",
                    padding: "15px",
                  }}
                >
                  <div
                    style={{ textAlign: "justify", textTransform: 'capitalize' }}
                    dangerouslySetInnerHTML={{
                      __html: this.state.data.data.description,
                    }}
                  />
                  <div className="steps pad_z">
                    {this.state.data.data.microstep_titles.map((item, i) => {
                      return (
                        <span
                          key={i}
                          className="step"
                          style={{
                            width: datalength + "%",
                            background: "#b5bfc5",
                            height: "3.2px",
                          }}
                        ></span>
                      );
                    })}
                  </div>
                </div>
                <div className="col-12 step-btn1 justify-content-center mt-3 px-0">
                    
                  <button
                    type="button"
                    className="btn btn-primary btn-lg"
                    style={{ display: 'initial', padding: '12px 16px !important' }}
                    onClick={() => this._start(len)}
                  >
                    Start
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Overview;
