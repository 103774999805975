import axios from 'axios';
import API from '../../config_api'
import { FETCH_PAUSE_DATA_REQUEST, FETCH_PAUSE_DATA_SUCCESS, FETCH_PAUSE_DATA_FAILURE } from './pauseTypes'
export const fetchDataRequest = () => {
    return {
        type: FETCH_PAUSE_DATA_REQUEST
    }
}
export const fetchDataSuccess = data => {
    return {
        type: FETCH_PAUSE_DATA_SUCCESS,
        payload: data
    }
}
export const fetchDataFailure = error => {
    return {
        type: FETCH_PAUSE_DATA_FAILURE,
        payload: error
    }
}

export const fetchData = (session_id, UnitId) => {
    return (dispatch) => {
        dispatch(fetchDataRequest());
        let formData = new FormData();
        formData.append('user_id', localStorage.getItem('token'))
        formData.append('session_id', session_id)
        formData.append('program_id', UnitId)
        axios({
                method: 'post',
                url: API.PauseSession,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
            .then(res => {
                if (res.data.status === 'success') {
                    const data = res.data;
                    console.log(res.data.data);
                    dispatch(fetchDataSuccess(data))

                } else {
                    const error = res.data.message;
                    dispatch(fetchDataFailure(error))
                }
            });
    }
}