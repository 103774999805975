import React, { Component } from 'react';
import axios from 'axios';
import API from '../config_api';
import '../css/App.css';
import Sidenav from './Sidenav';
import IdleTimerLogout from './IdleTimerLogout';


class AboutUs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: ''
           

        };
      
    }
    componentWillMount() {
        
        axios({
            method: 'post',
            url: API.AboutUs,
           
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                if (res.data.status === 'success') {
                    this.setState({ data: res.data.data.scalar })
                    
                }
                else {
                    alert(res.data.message)
                }
            });

    }

    render() {
        return ( <div className = "" >
            <IdleTimerLogout/>
            <Sidenav type={'second'} url={'/dashboard'}/>
            <div className="container">
                <div className="row">
                <div className="col s12">
                <div style={{position:'fixed',left:0,right:0,zIndex:1,background:'#F8F9FE'}}>
                        <div className="container">
                        <div className="row">
                           
                            <div className="col-md-12 text-center" style={{ padding: 15 }}>
                                <h5 className="size-text">About Us</h5>
                            </div>
                        </div>
                      </div>
                      </div>
                      <div style={{height:60}}></div>
                <div dangerouslySetInnerHTML={{ __html: this.state.data }}  className="about"/>

                </div>
                </div>
            </div>
            
            </div>
        );
    }
}

export default AboutUs;