import React, { Component } from 'react';

import Sidenav from './Sidenav';
import '../css/App.css';
import IdleTimerLogout from './IdleTimerLogout';



class MyAccount extends Component {
    componentWillMount() {
        if (!localStorage.getItem('token')) {
            window.location.href = "https://app.acuizen.com";
        }
    }

    render() {
        return (<div className="" >
            <IdleTimerLogout />
            <Sidenav type={'second'} url={'/dashboard'} />
            <div className="container">
                <div style={{ position: 'fixed', left: 0, right: 0, zIndex: 1, background: '#F8F9FE' }}>
                    <div className="container">
                        <div className="row">
                        <i className="material-icons">
                                    person
                                </i>
                            <div className="col-md-12 text-center" style={{ padding: 15 }}>
                                
                                <h5 className="size-text">My Account</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: 60 }}></div>
                <div className="row">
                    <div className="col s12 account">
                        <p>Employee ID : {localStorage.getItem('emp_id')}</p>
                        <p>Email ID : {localStorage.getItem('email')}</p>
                        <p>First Name : {localStorage.getItem('firstname')}</p>
                        <p>Last Name : {localStorage.getItem('lastname')}</p>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default MyAccount;