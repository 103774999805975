import React, { Component } from 'react';
import IdleTimer from 'react-idle-timer';
import { IdleModal } from '../component/IdleModal';
class IdleTimerLogout extends Component {

    constructor(props) {
        super(props)

        this.state = {
            timeout: 1000 * 60 * 15,
            showModal: false,
            userLoggedIn: false,
            isTimedOut: false
        }

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    _onAction(e) {
        this.setState({isTimedOut: false})
      }
     
      _onActive(e) {
        this.setState({isTimedOut: false})
      }
     
      _onIdle(e) {
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
        //    window.location.href = '/logout'
        } else {
          this.setState({showModal: true})
          this.idleTimer.reset();
          this.setState({isTimedOut: true})
        }
        
      }
    
      handleClose() {
        this.setState({showModal: false})
        window.location.href = '/logout'
      }
    render() {
        return ( 
            <>
            <IdleTimer ref = { ref => { this.idleTimer = ref } }
            element = { document }
            onActive = { this.onActive }
            onIdle = { this.onIdle }
            onAction = { this.onAction }
            debounce = { 250 }
            timeout = { this.state.timeout }
            />
            <IdleModal 
            showModal={this.state.showModal} 
            handleClose={this.handleClose}
            />
            </>
        )
    }
}
export default IdleTimerLogout;
