
import React from 'react';
import { Avatar, Day, Time, utils } from 'react-gifted-chat';
import Bubble from './SlackBubble';
const { isSameUser, isSameDay } = utils;

export default class Message extends React.Component {

  getInnerComponentProps() {
    const { containerStyle, ...props } = this.props;
    return {
      ...props,
      position: 'left',
      isSameUser,
      isSameDay,
    };
  }

  renderDay() {
    if (this.props.currentMessage.createdAt) {
      const dayProps = this.getInnerComponentProps();
      if (this.props.renderDay) {
        return this.props.renderDay(dayProps);
      }
      return <Day {...dayProps} />;
    }
    return null;
  }

  renderTime() {
    if (this.props.currentMessage.createdAt) {
      const timeProps = this.getInnerComponentProps();
      if (this.props.renderTime) {
        return this.props.renderTime(timeProps);
      }
      return <Time {...timeProps} />;
    }
    return null;
  }

  renderBubble() {
    const bubbleProps = this.getInnerComponentProps();
    if (this.props.renderBubble) {
      return this.props.renderBubble(bubbleProps);
    }
    return <Bubble {...bubbleProps} />;
  }

  

  renderAvatar() {
   
    const { fid } = this.props
    // console.log(fid);
    let extraStyle;
    let avatarColor;
    const avatarProps = this.getInnerComponentProps();
    if (
      isSameUser(this.props.currentMessage, this.props.previousMessage)
      && isSameDay(this.props.currentMessage, this.props.previousMessage)
    ) {
      extraStyle = { height: 0 };
    }
    if(fid === this.props.currentMessage.user._id) {
      avatarColor = { backgroundColor : '#005284' }
    }
    
     console.log(avatarProps);
    return (
      <Avatar
        {...avatarProps}
        renderAvatarOnTop={true}
        imageStyle={{ left: [styles.slackAvatar, styles.avatarTextStyle, avatarProps.imageStyle, extraStyle, avatarColor] }}
      />
    );
  }

  render() {
    const marginBottom = isSameUser(this.props.currentMessage, this.props.nextMessage) ? -15 : 10;

    return (
      <div style={{padding:"0 10px"}}>
        {this.renderDay()}
        
        <div style={Object.assign(styles.container,marginBottom, this.props.containerStyle)}>
         {this.renderAvatar()}
         {this.renderBubble()}
         </div>
      </div>
    );
  }

}

const styles = {
  container: {
    flexDirection: 'row',
    display:'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    marginLeft: 8,
    marginRight: 0,
  },
  slackAvatar: {
    // The bottom should roughly line up with the first line of message text.
    height: 40,
    width: 40,
    borderRadius: 3,
  },
  avatarTextStyle: {
    color: 'white',
    fontSize: 16,
    // backgroundColor: Color.backgroundTransparent,
    fontWeight: '400',
  },
};

// Message.defaultProps = {
//   renderAvatar: undefined,
//   renderBubble: null,
//   renderDay: null,
//   currentMessage: {},
//   nextMessage: {},
//   previousMessage: {},
//   user: {},
//   containerStyle: {},
// };

// Message.propTypes = {
//   renderAvatar: PropTypes.func,
//   renderBubble: PropTypes.func,
//   renderDay: PropTypes.func,
//   currentMessage: PropTypes.object,
//   nextMessage: PropTypes.object,
//   previousMessage: PropTypes.object,
//   user: PropTypes.object,
// //   containerStyle: PropTypes.shape({
// //     left: ViewPropTypes.style,
// //     right: ViewPropTypes.style,
// //   }),
// };