import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import store from './redux/store';
import swConfig from './swConfig';
ReactDOM.render(
    <Provider store={store}><App /></Provider>, document.getElementById('root'));
defineCustomElements(window);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

serviceWorker.register(swConfig);


  
   
