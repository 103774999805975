import React, { Component } from 'react';
import Sidenav from './Sidenav';
import { GiftedChat } from 'react-gifted-chat';
import SlackMessage from './SlackMessage'
import API from '../config_api'
import axios from 'axios'
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import '@ionic/pwa-elements';
import { Modal } from 'react-bootstrap'
import { ReactMic } from 'react-mic';
const { Camera } = Plugins;
class Chat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            record:false,
            messageText:''

        };
        this.renderMessage = this.renderMessage.bind(this);
        this.listenForNotificationRequests = this.listenForNotificationRequests.bind(this);
        this.messagesRef = this.props.firebase.database().ref(`messageGroup/${this.props.match.params.id}/messages/${this.props.location.state.data.unique_id}`);
        this.unReadMsgRef = this.props.firebase.database().ref(`messageGroup/${this.props.match.params.id}/unReadCountData/${this.props.location.state.data.unique_id}`);
        this.unitRef = this.props.firebase.database().ref(`messageGroup/${this.props.match.params.id}/unitOrderByTime`);
        this.unreadRef = this.props.firebase.database().ref(`messageGroup/${this.props.match.params.id}/unReadMsgData/${this.props.location.state.data.unique_id}`);
        this.groupRef = this.props.firebase.database().ref(`groupOrderByTime`);
        this.groupChildRef = this.props.firebase.database().ref(`messageGroup/${this.props.match.params.id}/totalUnRead`);
    }

    componentWillMount() {
        this.setState({
            userId: this.userID(),
            username: localStorage.getItem('firstname') + ' ' + localStorage.getItem('lastname')
        })
        this.loadDataContent()
    }
    userID() {
        const userID = localStorage.getItem('token');
        const ID = userID.split('@');
        console.log(ID[0])
        return ID[0];
    }
    loadDataContent() {

        this.setState({
            isLoading: true
        })
        // this.messagesRef.limitToLast(12).on('value', (data)=>{
        this.messagesRef.on('value', (data) => {
            var items = [];

            data.forEach((child) => {
                items.push(
                    child.val()
                );
            });
            console.log(items)
            this.getContent(items)

        });

        var self = this;
        var userId = this.state.userId
        this.unreadRef.child(`${userId}`).once("value", function (snapshot) {
            var unreadChild = self.unreadRef.child(`${userId}`)
            unreadChild.set(0)
        })
        var unreadChild = this.groupChildRef.child(`${userId}`)
        unreadChild.set(0)
        // this.groupChildRef.child(`${userId}`).once("value", function(snapshot){

        // })

    }
    getContent = (datas) => {
        console.log(datas)

        return Promise.all(datas.map(data => {

            if (data.message_type === 'image') {
                let storage = this.props.firebase.storage();
                let gsReference = storage.refFromURL(data.message)
                return new Promise((resolve, reject) => {
                    gsReference.getDownloadURL()
                        .then((url) => {

                            let obj = {
                                _id: data.date,
                                text: '',
                                createdAt: new Date(data.date),
                                type: 'image',
                                user: {
                                    _id: data.sender_id,
                                    name: data.sender_name,
                                },
                                image: data.message_type === 'image' ? url : '',
                                audio: data.message_type === 'audio' ? '' : '',
                                url: url
                            }
                            resolve(obj)
                        }).catch(err => reject(err))

                })
            } else if (data.message_type === 'audio') {
                let storage = this.props.firebase.storage();
                let gsReference = storage.refFromURL(data.message)
                return new Promise((resolve, reject) => {
                    gsReference.getDownloadURL()
                        .then((url) => {

                            let obj = {
                                _id: data.date,
                                text: '',
                                createdAt: new Date(data.date),
                                type: 'audio',
                                user: {
                                    _id: data.sender_id,
                                    name: data.sender_name,
                                },
                                image: data.message_type === 'image' ? url : '',
                                audio: data.message_type === 'audio' ? '' : '',
                                url: url
                            }


                            resolve(obj)
                        }).catch(err => reject(err))

                })
            } else if (data.message_type === 'video') {
                let storage = this.props.firebase.storage();
                let gsReference = storage.refFromURL(data.message)
                return new Promise((resolve, reject) => {
                    gsReference.getDownloadURL()
                        .then((url) => {
                            let obj = {
                                _id: data.date,
                                text: '',
                                createdAt: new Date(data.date),
                                type: 'video',
                                user: {
                                    _id: data.sender_id,
                                    name: data.sender_name,
                                },
                                image: data.message_type === 'image' ? url : '',
                                audio: data.message_type === 'audio' ? '' : '',
                                url: url
                            }
                            resolve(obj)
                        }).catch(err => reject(err))

                })
            } else if (data.message_type === 'text') {

                return new Promise((resolve, reject) => {

                    let obj = {
                        _id: data.date,
                        text: data.message,
                        createdAt: new Date(data.date),
                        type: 'text',
                        user: {
                            _id: data.sender_id,
                            name: data.sender_name,
                        },
                        image: '',
                        audio: '',
                    }

                    // setTimeout(() => {
                    resolve(obj)
                    // },100)

                })
            }

        })).then(data => {
            this.setState({
                isLoading: false
            })

            let vl = data.reverse()
            // let vl = data
            setTimeout(() => {
                this.setState({
                    messages: [...vl]
                })
                this.setState(this.state)
            }, 100)




            // console.log(data, " ", this.state.isLoading)
        }).catch(err => {
            console.log("Error", err)
            this.setState({
                isLoading: false
            })
            this.forceUpdate()
        })


    }


    firebaseFunc() {

        var childRef = this.unitRef.child(`${this.props.location.state.data.unique_id}`)
        childRef.set(this.props.firebase.database.ServerValue.TIMESTAMP);
        // this.unitRef.set({ unique_id : firebase.database.ServerValue.TIMESTAMP });
        var groupChild = this.groupRef.child(`${this.props.match.params.id}`)
        groupChild.set(this.props.firebase.database.ServerValue.TIMESTAMP);

        var members = this.props.location.state.value.members_list.split(',');
        // console.log(members)
        var self = this;
        for (let i of members) {


            this.groupChildRef.child(i).once('value', function (snapshot) {
                if (self.state.userId !== snapshot.key) {
                    if (snapshot.exists()) {
                        let unreadChild = self.groupChildRef.child(`${snapshot.key}`)
                        let unread = snapshot.val() + 1
                        unreadChild.set(unread)
                    } else {
                        // console.log("else")
                        let unreadChild = self.groupChildRef.child(`${snapshot.key}`)
                        let unread = 1
                        unreadChild.set(unread)
                    }
                }
            })

        }
    }

    onSubmitMessage(){
        // console.log('enter text')
        this.sendMessage([])

       
     }
    sendMessage(messages) {
        // console.log("sendMessage", message)

        //   for (var i = 0; i < message.length; i++) { //firebase.database.ServerValue.TIMESTAMP
        if (this.state.messageText) {
            let obj = {
                message: this.state.messageText,
                date: this.props.firebase.database.ServerValue.TIMESTAMP,
                message_type: 'text',
                sender_id: this.state.userId,
                sender_name: this.state.username,
                status: 'sent'
            }
            console.log(obj)
            this.messagesRef
                .push(obj);

                this.setState({messageText:''}) 
            this.firebaseFunc()

        }
        // console.error(obj)
        this.listenForNotificationRequests(this.props);
    }
    listenForNotificationRequests(props){
        this.messagesRef.endAt().limitToLast(1).on('child_added', function(snapshot) {
          // all records after the last continue to invoke this function
          // console.log(snapshot.val());
          var request = snapshot.val();
          let message =''
          if(request.message_type === 'image'){
            message = 'Received a new image.'
          }else if(request.message_type === 'audio'){
            message = 'Received a new audio message.'
          }else {
            message = request.message
          }
          const userID = localStorage.getItem('token');
          const ID = userID.split('@');
          
            let formData = new FormData();
            formData.append('group_id', props.location.state.value.group_id)
            formData.append('user_id',ID[0])
            formData.append('message', message)
            formData.append('message_type', request.message_type)
            formData.append('token', localStorage.getItem('token'))
            formData.append('unit_id', props.location.state.data.topic_id)
            axios({
                method: 'post',
                url: API.Group_Notification,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
                .then(res => {
                    console.log(res.data.data)
    
                   
    
                });
         
       });
       
      };
    
    dataURItoFile = (dataURI) => {
        var byteString = atob(dataURI.split(',')[1]);
        // separate out the mime component
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        // write the bytes of the string to an ArrayBuffer
        var ab = new ArrayBuffer(byteString.length);
        var dw = new DataView(ab);
        for (var i = 0; i < byteString.length; i++) {
            dw.setUint8(i, byteString.charCodeAt(i));
        }

        // write the ArrayBuffer to a blob, and you're done
        return new File([ab], { type: mimeString });
    }
    async takePicture() {
        const image = await Camera.getPhoto({
            resultType: CameraResultType.DataUrl,
            quality: 90,
            allowEditing: true,
            source: CameraSource.Camera
        });
        let value = this.dataURItoFile(image.dataUrl, '');
        let data = { uri: value, fileName: '', type: 'image' }
        this.uploadImage(data);


    }
    messageIdGenerator() {
        // generates uuid.
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, c => {
            let r = (Math.random() * 16) | 0,
                v = c === "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }

    uploadImage({ uri, fileName, type }, mime = 'application/octet-stream') {
        let finalFileName;
        console.log(type)
        if (type === 'image') {
            console.log(type)
            finalFileName = `${this.messageIdGenerator()}.jpg`;
        } else {
            finalFileName = fileName
        }

        console.log(finalFileName)
        return new Promise((resolve, reject) => {
            const imagePath = uri
            var sessionId = new Date().getTime()

            const imageRef = this.props.firebase
                .storage()
                .ref('AcuizenImage')
                .child(finalFileName);
            // let mime = 'image/jpg';

            imageRef
                .put(imagePath, { contentType: mime })
                .then(() => {
                    return imageRef.getDownloadURL();
                })
                .then((url) => {
                    resolve(url)
                    this.storageReferance(url, sessionId, type)
                })
                .catch(reject);
        });
    }
    storageReferance = (url, sessionId, type) => {

        // console.log(url, type)


        var obj = {
            message: url,
            date: this.props.firebase.database.ServerValue.TIMESTAMP,
            message_type: type,
            sender_id: this.state.userId,
            sender_name: this.state.username,
            status: 'sent'
        }
        // console.error(obj)
        this.messagesRef
            .push(obj);

        this.firebaseFunc()
        this.listenForNotificationRequests(this.props);

    }
     handleChange = e => {
        if (e.target.files.length) {
          let value=e.target.files[0]
          let data = { uri: value, fileName: '', type: 'image' }
          this.uploadImage(data);
        }
      };
    renderMessage(props) {
        console.log(props)
        const { currentMessage: { text: currText } } = props;
        // const { params } = this.props.navigation.state;
        // console.log("CurrentText", currText);
        let messageTextStyle;

        //Make "pure emoji" messages much bigger than plain text.
        if (currText) {//} && emojiUtils.isPureEmojiString(currText)) {
            messageTextStyle = {
                fontSize: 14,
                marginBottom: 0,
                color: this.props.location.state.value.facilitator_id === props.currentMessage.user._id ? '#005284' : '#000'
            };
        }
        // console.log(params.facilitator_id)
        return (
            <SlackMessage {...props} fid={this.props.location.state.value.facilitator_id} messageTextStyle={messageTextStyle} />
        );
    }
    customInputToolbar() {
        return (
            <div className="flex justify-content-md-around bg-white align-item-center" style={{ height: 40, border: '1px solid rgb(184 184 184)' }}>
                <span className="material-icons" onClick={() => this.takePicture()}>
                    camera_alt
</span>
                <div><label htmlFor="upload-button"><span className="material-icons">
                    insert_photo
</span> </label>
                    <input
                        type="file"
                        id="upload-button"
                        style={{ display: "none" }}
                        accept='image/*'
                        onChange={(e)=>this.handleChange(e)}
                    /></div>

                <span className="material-icons" onClick={() => this.takeAudio()}>
                    mic
</span>
            </div>
        )
    }
    takeAudio() {
        this.setState({
            audioVisible: true
        })
    }
    closeAudioModal() {
    this.setState({
            audioVisible: false
        })

    }
    stopRecording = () => {
        this.setState({
            record: false
        });
    }
    startRecording = () => {
        this.setState({
            record: true
        });
    }
    onData(recordedBlob) {
        console.log('chunk of real-time data is: ', recordedBlob);
    }
    onStop = recordedBlob => {
        console.log(recordedBlob)
        if (!this.state.record) {
            this.setState({
                audioVisible: false,
            })
            var value = new File([recordedBlob.blob], { type: 'audio/mp3', lastModified: Date.now() });
            const fileName = `${this.messageIdGenerator()}.aac`;
            let data = { uri: value, fileName: fileName, type: 'audio' }
            this.uploadImage(data);
    
        }

    }
    saveAudio() {
        if (!this.state.record) {
            this.setState({
                audioVisible: false,
            })
        }
    }
    renderChat() {
        return (
            <GiftedChat
                messages={this.state.messages}
                alwaysShowSend={true}
              
                ScrollToTop={true}
                renderAccessory={() => this.customInputToolbar()}
                user={{
                    _id: this.state.userId,
                    name: this.state.username
                }}
                onSend={messages => this.sendMessage(messages)}
                renderMessage={this.renderMessage}
                 
                // multiline={false}
                // textInputProps={{onSubmitEditing: this.onSubmitMessage}}

                // onInputTextChanged={text => this.setState({messageText: text})}
                // text={this.state.messageText}
            />
        );
    }

    render() {
        console.log(this.props)
        return (
            <div className="">
                <Sidenav type={'second'} Push={this.props.history} url={'back'} />

                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center" style={{ padding: 15 }}>
                            <h5 className="size-text" style={{color:'white'}}>{this.props.location.state.data.name}</h5>
                        </div>
                    </div>
                    <div style={styles.container}>


                        <div style={styles.chat}>

                            {this.renderChat()}
                        </div>

                    </div>
                </div>
                <Modal
                    size="sm"
                    show={this.state.audioVisible}
                    onHide={() => this.closeAudioModal()}
                    backdrop='static'
                    centered
                    animation
                    aria-labelledby="example-modal-sizes-title-sm"
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            Audio Recording
                  </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="flex flex-column align-item-center justify-content-between">
                            <ReactMic
                                record={this.state.record}
                                className="sound-wave"
                                onStop={this.onStop}
                                onData={this.onData}
                                strokeColor="#000000"
                                backgroundColor="#adb5bd"
                                mimeType="audio/mp3" />
                            <div className="">

                                {this.state.record ?
                                    <img onClick={() => this.stopRecording()} style={{ width: 60 }} src={require('../images/stop.png')} alt="stop" className="img-responsive" />
                                    :
                                    <img onClick={() => this.startRecording()} style={{ width: 60 }} src={require('../images/recording.png')} alt="start" className="img-responsive" />
                                }

                            </div> </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="flex justify-content-between">

                            <span onClick={() => this.closeAudioModal()}>Cancel
    </span>
                            {this.state.record ? '' : <span onClick={() => this.saveAudio()}>Done
    </span>}


                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}
const styles = {
    container: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        height: "74vh",
        background: '#f5f5f5'
    },
    channelList: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
    chat: {
        display: "flex",
        flex: 3,
        flexDirection: "column",
        borderWidth: "1px",
        borderColor: "#ccc",
        borderRightStyle: "solid",
        borderLeftStyle: "solid",
    },
    settings: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
    },
};
export default Chat
