import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export const IdleModal = ({showModal, handleClose, remainingTime}) => {

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Header closeButton>
            <Modal.Title>You Have Been Idle!</Modal.Title>
            </Modal.Header>
            <Modal.Body>Session has timed out after 15 minutes of inactivity. </Modal.Body>
            <Modal.Footer>

            <Button variant="primary" onClick={handleClose}>
               Login Again
            </Button>
            </Modal.Footer>
        </Modal>
    )
}