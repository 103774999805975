import React, { Component } from 'react'
import QrReader from 'react-qr-reader'
import { fetchData } from '../redux/assignedData/AssignAction'
import { connect } from 'react-redux'
import Sidenav from './Sidenav'
class QRScanner extends Component {
    constructor(props) {
        super(props);
        this.handleScan = this.handleScan.bind(this);
    }
    componentWillMount() {
        this.props.getAreaData();
    }
    handleScan = (data, props) => {
        const value = this.props.data;
        if (data) {
            const split = data.split(',')
            if (split[0] === 'area') {
                value.forEach(function (area, ind) {
                    if (area.id === split[1]) {
                        props.history.push('/unit', { data: area })
                    }
                })
            } else if (split[0] === 'topic') {
                value.forEach(function (area, i1) {
                    area.models.forEach(function (model, i2) {
                        if (model.id === split[1]) {
                            model.module_ui_status = true
                            props.history.push('/unit', { data: area })

                        }
                    })
                })
            } else if (split[0] === 'unit') {
                value.forEach(function (area, i1) {
                    area.models.forEach(function (model, i2) {
                        model.programs.forEach(function (unit, i) {
                            if (unit.program_id === split[1]) {
                                props.history.push('/overview', { data: unit })
                            }
                        })
                    })
                })
            } else {
                alert('Invalid QR Code.')
            }
        }
    }
    handleError = err => {
        console.error(err)
    }
    render() {
        console.log(this.props.data)
        return (
            <div className="" >
                <Sidenav type={'second'} url={'/dashboard'} />
                <div className="container h-h" >
                    <div className="row" >
                        <div className="col-12 mt-3 mb-4">
                            <p>Scan AcuiZen QR code to access the content</p>
                        </div>
                        <QrReader
                            delay={300}
                            onError={this.handleError}
                            onScan={(data) => this.handleScan(data, this.props)}
                            facingMode='environment'
                            style={{ width: '100%' }}
                        />

                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        data: state.assign.data,
        loader: state.assign.loading,
        error: state.assign.error
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getAreaData: () => dispatch(fetchData())
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(QRScanner); 