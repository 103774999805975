import React, { useState, useEffect } from 'react'
//import Jitsi from 'react-jitsi'
import Loader from '../component/Loader'
import API from '../config_api'
import axios from 'axios'
import '../css/App.css';
//import Sidenav from './Sidenav';
const VideoExample = (props) => {
    const jitsiContainerId = "jitsi-container-id";
    const [jitsi, setJitsi] = useState({});

    const [onCall, setOnCall] = useState(true)
    useEffect(() => {
        if (!localStorage.getItem('token')) {
            window.location.href = "https://app.acuizen.com";
        }
        let formData = new FormData();
        formData.append('room_id', props.match.params.id);
        formData.append('user_id', userID())
        axios({
            method: 'post',
            url: API.CheckVideoConference,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res.data)
                if (res.data.status === 'success') {
                    localStorage.setItem('roomName', res.data.data.data.room)
                    initialiseJitsi()

                }
                else {
                    setOnCall(false)
                    alert('Not Allowed');
                    props.history.push('/dashboard');
                }


            });
        return () => jitsi?.dispose?.();
    }, [props])

    const loadJitsiScript = () => {
        let resolveLoadJitsiScriptPromise = null;

        const loadJitsiScriptPromise = new Promise(resolve => {
            resolveLoadJitsiScriptPromise = resolve;
        });

        const script = document.createElement("script");
        script.src = "https://meet.acuizen.com/external_api.js";
        script.async = true;
        script.onload = () => resolveLoadJitsiScriptPromise(true);
        document.body.appendChild(script);

        return loadJitsiScriptPromise;
    };
    const initialiseJitsi = async () => {
        if (!window.JitsiMeetExternalAPI) {
            await loadJitsiScript();
        }

        const _jitsi = new window.JitsiMeetExternalAPI("meet.acuizen.com", {
            parentNode: document.getElementById(jitsiContainerId),
            roomName: localStorage.getItem('roomName'),
            //jwt:token,
            config: {
                disableDeepLinking: true,
            },
            configOverwrite: {
                enableClosePage: false,
                disableDeepLinking: true,
            },
            interfaceConfigOverwrite: {
                HIDE_INVITE_MORE_HEADER: true,
                SHOW_PROMOTIONAL_CLOSE_PAGE: false,
                TOOLBAR_BUTTONS: [
                    'microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen',
                    'fodeviceselection', 'hangup', 'profile', 'chat', 'recording',
                    'livestreaming', 'etherpad', 'sharedvideo', 'settings', 'raisehand',
                    'videoquality', 'filmstrip', 'feedback', 'stats', 'shortcuts',
                    'tileview', 'videobackgroundblur', 'download', 'help', 'mute-everyone'
                ],
            }

        });
        _jitsi.executeCommand('displayName', localStorage.getItem('firstname'));
        // _jitsi.addEventListener('participantRoleChanged', function(event) {
        //     if (event.role === "moderator") {
        //         _jitsi.executeCommand('password', 'The Password');
        //     }
        // });
        // join a protected channel
        // _jitsi.on('passwordRequired', function ()
        // {
        //     _jitsi.executeCommand('password', 'The Password');
        // });


        _jitsi.addEventListener('videoConferenceLeft', () => {
            _jitsi.dispose();
            window.opener = null;
            window.open('', '_self');
            window.close();
            // props.history.push('/dashboard')
        })
        setOnCall(false)
        setJitsi(_jitsi);
    };

    const userID = () => {
        const userID = localStorage.getItem('token');
        const ID = userID.split('@');
        console.log(ID[0])
        return ID[0];
    };
    return (

        <div>
            {onCall && <Loader />}

            <div className='main'>
                <div id={jitsiContainerId} style={{ height: '100vh', width: "100%" }} />

            </div>



        </div>

    )
};

export default VideoExample
