import React, { Component } from 'react';
import axios from 'axios';
import API from '../config_api';
import '../css/App.css';
// import '../css/Cardstyle.css';
import Card from '../component/Card';
import '../css/App.css';

import Sidenav from './Sidenav';
import IdleTimerLogout from './IdleTimerLogout';


class Dummy extends Component {
    constructor(props) {
      super(props);
      
    }
  
    render() {
        return ( 
        <div className = "" >
             <IdleTimerLogout/>
             <Sidenav type={'second'} url={'/dashboard'}/>
            <div className="container">
          
            <div className="cards">
      <div className="card card-one">
        <h2 className="card-title">Card One</h2>
        <p className="date">May 25, 2021</p>
        <p className="description">Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolores alias ut veniam?</p>
        <div><span img={require('../images/resume2.png')}/> </div>
        <Card type={"3"} Title={"Resume"} img={require('../images/resume2.png')} Count={this.state.data.paused} Push={this.props.history} Url={"/resume"} />
      </div>

     
     
    </div>
            </div>
            
            </div>
        );
    }
}

export default Dummy;