import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux'
import { fetchData } from '../redux/notificationData/NotificationAction'
import Loader from '../component/Loader';
import API from '../config_api'
import '../css/App.css';
import Sidenav from './Sidenav';
import BigPicture from "react-bigpicture";
import IdleTimerLogout from './IdleTimerLogout';

class Notification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            start: 0,
            end: 10,
            requiredCheck: false,
            lat: '',
            lon: '',
            loader: false

        };

    }
    getPosision = (position) => {
        this.setState({
            lat: position.coords.latitude,
            lon: position.coords.longitude
        })
        // console.log("Latitude is :", position.coords.latitude);
        // console.log("Longitude is :", position.coords.longitude);  
    }
    componentWillMount() {

        if (!localStorage.getItem('token')) {
            this.props.history.push('/');
        }

        this.props.getNotificationData(this.state.start, this.state.end);
        navigator.geolocation.getCurrentPosition(this.getPosision)
        // axios({
        //     method: 'post',
        //     url: API.Notification,
        //     data: formData,
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        //     },
        //     mode: 'no-cors'
        // })
        //     .then(res => {
        //         if (res.data.status === 'success') {
        //             this.setState({
        //                 data: res.data.data
        //             })
        //         }
        //         else {
        //             alert(res.data.message)
        //         }
        //     });

    }
    delete(NotificationData, id) {
        let formData = new FormData();
        formData.append('user_id', localStorage.getItem('token'))
        formData.append('msg_id', NotificationData.msg_id)
        formData.append('type', "2")
        axios({
            method: 'post',
            url: API.NotificationUpdate,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                if (res.data.status === 'success') {
                    console.log(res)

                }
                else {
                    alert(res.data.message)

                }
            });
        this.deleteitem(id)
    }

    deleteitem(id) {
        console.log(id)
        const { data } = this.props
        data.splice(id, 1);
        this.forceUpdate()

    }
    loadmore() {
        this.setState({
            start: this.state.start + 10,
            end: this.state.end + 10
        })

        this.props.getNotificationData(this.state.start, this.state.end);
        // let formData = new FormData();
        // formData.append('user_id', localStorage.getItem('token'))
        // formData.append('limit[start]', this.state.start)
        // formData.append('limit[end]', this.state.end)
        // axios({
        //     method: 'post',
        //     url: API.Notification,
        //     data: formData,
        //     headers: {
        //         'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        //     },
        //     mode: 'no-cors',
        // })
        //     .then(res => {
        //         console.log(res)
        //         if (res.data.status === 'success') {
        //             this.setState({
        //                 data: res.data.data
        //             })
        //         }
        //         else {
        //             alert(res.data.message)
        //         }
        //     });
    }
    readStatus(Notification, i) {

        if (Notification.read_status === '0') {
            Notification.read_status = '1'
            this.forceUpdate()
            let formData = new FormData();
            formData.append('user_id', localStorage.getItem('token'))
            formData.append('msg_id', Notification.msg_id)
            formData.append('type', '1');
            axios({
                method: 'post',
                url: API.NotificationUpdate,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
                .then(res => {
                    if (res.data.status === 'success') {
                        console.log(res)

                    }
                    else {
                        alert(res.data.message)
                    }
                });
        }
        else {
            this.forceUpdate()
        }
    }
    videoCall(room) {
        // this.props.history.push('/video/'+room);
        window.open('/video/' + room, '_blank')
    }
    checkbox_icon(is_multiselect, checked) {

        if (is_multiselect === 1) {
            if (checked === 0) {
                return (
                    <img src={require('../images/unchecked_48dp.png')} alt="uncheck"

                        style={{ paddingRight: 10, height: 20 }} />
                )
            } else {
                return (<img src={require('../images/checked_48dp.png')} alt="checked"
                    style={{ paddingRight: 10, height: 20 }} />
                )
            }
        } else {
            if (checked === 0) {
                return (<img src={require('../images/radio_unchecked_48dp.png')} alt="uncheck"
                    style={{ paddingRight: 10, height: 20 }} />
                )
            } else {
                return (<img src={require('../images/radio_checked_48dp.png')} alt="uncheck"
                    style={{ paddingRight: 10, height: 20 }} />
                )
            }
        }


    }
    checkBoxAction(data, item, index) {

        if (item.multi === 1) {
            if (data.checked === 1) {
                data.checked = 0
                this.forceUpdate()
            } else {
                data.checked = 1
                this.forceUpdate()

            }

        } else {


            item.option.forEach((data, ind) => {

                if (ind === index) {
                    data.checked = 1
                    this.forceUpdate()
                } else {
                    data.checked = 0
                    this.forceUpdate()

                }

            })
        }
        console.log(item);
    }
    checkBoxAction1(data, item, index) {

        if (item.response.multi === 1) {
            if (data.checked === 1) {
                data.checked = 0
                this.forceUpdate()
            } else {
                data.checked = 1
                this.forceUpdate()

            }

        } else {


            item.response.option.forEach((data, ind) => {

                if (ind === index) {
                    data.checked = 1
                    this.forceUpdate()
                } else {
                    data.checked = 0
                    this.forceUpdate()

                }

            })
        }
        console.log(item);
    }
    submitAction(item) {
        this.setState({
            loader: true
        })
        let validation = true;
        let formData = new FormData();
        formData.append('user_id', localStorage.getItem('token'))
        formData.append('msg_id', item.msg_id)

        if (item.read_status === '2') {

            formData.append('json', JSON.stringify(item.response));
            // item.response.question.map((question) =>{
            //     if (question.option.some((data) => { return data.checked === 1 })) {
            //        validation=true;
            //     }
            //     })
        } else {
            formData.append('json', JSON.stringify(item.json));
            // item.json.question.map((question) =>{
            // if (question.option.some((data) => { return data.checked === 1 })) {
            //    validation=true;
            // }
            // })

        }
        if (item.json.location === 1) {
            formData.append('lat', this.state.lat);
            formData.append('long', this.state.lon);
        }
        if (validation) {
            axios({
                method: 'post',
                url: API.NotificationPollUpdate,
                data: formData,
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
                },
                mode: 'no-cors',
            })
                .then(res => {
                    this.setState({
                        loader: false
                    })
                    if (res.data.status === 'success') {
                        alert('Answered...')
                    }
                    else {
                        alert(res.data.message)
                    }
                });
        }
        else {
            alert('Please Select Answer...')
        }
    }
    pollJson(item) {
        console.log(item)
        //let json= JSON.parse(item.poll_json);
        if (item.read_status === '2') {

            return (
                <div>
                    {item.response.question.map((question, i) => {
                        return (
                            <div>
                                <p className="mZero" dangerouslySetInnerHTML={{ __html: "Quesion : " + (i + 1) + question.question }} />
                                {question.option.map((data, i) => {

                                    return (

                                        <div className="flex" onClick={() => this.checkBoxAction1(data, question, i)}>
                                            <div >
                                                {this.checkbox_icon(question.multi, data.checked)}
                                            </div>
                                            <div >
                                                {data.value}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )

                    })}

                    <div className="flex" style={{ margin: '20px 0' }}>
                        <span className="submit"
                            onClick={() => this.submitAction(item)} style={{ paddingRight: '10px' }}>
                            submit
                        </span>

                    </div>
                </div>
            )

        } else {
            return (
                <div>
                    {item.json.question.map((question, i) => {
                        return (
                            <div>
                                <p className="mZero" dangerouslySetInnerHTML={{ __html: question.question }} />
                                {question.option.map((data, i) => {

                                    return (

                                        <div className="flex" onClick={() => this.checkBoxAction(data, question, i)}>
                                            <div >
                                                {this.checkbox_icon(question.multi, data.checked)}
                                            </div>
                                            <div >
                                                {data.value}
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        )

                    })}

                    <div className="flex" style={{ margin: '20px 0' }}>
                        <span className="submit"
                            onClick={() => this.submitAction(item)} style={{ paddingRight: '10px' }}>
                            submit
                        </span>

                    </div>
                </div>
            )
        }
    }
    attachment(json) {
        let item = json.attachment;
        if (item === undefined) {
            return (
                <></>
            )
        }
        if (item.name === 'youtube') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="youtube"
                                src={"https://www.youtube.com/watch/v=" + item.value}
                            >
                                <div className="flex" style={{ position: 'relative' }}>
                                    <img src={"https://img.youtube.com/vi/" + item.value + "/0.jpg"} className="img-responsive" style={{ width: '100%' }} alt="test" />
                                    <div className="overlay"> <img src={require('../images/youtube.png')} style={{ width: '70px' }} alt="test" /></div>

                                </div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            );
        }

        else if (item.name === 'image') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content text-center">
                            <BigPicture
                                type="image"
                                src={item.value}
                            >
                                <div className="flex justify-content-center"><img src={item.value} alt="step" className="img-responsive" /></div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'video') {

            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">

                            {/* <FancyVideo
                        source={item.name}
                        poster={require('../images/white.jpg')}
                        id={Math.random().toString(36).substring(7)} /> 
                     <video src={item.name} controls style={{ width: '100%' }}>
                        Your browser does not support the video tag.
                        </video> */}
                            <BigPicture
                                type="video"
                                src={item.value}
                            >
                                <div className="flex" style={{ position: 'relative' }}>
                                    {item.preview ? <img src={item.preview} className="img-responsive" style={{ width: '100%' }} alt="test" /> : <img src={require('../images/black.jpg')} className="img-responsive" style={{ width: '100%', height: 200 }} alt="test" />
                                    }
                                    <div className="overlay"> <img src={require('../images/cinema.png')} style={{ width: '70px' }} alt="test" /></div>


                                </div>
                            </BigPicture>

                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'embed') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <div className="embed" dangerouslySetInnerHTML={{ __html: item.value }} />
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'weblink') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content text-center">
                            <a href={item.value} target="_blank" rel="noopener noreferrer" style={{
                                padding: '8px 20px', display: 'inline-block', color: '#fff',
                                background: '#920067',
                                borderRadius: 3, fontSize: 15, margin: 10
                            }}>Click Here</a>
                            <p>This will Open on a separate tab</p>
                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'pdfs') {


            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="iframe"
                                src={item.value}
                            >
                                <div className="flex justify-content-center" style={{ position: 'relative' }}>
                                    {item.preview ? <img src={item.preview} className="img-responsive" style={{ width: 320, height: 400 }} alt="test" /> : <img src={require('../images/black.jpg')} className="img-responsive" style={{ width: '100%', height: 200 }} alt="test" />
                                    }
                                    <div className="overlay"> <img src={require('../images/pdf.png')} style={{ width: '70px' }} alt="test" /></div>


                                </div>
                            </BigPicture>
                            {/* <iframe src={item.name} width='100%' height="400px" title="red" allowFullScreen/> */}


                        </div>

                    </div>
                </div>

            )
        }
        else if (item.name === 'audio') {
            return (
                <div className="card horizontal">

                    <div className="card-stacked">
                        <div className="card-content">
                            <BigPicture
                                type="audio"
                                src={item.value}
                            >
                                <div className="flex justify-content-center">
                                    <img src={require('../images/1200px-Speaker_Icon.png')} style={{ width: '70px' }} alt="test" />
                                </div>
                            </BigPicture>
                            {/* <audio controls>
                        <source src={item.name}></source>
                    </audio> */}
                        </div>

                    </div>
                </div>

            )
        }
    }
    onExpand(Notification, i) {
        Notification.expanded = Notification.expanded === "1" ? "0" : "1"
        this.forceUpdate()
    }
    render() {

        return (
            <div className="">

                <Sidenav type={'second'} url={'/dashboard'} />

                <div className="container">
                    <div style={{ position: 'fixed', left: 0, right: 0, zIndex: 1, background: '##003153' }}>
                        <div className="container">
                            <div className="row">

                                <div className="col-md-12 text-center" style={{ padding: 15 }}>
                                    <h5 className="size-text" style={{ color: 'white' }}>Notification</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 60 }}></div>
                    {this.props.data !== undefined ?
                        <div className="row">
                            <div className="col-sm-12 pad_z">
                                {this.props.data.length === 0 ? <p className="text-center">No Data Found </p> :
                                    this.props.data.map((item, i) => {
                                        return item.types === '0' ?
                                            // return (
                                            (
                                                <div className={item.read_status === '0' ? "card unread" : "card read"} style={item.expanded === "1" ? { height: 70, overflow: "hidden", padding: 10, margin: 10 } : { height: "auto", overFlow: 'auto', padding: 10, margin: 10 }} key={i} onClick={() => this.readStatus(item, i)}>
                                                    <div className="row" style={{ margin: 0 }}>
                                                        <div className="col-sm-11 col-xs-11 padZero msgnoti">
                                                            {item.expanded === "1" ?
                                                                <h4 className="title" style={{color:'darkturquoise'}}>{item.read_status === '0' ? <i className="material-icons">
                                                                    notifications
                                                                </i> : ""}{item.title}</h4> : <> <h4>{item.title}</h4>
                                                                    <p className="mZero" dangerouslySetInnerHTML={{ __html: item.message }} />
                                                                    {/* <p className="mZero">{item.message}</p> */}
                                                                    {this.attachment(item.json)}
                                                                    {item.json.description && <p>{item.json.description}</p>}
                                                                    <p className="m-0" style={{ color: 'orange', fontSize: 12, fontWeight: 500 }}>{item.daysLeft !== 0 ? item.daysLeft + ' days ' : item.hoursLeft !== 0 ? item.hoursLeft + ' hours ' : item.minutesLeft !== 0 ? item.minutesLeft + ' minutes ' : item.daysLeft !== '0' && item.hoursLeft !== '0' && item.minutesLeft !== '0' ? item.daysLeft + ' days ' : '0 minutes'}</p>
                                                                </>}
                                                        </div>

                                                        {/* <div className="col-sm-1 col-xs-1 pad_z flex align-item-center justify-content-center">
                                        {item.video_chat_rooms && 
                                        <span className="material-icons" style={{fontSize:50}} onClick={() => this.videoCall(item.video_chat_rooms)}>
                                videocam
</span>
                                        }</div> */}
                                                        <div className="col-sm-1 col-xs-1 pad_z text-right">
                                                            <span className="material-icons" style={{ color: 'red ' }} onClick={() => this.delete(item, i)}>
                                                                delete_forever
                                                            </span></div>
                                                    </div>
                                                    <div className='row justity-center ex_border' onClick={() => this.onExpand(item, i)}>
                                                        {item.expanded === "1" ? <span className="material-icons">expand_more</span> : <span className="material-icons">expand_less</span>}
                                                    </div>

                                                </div>

                                            ) : item.types === '1' ? (
                                                <div className={item.read_status === '0' ? "card unread" : "card read"} style={item.expanded === "1" ? { height: 70, overflow: "hidden", padding: 10, margin: 10 } : { height: "auto", overFlow: 'auto', padding: 10, margin: 10 }} key={i} onClick={() => this.readStatus(item, i)}>
                                                    <div className="row" style={{ margin: 0 }}>
                                                        <div className="col-sm-11 col-xs-11 padZero">
                                                            {item.expanded === "1" ?
                                                                <h4 className="title" >{item.read_status === '0' ? <i className="material-icons">
                                                                    notifications
                                                                </i> : ""}{item.title}</h4> : <> <h4>{item.title}</h4>
                                                                    {this.attachment(item.json)}
                                                                    {item.json.description && <p>{item.json.description}</p>}
                                                                    {this.pollJson(item)}

                                                                    <p className="m-0" style={{ color: 'orange', fontSize: 12, fontWeight: 500 }}>{item.daysLeft !== 0 ? item.daysLeft + ' days ' : item.hoursLeft !== 0 ? item.hoursLeft + ' hours ' : item.minutesLeft !== 0 ? item.minutesLeft + ' minutes ' : item.daysLeft !== '0' && item.hoursLeft !== '0' && item.minutesLeft !== '0' ? item.daysLeft + ' days ' : '0 minutes'}</p>
                                                                </>}
                                                        </div>
                                                        {/* <div className="col-sm-2 col-xs-2 pad_z flex align-item-center justify-content-center">
                                            {item.video_chat_rooms && 
                                            <span className="material-icons" style={{fontSize:50}} onClick={() => this.videoCall(item.video_chat_rooms)}>
                                    videocam
    </span>
                                            }</div> */}
                                                        <div className="col-sm-1 col-xs-1 pad_z text-right"><span className="material-icons" style={{ color: 'red ' }} onClick={() => this.delete(item, i)}>
                                                            delete_forever
                                                        </span></div>
                                                    </div>
                                                    <div className='row justity-center ex_border' onClick={() => this.onExpand(item, i)}>
                                                        {item.expanded === "1" ? <span className="material-icons">expand_more</span> : <span className="material-icons">expand_less</span>}
                                                    </div>
                                                </div>

                                            ) : (
                                                <div className={item.read_status === '0' ? "card unread" : "card read"} style={item.expanded === "1" ? { height: 70, overflow: "hidden", padding: 10, margin: 10 } : { height: "auto", overFlow: 'auto', padding: 10, margin: 10 }} key={i} onClick={() => this.readStatus(item, i)}>
                                                    <div className="row" style={{ margin: 0 }}>
                                                        <div className="col-sm-11 col-xs-11 padZero">
                                                            {item.expanded === "1" ?
                                                                <h4 className="title" >{item.read_status === '0' ?
                                                                    <i className="material-icons">
                                                                        notifications
                                                                    </i> : ""}{item.title}
                                                                </h4> : <><h4>{item.title}</h4>
                                                                    {this.attachment(item.json)}
                                                                    <p>{item.json.description}</p>
                                                                    {this.pollJson(item)}

                                                                    <p className="m-0" style={{ color: 'orange', fontSize: 12, fontWeight: 500 }}>{item.daysLeft !== 0 ? item.daysLeft + ' days ' : item.hoursLeft !== 0 ? item.hoursLeft + ' hours ' : item.minutesLeft !== 0 ? item.minutesLeft + ' minutes ' : item.daysLeft !== '0' && item.hoursLeft !== '0' && item.minutesLeft !== '0' ? item.daysLeft + ' days ' : '0 minutes'}</p>
                                                                </>}
                                                        </div>
                                                        {/* <div className="col-sm-2 col-xs-2 pad_z flex align-item-center justify-content-center">
                                            {item.video_chat_rooms && 
                                            <span className="material-icons" style={{fontSize:50}} onClick={() => this.videoCall(item.video_chat_rooms)}>
                                    videocam
    </span>
                                            }</div> */}
                                                        <div className="col-sm-1 col-xs-1 pad_z text-right"> <span className="material-icons" style={{ color: 'red ' }} onClick={() => this.delete(item, i)}>
                                                            delete_forever
                                                        </span></div>
                                                    </div>
                                                    <div className='row justity-center ex_border' onClick={() => this.onExpand(item, i)}>
                                                        {item.expanded === "1" ? <span className="material-icons">expand_more</span> : <span className="material-icons">expand_less</span>}
                                                    </div>
                                                </div>

                                            )
                                    })
                                }

                            </div>
                        </div> : ''}
                    <div className="row">
                        <div className="col-12 text-center">
                            <p onClick={() => this.loadmore()} style={{ color: 'white' }}>Load More</p>
                        </div>
                    </div>
                    {this.props.loader && <Loader />}
                    {this.state.loader && <Loader />}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        data: state.noti.data.data,
        loader: state.noti.loading
    }
}
const mapDispatchToProps = dispatch => {
    return {
        getNotificationData: (start, end) => dispatch(fetchData(start, end))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Notification);