import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Dummy from './pages/Dummy';
import Enterprise from './pages/EnterpriseLogin';
import UserLogin from './pages/UserLogin';
import Splash from './pages/SplashScreen';
import AreaAllocation from './pages/AreaAllocation';
import UnitAssign from './pages/UnitAssign';
import FetchSteps from './pages/FetchSteps';
import Logout from './pages/Logout'
import DashBoard from './pages/DashBoard';
import MyAccount from './pages/MyAccount';
import AboutUs from './pages/AboutUs';
import Privacy from './pages/Privacy';
import ChangePassword from './pages/ChangePassword';
import ForGotPassword from './pages/ForGotPassword';
import Checklist from './pages/Checklist';
import Observation from './pages/Observation';
import Suggestion from './pages/Suggestion';
import QRScanner from './pages/QRScanner';
import Groups from './pages/Groups';
import Group from './pages/Group';
import Insight from './pages/Insight';
import OverView from './pages/Overview';
import SearchUnit from './pages/SearchUnit';
import Notification from './pages/Notification';
import VideoExample from './pages/VideoExample';
import Chat from './pages/Chat';
import Spaces from './pages/Spaces';
import {initializeFirebase,askForPermissionToReceiveNotification,data} from './pages/PushNotification'
import Test from './pages/Test';
import Resume from './pages/Resume';
import PauseSession from './pages/PauseSession';
import WaveLogin from './pages/WaveLogin';
import Action from './pages/Action';
import {Helmet} from "react-helmet";
class App extends Component {

  constructor(props) {
        super(props);
        this.state = {
            value: ''
        };
         initializeFirebase();
      
    }
    componentWillMount(){
       
        // if(!localStorage.getItem('notification-token')){
        //     askForPermissionToReceiveNotification();
        // }
       
    }
      componentDidMount() {
      window.addEventListener("beforeunload", this.unload);
      
      }
    
      componentWillUnmount() {
        window.removeEventListener("beforeunload", this.unload);
      }
    
      unload = (e) => {
          console.log(e)
          e.preventDefault();
        //   localStorage.removeItem('models');
        //   localStorage.removeItem('token');
        //   localStorage.removeItem('screen');
        //   localStorage.removeItem('email');
        //   localStorage.removeItem('firstname');
        //   localStorage.removeItem('lastname');
        //   localStorage.removeItem('emp_id');
        //   localStorage.removeItem('notification-token');
       
      }

    render() {

        return (
            <BrowserRouter>
             <Helmet>
             {window.location.href.includes('webapp.acuizen.com')?
             <title>Acuizen Webapp</title>
           :
         <title>Wavemakers</title>}
            
             </Helmet>
                <div className="App" >
                    <Switch>
                        <Route path="/" exact component={Enterprise} />
                        <Route path="/login" render={routeProps => <UserLogin firebase={data} {...routeProps} />}/>
                        <Route path="/wavelogin"render={routeProps => <WaveLogin firebase={data} {...routeProps} />}/>
                        <Route path="/splash" component={Splash} />
                        <Route path="/dummy" component={Dummy}/>
                        <Route path="/area" component={AreaAllocation} />
                        <Route path="/action" component={Action}/>
                        <Route path="/unit" component={UnitAssign} />
                        <Route path="/units/:id" component={FetchSteps} />
                        <Route path="/logout" component={Logout} />
                        <Route path="/dashboard" component={DashBoard} />
                        <Route path="/myaccount" component={MyAccount} />
                        <Route path="/about" component={AboutUs} />
                        <Route path="/privacy" component={Privacy} />
                        <Route path="/changepassword" component={ChangePassword} />
                        <Route path="/forgotpassword" component={ForGotPassword} />
                        <Route path="/checklist" component={Checklist} />
                        <Route path="/observation" component={Observation} />
                        <Route path="/suggestion" component={Suggestion} />
                        <Route path="/qrscan" component={QRScanner} />
                        <Route path="/groups" component={Groups} />
                        <Route path="/group/:id" render={routeProps => <Group firebase={data} {...routeProps} />} />
                        <Route path="/insight" component={Insight} />
                        <Route path="/overview" component={OverView} />
                        <Route path="/search" component={SearchUnit} />
                        <Route path="/video/:id"  component={VideoExample}/>
                        <Route path="/notification" component={Notification} />
                        <Route path="/resume" component={Resume} />
                        <Route path="/pause/:id" component={PauseSession} />
                        <Route path="/spaces" component={Spaces}/>
                        <Route path="/chat/:id" render={routeProps => <Chat firebase={data} {...routeProps} />} />
                        <Route path="/test" component={Test}/>
                    </Switch>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;