import React, { Component } from "react";
import "../css/App.css";

class AssignedModulesParentComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }
  componentWillMount() {}

  render() {
    return (
      <div
        className="area col-md-12"
        onClick={() => this.props.Onpress()}
        style={{ cursor: "pointer" }}
      >
        <h4
          className="text-white mZero"
          style={{
            textTransform: "capitalize",
            fontSize: "14px",
            letterSpacing: ".5px",
            fontWeight: "500",
          }}
        >
          {this.props.ParentComponentTitle}
        </h4>
        <span className="align-item-center">
          <span
            className="topic"
            style={{ fontSize: "12px", fontWeight: "400" }}
          >
            {this.props.UnitsCount} Unit(s)
          </span>{" "}
          {this.props.ui_open === true ? (
            <i className="material-icons">keyboard_arrow_down</i>
          ) : (
            <i className="material-icons">keyboard_arrow_right</i>
          )}
        </span>
      </div>
    );
  }
}

export default AssignedModulesParentComponent;
