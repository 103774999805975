import React, { Component } from "react";
import "../css/App.css";
import { fetchData } from "../redux/pauseData/pauseAction";
import { connect } from "react-redux";
import axios from "axios";
import API from "../config_api";
import FancyVideo from "react-videojs-fancybox";
import cryptoRandomString from "crypto-random-string";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SignatureCanvas from "react-signature-canvas";
import { Modal } from "react-bootstrap";
import { Plugins, CameraResultType, CameraSource } from "@capacitor/core";
import "@ionic/pwa-elements";
import Loader from "../component/Loader";
import { ReactMic } from "react-mic";
import BigPicture from "react-bigpicture";
import IdleTimerLogout from "./IdleTimerLogout";
import MdPlay from "react-ionicons/lib/MdPlay";
import RangeSlider from "react-bootstrap-range-slider";
const { Camera, Browser, Network, Geolocation } = Plugins;

class PauseSession extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      currentStep: 0,
      style: "menu",
      menuStatus: "open",
      status: false,
      isRecording: false,
      blobURL: "",
      loading: false,
      isBlocked: false,
      requiredCheck: true,
      preview: "",
      visible: false,
      audioVisible: false,
      videoVisible: false,
      index: "",
      record: false,
      recordedBlob: "",
      lat: "",
      lon: "",
      mediaDevice: [],
      videoRecord: false,
      lightboxIsOpen: false,
      seconds: 0,
      submitStatus: 3,
    };
  }

  componentDidMount() {
    setInterval(() => {
      this.setState(({ seconds }) => ({
        seconds: seconds + 1,
      }));
    }, 1000);

    // navigator.getUserMedia({ audio: true },
    //     () => {
    //         console.log('Permission Granted');
    //         this.setState({ isBlocked: false });
    //     },
    //     () => {
    //         console.log('Permission Denied');
    //         this.setState({ isBlocked: true })
    //     },
    // );
  }
  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps);
    if (this.state.currentStep !== nextProps.current_step) {
      this.setState({ currentStep: Number(nextProps.current_step) });
    }
    if (this.state.seconds !== nextProps.duration) {
      this.setState({ seconds: Number(nextProps.duration) });
    }
  }
  async componentWillMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "https://app.acuizen.com";
    }

    this.props.getPauseData(
      this.props.match.params.id,
      this.props.location.state.data.program_id
    );
    // Network.addListener('networkStatusChange', (status) => {
    //     console.log("Network status changed", status);
    //   });
    let status = await Network.getStatus();
    console.log(status);
    // const coordinates = await Geolocation.getCurrentPosition();
    // console.log('Current', coordinates);
    // this.setState({
    //     lon: coordinates.coords.longitude,
    //     lat: coordinates.coords.latitude,

    // })
  }

  async takePicture(item, index) {
    const image = await Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      quality: 90,
      allowEditing: true,
      source: CameraSource.Camera,
    });
    console.log(image);
    this.props.data[this.state.currentStep].microstep_data[index].value =
      image.dataUrl;
    this.props.data[this.state.currentStep].microstep_data[index].edit = true;
    this.forceUpdate();
  }
  removePicture(item, index) {
    this.props.data[this.state.currentStep].microstep_data[index].value = "";
    this.forceUpdate();
  }
  async launchScrom(url) {
    await Browser.open({ url: url });
  }
  async takeVideo(item, index) {
    this.setState({
      videoVisible: true,
      index: index,
    });
    this.gotDevices();
  }
  gotDevices = async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(
      (device) => device.kind === "videoinput"
    );
    this.setState({
      mediaDevice: videoDevices,
    });
    const videoConstraints = {};

    videoConstraints.facingMode = "environment";
    const constraints = {
      video: videoConstraints,
      audio: false,
    };
    this.capture(constraints);
  };
  async capture(constraints) {
    //   var constraints = {audio:true, video: { facingMode: "environment" } };

    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    // show it to user
    this.setState({
      currentStream: stream,
    });
    this.video.srcObject = stream;
    this.video.play();
    // init recording

    this.mediaRecorder = new MediaRecorder(stream, {
      mimeType: "video/webm",
    });
    // init data storage for video chunks
    this.chunks = [];
    // listen for data from media recorder
    this.mediaRecorder.ondataavailable = (e) => {
      if (e.data && e.data.size > 0) {
        this.chunks.push(e.data);
      }
    };
  }
  takeSign(item, index) {
    this.setState({
      visible: true,
      index: index,
    });
  }
  takeAudio(item, index) {
    this.setState({
      audioVisible: true,
      index: index,
    });
  }
  startRecording = () => {
    this.setState({
      record: true,
    });
  };
  stopMediaTracks(stream) {
    stream.getTracks().forEach((track) => {
      track.stop();
    });
  }

  startVideoRecord(e) {
    // wipe old data chunks
    this.chunks = [];
    // start recorder with 10ms buffer
    this.mediaRecorder.start(10);
    // say that we're recording
    this.setState({ videoRecord: true });
  }
  changeCamera(event) {
    if (typeof this.state.currentStream !== "undefined") {
      this.stopMediaTracks(this.state.currentStream);
    }
    const videoConstraints = {};
    if (event.target.value === "") {
      videoConstraints.facingMode = "environment";
    } else {
      videoConstraints.deviceId = { exact: event.target.value };
    }
    const constraints = {
      video: videoConstraints,
      audio: false,
    };
    this.capture(constraints);
  }
  stopVideoRecord(e) {
    // stop the recorder
    this.mediaRecorder.stop();
    // say that we're not recording
    this.setState({ videoRecord: false });
    // save the video to memory
    this.stopMediaTracks(this.state.currentStream);
    this.saveVideo();
  }
  saveVideo() {
    const blob = new Blob(this.chunks, { type: "video/mp4" });
    const videoURL = window.URL.createObjectURL(blob);
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = videoURL;
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].blob = blob;
    this.forceUpdate();
    this.setState({
      videoVisible: false,
      index: null,
    });
  }
  stopRecording = () => {
    this.setState({
      record: false,
    });
  };

  onData(recordedBlob) {
    console.log("chunk of real-time data is: ", recordedBlob);
  }

  onStop = (recordedBlob) => {
    console.log(recordedBlob);
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = recordedBlob.blobURL;
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].blob = recordedBlob.blob;
    this.forceUpdate();
  };
  closeModal() {
    this.setState({
      visible: false,
      index: null,
    });
    this.sigPad.clear();
  }
  closeAudioModal() {
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = "";
    this.forceUpdate();
    this.setState({
      audioVisible: false,
      index: null,
    });
  }
  closeVideoModal() {
    this.props.data[this.state.currentStep].microstep_data[
      this.state.index
    ].value = "";
    this.forceUpdate();
    this.setState({
      videoVisible: false,
      index: null,
    });
  }
  signTrim() {
    if (!this.sigPad.isEmpty()) {
      const sign = this.sigPad.getTrimmedCanvas().toDataURL("image/png");
      this.props.data[this.state.currentStep].microstep_data[
        this.state.index
      ].value = sign;
      this.props.data[this.state.currentStep].microstep_data[
        this.state.index
      ].edit = true;
      this.forceUpdate();
      this.setState({
        visible: false,
        index: null,
      });
      this.sigPad.clear();
    }
  }
  removeSign(item, index) {
    this.props.data[this.state.currentStep].microstep_data[index].value = "";
    this.forceUpdate();
  }
  saveAudio() {
    if (!this.state.record) {
      this.setState({
        audioVisible: false,
        index: null,
      });
    }
  }

  checkBoxAction(data, item, index) {
    if (item.is_multiselect === "1") {
      if (data.checked === 1) {
        data.checked = 0;
        this.forceUpdate();
      } else {
        data.checked = 1;
        this.forceUpdate();
      }
    } else {
      item.options.forEach((data, ind) => {
        if (ind === index) {
          data.checked = 1;
          this.forceUpdate();
        } else {
          data.checked = 0;
          this.forceUpdate();
        }
      });
    }
    console.log(item);
  }
  MCQAction(data, item, index, indexV) {
    console.log(this.state.currentStep);
    if (item.is_multiselect === "1") {
      if (data.imcaChecked === 1) {
        data.imcaChecked = 0;
        this.forceUpdate();
      } else {
        data.imcaChecked = 1;
        this.forceUpdate();
      }
      let files = item.options.filter((dt) => dt.imcaChecked === 1);
      let ft = files.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      let files2 = item.options.filter((dt) => dt.checked === 1);
      let ans = files2.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      this.props.data[this.state.currentStep].microstep_data[indexV].user_ans =
        ft;
      this.props.data[this.state.currentStep].microstep_data[indexV].ans = ans;
      this.forceUpdate();
    } else {
      item.options.forEach((data, ind) => {
        if (ind === index) {
          data.imcaChecked = 1;
          this.forceUpdate();
        } else {
          data.imcaChecked = 0;
          this.forceUpdate();
        }
      });

      let files = item.options.filter((dt) => dt.imcaChecked === 1);
      let ft = files.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      let files2 = item.options.filter((dt) => dt.checked === 1);
      let ans = files2.map((ds) => {
        let dt = { ans: ds.name };
        return dt;
      });
      this.props.data[this.state.currentStep].microstep_data[indexV].user_ans =
        ft;
      this.props.data[this.state.currentStep].microstep_data[indexV].ans = ans;
      this.forceUpdate();
    }
    item.slider = "1";
    this.forceUpdate();
    console.log(item);
  }
  insightAction(dataV, item, i, index) {
    item.insight_options.forEach((data, ind) => {
      if (ind === i) {
        data.checked = "1";
        this.forceUpdate();
      } else {
        data.checked = "0";
        this.forceUpdate();
      }
    });
    console.log(item);
  }
  checkbox_icon(is_multiselect, checked) {
    if (is_multiselect === "1") {
      if (checked === 0) {
        return (
          <img
            src={require("../images/unchecked_48dp.png")}
            alt="uncheck"
            style={{ paddingRight: 10, height: 20 }}
          />
        );
      } else {
        return (
          <img
            src={require("../images/checked_48dp.png")}
            alt="checked"
            style={{ paddingRight: 10, height: 20 }}
          />
        );
      }
    } else {
      if (checked === 0) {
        return (
          <img
            src={require("../images/radio_unchecked_48dp.png")}
            alt="uncheck"
            style={{ paddingRight: 10, height: 20 }}
          />
        );
      } else {
        return (
          <img
            src={require("../images/radio_checked_48dp.png")}
            alt="uncheck"
            style={{ paddingRight: 10, height: 20 }}
          />
        );
      }
    }
  }
  mcqValueCheck(item) {
    if (
      item.some((data) => {
        return data.imcaChecked === 1;
      })
    ) {
      // console.log('checked true')
      return true;
    } else {
      // console.log('checked false')
      return false;
    }
  }
  handleOnSliderChange(onChange, item) {
    console.log(onChange.target.value, item);
    item.slider = onChange.target.value;
    this.forceUpdate();
  }
  pollValueCheck(item) {
    if (
      item.some((data) => {
        return data.checked === 1;
      })
    ) {
      return true;
    } else {
      return false;
    }
  }
  checkValueCheck(item) {
    if (
      item.some((data) => {
        return data.checked === 1;
      })
    ) {
      return true;
    } else {
      return false;
    }
  }

  insightValueCheck(item) {
    if (
      item.some((data) => {
        return data.checked === 1;
      })
    ) {
      return true;
    } else {
      // this.setState({ requiredCheck: false})
      return false;
    }
  }
  textInputInfo(event, index) {
    console.log(event.target.value);
    this.props.data[this.state.currentStep].microstep_data[index].value =
      event.target.value;
    this.forceUpdate();
  }
  textInputRemarks(event, index) {
    console.log(event.target.value);
    this.props.data[this.state.currentStep].microstep_data[index].remarks =
      event.target.value;
    this.forceUpdate();
  }
  ref = (player) => {
    this.player = player;
  };
  layout(item, index, step_id) {
    if (item.type === "scorm") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <p>Scrom</p>
              <img
                onClick={() => this.launchScrom(item.name)}
                src={require("../images/camera.png")}
                alt="camera"
              />
            </div>
          </div>
        </div>
      );
    }
    if (item.type === "webgl") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <p>WebGL</p>
              <BigPicture type="iframe" src={item.name}>
                <div
                  className="flex justify-content-center flex-column align-item-center"
                  style={{
                    padding: "10px",
                    background: "#f5f5f5",
                  }}
                >
                  <p>Click play Button to view WebGL</p>
                  <MdPlay fontSize="60px" color="#005284" />
                </div>
              </BigPicture>
              {/* <iframe src={item.name} style={{width:'100%',height:400}} title="test"/> */}
              {/* <img onClick={() => this.launchScrom(item.name)} src={require('../images/camera.png')} alt="camera" /> */}
            </div>
          </div>
        </div>
      );
    }
    if (item.type === "youtube") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <BigPicture
                type="youtube"
                src={"https://www.youtube.com/watch/v=" + item.name}
              >
                <div className="flex" style={{ position: "relative" }}>
                  <img
                    src={"https://img.youtube.com/vi/" + item.name + "/0.jpg"}
                    className="img-responsive"
                    style={{ width: "100%" }}
                    alt="test"
                  />
                  <div className="overlay">
                    {" "}
                    <img
                      src={require("../images/youtube.png")}
                      style={{ width: "70px" }}
                      alt="test"
                    />
                  </div>
                </div>
              </BigPicture>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "paragraph") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <div dangerouslySetInnerHTML={{ __html: item.name }} />
            </div>
          </div>
        </div>
      );
    } else if (item.type === "image") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content text-center">
              <BigPicture type="image" src={item.name}>
                <div className="flex justify-content-center">
                  <img src={item.name} alt="step" className="img-responsive" />
                </div>
              </BigPicture>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "video") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              {/* <FancyVideo
                                source={item.name}
                                poster={require('../images/white.jpg')}
                                id={Math.random().toString(36).substring(7)} /> */}
              {/* <video src={item.name} controls style={{ width: '100%' }}>
                                Your browser does not support the video tag.
</video> */}
              <BigPicture type="video" src={item.name}>
                <div
                  className="flex"
                  style={{ position: "relative", cursor: "pointer" }}
                >
                  {item.preview ? (
                    <div
                      style={{
                        width: "100%",
                        height: "320px",
                        position: "relative",
                        overflow: "hidden",
                        borderRadius: "4px",
                      }}
                    >
                      <img
                        src={item.preview}
                        className="img-responsive"
                        style={{
                          position: "absolute",
                          top: "0",
                          bottom: "0",
                          margin: "auto",
                          width: "100%",
                        }}
                        alt="test one"
                      />
                    </div>
                  ) : (
                    <img
                      src={require("../images/black.jpg")}
                      className="img-responsive"
                      style={{ width: "100%", height: 200 }}
                      alt="test one"
                    />
                  )}
                  <div className="overlay">
                    <i
                      class="fa fa-play"
                      style={{ color: "#ffffff", fontSize: "65px" }}
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </BigPicture>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "embed") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <div
                className="embed"
                dangerouslySetInnerHTML={{ __html: item.name }}
              />
            </div>
          </div>
        </div>
      );
    } else if (item.type === "weblink") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content text-center">
              <a
                href={item.name}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  padding: "8px 20px",
                  display: "inline-block",
                  color: "#fff",
                  background: "#005284",
                  borderRadius: 3,
                  fontSize: 15,
                  margin: 10,
                }}
              >
                Click Here
              </a>
              <p>This will Open on a separate tab</p>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "pdfs") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <BigPicture type="iframe" src={item.name}>
                <div
                  className="flex justify-content-center"
                  style={{ position: "relative" }}
                >
                  {item.preview ? (
                    <img
                      src={item.preview}
                      className="img-responsive"
                      style={{ width: 320, height: 400 }}
                      alt="test"
                    />
                  ) : (
                    <img
                      src={require("../images/black.jpg")}
                      className="img-responsive"
                      style={{ width: "100%", height: 200 }}
                      alt="test"
                    />
                  )}
                  <div className="overlay">
                    {" "}
                    <img
                      src={require("../images/pdf.png")}
                      style={{ width: "70px" }}
                      alt="test"
                    />
                  </div>
                </div>
              </BigPicture>
              {/* <iframe src={item.name} width='100%' height="400px" title="red" allowFullScreen/> */}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "audio") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <BigPicture type="audio" src={item.name}>
                <div className="flex justify-content-center">
                  <img
                    src={require("../images/1200px-Speaker_Icon.png")}
                    style={{ width: "70px" }}
                    alt="test"
                  />
                </div>
              </BigPicture>
              {/* <audio controls>
                                <source src={item.name}></source>
                            </audio> */}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "userimage") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : item.required === "1"
                ? item.value === ""
                  ? "1px solid red"
                  : "1px solid #fff"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <div
                className="flex justify-content-end"
                style={{ position: "relative" }}
              >
                {item.value === "" ? (
                  ""
                ) : (
                  <img
                    src={require("../images/clear.png")}
                    onClick={() => this.removeSign(item, index)}
                    className="img-responsive"
                    style={{
                      width: 12,
                      position: "absolute",
                      top: 4,
                      right: 4,
                    }}
                    alt="demo"
                  />
                )}
              </div>
              <div
                className="flex justify-content-center"
                style={{ border: "1px solid", padding: 10 }}
              >
                {item.value === "" ? (
                  <img
                    onClick={() => this.takePicture(item, index)}
                    src={require("../images/camera.png")}
                    alt="camera"
                  />
                ) : item.edit ? (
                  <img
                    src={item.value}
                    alt="camera"
                    className="img-responsive"
                  />
                ) : (
                  <img
                    src={
                      this.props.url + step_id + "/input_image/" + item.value
                    }
                    alt="camera"
                    className="img-responsive"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "useraudio") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : item.required === "1"
                ? item.value === ""
                  ? "1px solid red"
                  : "1px solid #fff"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <div
                className="flex justify-content-end"
                style={{ position: "relative" }}
              >
                {item.value === "" ? (
                  ""
                ) : (
                  <img
                    src={require("../images/clear.png")}
                    onClick={() => this.removeSign(item, index)}
                    className="img-responsive"
                    style={{
                      width: 12,
                      position: "absolute",
                      top: 4,
                      right: 4,
                    }}
                    alt="demo"
                  />
                )}
              </div>
              <div
                className="flex justify-content-center"
                style={{ border: "1px solid", padding: 10 }}
              >
                {item.value === "" ? (
                  <img
                    onClick={() => this.takeAudio(item, index)}
                    src={require("../images/microphone.png")}
                    alt="camera"
                  />
                ) : item.blob ? (
                  <BigPicture type="audio" src={item.value}>
                    <div className="flex justify-content-center">
                      <img
                        src={require("../images/1200px-Speaker_Icon.png")}
                        style={{ width: "70px" }}
                        alt="test"
                      />
                    </div>
                  </BigPicture>
                ) : (
                  <BigPicture
                    type="audio"
                    src={
                      this.props.url + step_id + "/input_audio/" + item.value
                    }
                  >
                    <div className="flex justify-content-center">
                      <img
                        src={require("../images/1200px-Speaker_Icon.png")}
                        style={{ width: "70px" }}
                        alt="test"
                      />
                    </div>
                  </BigPicture>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "uservideo") {
      return (
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <div
                className="flex justify-content-end"
                style={{ position: "relative" }}
              >
                {item.value === "" ? (
                  ""
                ) : (
                  <img
                    src={require("../images/clear.png")}
                    onClick={() => this.removeSign(item, index)}
                    className="img-responsive"
                    style={{
                      width: 12,
                      position: "absolute",
                      top: 4,
                      right: 4,
                    }}
                    alt="demo"
                  />
                )}
              </div>
              <div
                className="flex justify-content-center"
                style={{ border: "1px solid", padding: 10 }}
              >
                {
                  item.value === "" ? (
                    <img
                      onClick={() => this.takeVideo(item, index)}
                      src={require("../images/video.png")}
                      alt="camera"
                    />
                  ) : item.blob ? (
                    <FancyVideo
                      source={item.value}
                      poster={require("../images/white.jpg")}
                      id={Math.random().toString(36).substring(7)}
                    />
                  ) : (
                    <FancyVideo
                      source={
                        this.props.url + step_id + "/input_video/" + item.value
                      }
                      poster={require("../images/white.jpg")}
                      id={Math.random().toString(36).substring(7)}
                    />
                  )
                  // <video controls controlsList="nodownload" style={{ width: '100%' }}>
                  //     <source src={item.value}></source>
                  // </video>
                }
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "textbox") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #ffffff"
                : item.required === "1"
                ? item.value === ""
                  ? "1px solid red"
                  : "1px solid #ffffff"
                : "1px solid #ffffff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <textarea
                rows="6"
                style={{
                  width: "100%",
                    borderRadius: 8,
                    padding: "4px 8px",
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: "inherit",
                    border: '.75px solid #ffffff',
                  borderColor:
                    this.state.requiredCheck === true
                      ? "#ffffff"
                      : item.required === "1"
                      ? item.value === ""
                        ? "red"
                        : "#ffffff"
                      : "#ffffff",
                }}
                onChange={(event) => this.textInputInfo(event, index)}
              >
                {item.value}
              </textarea>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "poll") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : this.pollValueCheck(item.options) === false
                ? "1px solid red"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p dangerouslySetInnerHTML={{ __html: item.value }}></p>
              {item.options.map((data, i) => {
                return (
                  <div
                    className="flex"
                    onClick={() => this.checkBoxAction(data, item, i)}
                  >
                    <div>
                      {this.checkbox_icon(item.is_multiselect, data.checked)}
                    </div>
                    <div>{data.name}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "checkpoint") {
      return (
        <div
          className="card horizontal"
          style={{
            padding: "12px 18px",
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : this.checkValueCheck(item.options) === false
                ? "1px solid red"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p dangerouslySetInnerHTML={{ __html: item.value }} />
              <div style={{ display: "flex" }}>
                {item.options.map((data, i) => {
                  return (
                    <div
                      className="flex mb-4"
                      style={
                        data.checked === "1"
                          ? {
                              backgroundColor: "red !important",
                              padding: "5px 20px",
                            }
                          : { padding: "5px 20px" }
                      }
                      onClick={() => this.checkBoxAction(data, item, i)}
                    >
                      <div style={{ cursor: "pointer" }}>
                        {this.checkbox_icon(item.is_multiselect, data.checked)}
                      </div>
                      <div style={{ cursor: "pointer" }}>{data.name}</div>
                    </div>
                  );
                })}
              </div>
              <div className="mt-3">
                <textarea
                  rows="6"
                  placeholder="Any Remarks"
                  style={{
                    width: "100%",
                    borderRadius: 8,
                    padding: "4px 8px",
                    fontSize: 14,
                    fontWeight: 400,
                    letterSpacing: "inherit",
                    border: '.75px solid #ffffff',
                  }}
                  onChange={(event) => this.textInputRemarks(event, index)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (item.type === "mcq") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : this.mcqValueCheck(item.options) === false
                ? "1px solid red"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p
                style={{ whiteSpace: "pre-line" }}
                dangerouslySetInnerHTML={{ __html: item.value }}
              ></p>
              {item.options.map((data, indexMCQ) => {
                return (
                  <div
                    className="flex"
                    onClick={() => this.MCQAction(data, item, indexMCQ, index)}
                  >
                    <div>
                      {this.checkbox_icon(
                        item.is_multiselect,
                        data.imcaChecked
                      )}
                    </div>
                    <div>{data.name}</div>
                  </div>
                );
              })}
              {this.mcqValueCheck(item.options) === false ? null : (
                <div className="col-md-12 mt-2">
                  {/* {item.insight_options.map((data, i) => {
                                            return (
                                                <div style={{ borderRadius: 25, backgroundColor: data.checked === '1' ? (data.name === 'guess' ? '#005188' : '#005188') : '#e8e8e8' }} onClick={() => this.insightAction(data, item, i, index)}>
                                                    <div style={{ padding: "2px 10px", fontSize: 14, color: data.checked === '1' ? '#fff' : '#000', fontWeight: data.checked === '1' ? '500' : '300' }}> {data.name === 'sure' ? " I am sure " : " I am not sure "}</div>
                                                </div>
                                            )
                                        })} */}
                  <p style={{ margin: 0 }}>
                    How sure are you about your answer ?
                  </p>
                  <RangeSlider
                    value={item.slider}
                    min={1}
                    max={5}
                    step={1}
                    size={"lg"}
                    tooltip={"off"}
                    onChange={(value) => this.handleOnSliderChange(value, item)}
                  />
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <div
                    className="flex"
                    style={{ justifyContent: "space-between" }}
                  >
                    <span>Not Sure</span>
                    <span>Very Sure</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      );
    } else if (item.type === "sign") {
      return (
        <div
          className="card horizontal"
          style={{
            border:
              this.state.requiredCheck === true
                ? "1px solid #fff"
                : item.required === "1"
                ? item.value === ""
                  ? "1px solid red"
                  : "1px solid #fff"
                : "1px solid #fff",
          }}
        >
          <div className="card-stacked">
            <div className="card-content">
              <p dangerouslySetInnerHTML={{ __html: item.name }}></p>
              <div
                className="flex justify-content-end"
                style={{ position: "relative" }}
              >
                {item.value === "" ? (
                  ""
                ) : (
                  <img
                    src={require("../images/clear.png")}
                    onClick={() => this.removeSign(item, index)}
                    className="img-responsive"
                    style={{
                      width: 22,
                      position: "absolute",
                      top: 0,
                      right: 0,
                      padding: "6px 6px",
                      cursor: "pointer",
                    }}
                    alt="demo"
                  />
                )}
              </div>
              <div
                className="flex justify-content-center"
                style={{
                  border: "0.5px solid #005284",
                  padding: 10,
                  borderRadius: "4px",
                  cursor: "pointer",
                }}
                onClick={() => this.takeSign(item, index)}
              >
                {item.value === "" ? (
                  <img
                    src={require("../images/signature.png")}
                    className="img-responsive"
                    style={{ width: 70 }}
                    alt="Signature camera"
                  />
                ) : (
                  <img
                    src={item.value}
                    alt="Signature camera"
                    className="img-responsive"
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  _back(i) {
    this.setState({ currentStep: i - 1 });
    window.scrollTo({
      top: 0,
    });
  }
  _next(i, total) {
    this.setState({
      requiredCheck: true,
    });
    this.dynamicCheckFunction(
      this.props.data[this.state.currentStep].microstep_data
    );
    setTimeout(() => {
      if (this.state.requiredCheck === true) {
        if (i !== total - 1) {
          this.setState({ currentStep: i + 1 });
        }
        if (i === total - 1) {
          this.alertAction();
          // this.props.history.push('/area');
        }
      } else {
        alert("Please fill all the required fields");
      }
    }, 100);
    window.scrollTo({
      top: 0,
    });
  }
  async dynamicCheckFunction(tt) {
    // console.log("Dynamic check func", tt)
    await tt.forEach((item, index) => {
      if (item.type === "textbox") {
        if (item.required === "1") {
          if (item.value === "") {
            this.setState({
              requiredCheck: false,
            });
          } else {
            this.setState({
              textBox: { borderColor: "lightgray" },
            });
          }
        }
      } else if (item.type === "sign") {
        if (item.required === "1") {
          if (item.value === "") {
            this.setState({
              requiredCheck: false,
            });
          } else {
            console.log("5");
            this.setState({
              requiredCheck: true,
            });
          }
        }
      } else if (item.type === "userimage") {
        if (item.required === "1") {
          if (item.value === "") {
            this.setState({
              requiredCheck: false,
            });
          } else {
          }
        }
      } else if (item.type === "poll") {
        if (item.required === "1") {
          if (item.options.some((data) => data.checked === 1)) {
            this.setState({
              pollBox: { borderColor: null, borderWidth: 0 },
              requiredCheck: true,
            });
          } else {
            this.setState({
              requiredCheck: false,
            });
          }
        } else {
          this.setState({
            requiredCheck: true,
          });
        }
      } else if (item.type === "checkpoint") {
        if (item.required === "1") {
          if (item.options.some((data) => data.checked === 1)) {
            this.setState({
              pollBox: { borderColor: null, borderWidth: 0 },
              requiredCheck: true,
            });
          } else {
            this.setState({
              requiredCheck: false,
            });
          }
        } else {
          this.setState({
            requiredCheck: true,
          });
        }
      } else if (item.type === "mcq") {
        // if (item.required == "1") {
        if (
          item.options.some((data) => {
            return data.imcaChecked === 1;
          })
        ) {
          this.setState({
            requiredCheck: true,
          });
        } else {
          this.setState({
            requiredCheck: false,
          });
        }
      } else if (item.type === "uservideo") {
        if (item.required === "1") {
          if (item.value === "") {
            this.setState({
              videoBox: { borderColor: "red" },
              requiredCheck: false,
            });
          } else {
          }
        }
      } else if (item.type === "useraudio") {
        if (item.required === "1") {
          if (item.value === "") {
            this.setState({
              requiredCheck: false,
            });
          } else {
          }
        }
      }
    });
  }
  pauseSession() {
    this.setState({
      loading: true,
      submitStatus: 2,
    });
    // console.log(params.page);
    this.submitButtonApi(this.props.data, this.props.data);
  }
  acuizenSubmitAction() {
    this.setState({
      loading: true,
      submitStatus: 3,
    });
    // console.log(params.page);
    this.submitButtonApi(this.props.data, this.props.data);
  }

  getFilename = (dataUrl) => {
    let filename = Math.random()
      .toString(36)
      .replace("0.", "image_" || "");
    const arr = dataUrl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];

    return filename + "." + mime.split("/").pop();
  };
  dataURItoFile = (dataURI, filename) => {
    var byteString = atob(dataURI.split(",")[1]);
    // separate out the mime component
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var dw = new DataView(ab);
    for (var i = 0; i < byteString.length; i++) {
      dw.setUint8(i, byteString.charCodeAt(i));
    }

    // write the ArrayBuffer to a blob, and you're done
    return new File([ab], filename, { type: mimeString });
  };
  submitButtonApi(response, element) {
    var req = [];
    var req1 = [];
    var req2 = [];
    var userImageIndex = 0;
    var userSignIndex = 0;
    var userAudioIndex = 0;
    var userVideoIndex = 0;
    element.forEach((data, i) => {
      data.microstep_data.forEach((ele, ind) => {
        if (ele.type === "userimage") {
          if (ele.value === "") {
          } else {
            if (ele.edit) {
              let filename = this.getFilename(ele.value);
              let obj = {
                [`step_id[${data.microstep_id}][input_image][${userImageIndex}]`]:
                  ele.value,
                filename: filename,
              };
              ele.value = filename;
              req.push(obj);
              userImageIndex = userImageIndex + 1;
              delete ele.edit;
            }
          }
        } else if (ele.type === "sign") {
          if (ele.value === "") {
          } else {
            if (ele.edit) {
              let filename = this.getFilename(ele.value);
              let obj = {
                [`step_id[${data.microstep_id}][sign][${userSignIndex}]`]:
                  ele.value,
                filename: filename,
              };
              ele.value = filename;
              req.push(obj);
              userSignIndex = userSignIndex + 1;
              delete ele.edit;
            }
          }
        } else if (ele.type === "useraudio") {
          if (ele.value === "") {
          } else {
            if (ele.blob) {
              let filename = cryptoRandomString({ length: 17 }) + ".mp3";
              let obj1 = {
                [`step_id[${data.microstep_id}][input_audio][${userAudioIndex}]`]:
                  ele.blob,
                filename: filename,
              };

              console.log(filename);
              ele.value = filename;
              req1.push(obj1);
              userAudioIndex = userAudioIndex + 1;
              delete ele.blob;
            }
          }
        } else if (ele.type === "uservideo") {
          if (ele.value === "") {
          } else {
            if (ele.blob) {
              let filename = cryptoRandomString({ length: 20 }) + ".mp4";
              let obj2 = {
                [`step_id[${data.microstep_id}][input_video][${userVideoIndex}]`]:
                  ele.blob,
                filename: filename,
              };
              console.log(filename);
              ele.value = filename;
              req2.push(obj2);
              userVideoIndex = userVideoIndex + 1;
              delete ele.blob;
            }
          }
        }
      });
      userImageIndex = 0;
      userSignIndex = 0;
      userAudioIndex = 0;
      userVideoIndex = 0;
    });
    console.log(req);
    setTimeout(() => {
      this.uploadApiCallAction(req, req1, req2, response, element);
    }, 1000);
  }
  async uploadApiCallAction(req, req1, req2, data, page) {
    let formData = new FormData();
    if (req.length >= 0) {
      req.forEach((element, index) => {
        console.log(element);
        var key = Object.keys(element)[0];
        var key1 = Object.keys(element)[1];
        var filename = element[key1];
        var value = this.dataURItoFile(element[key], filename);
        console.log(value);
        formData.append(`${key}`, value);
      });
    }
    if (req1.length >= 0) {
      req1.forEach((element, index) => {
        console.log(element);
        var key = Object.keys(element)[0];
        var key1 = Object.keys(element)[1];
        var filename = element[key1];
        var value = new File([element[key]], filename, {
          type: "audio/mp3",
          lastModified: Date.now(),
        });
        console.log(value);
        formData.append(`${key}`, value);
      });
    }
    if (req2.length >= 0) {
      req2.forEach((element, index) => {
        console.log(element);
        var key = Object.keys(element)[0];
        var key1 = Object.keys(element)[1];
        var filename = element[key1];
        var value = new File([element[key]], filename, {
          type: "video/mp4",
          lastModified: Date.now(),
        });
        console.log(value);
        formData.append(`${key}`, value);
      });
    }
    var latitude = this.state.lat;
    var longitude = this.state.lon;
    formData.append("user_id", localStorage.getItem("token"));

    formData.append("input_json", JSON.stringify(data));
    formData.append("session_id", this.props.session_id);
    formData.append("lat", latitude);
    formData.append("lon", longitude);
    formData.append("status", this.state.submitStatus);
    formData.append("token_id", localStorage.getItem("token"));
    formData.append("step_id", page[this.state.currentStep].microstep_id);
    formData.append("duration", this.state.seconds);
    if (this.state.submitStatus === 3) {
      axios({
        method: "post",
        url: API.SubmitUnit,
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
        mode: "no-cors",
      }).then((res) => {
        this.setState({ loading: false });

        this.callAssignedApi();
      });
    } else {
      formData.append("currentStep", this.state.currentStep);
      axios({
        method: "post",
        url: API.SubmitPauseUnit,
        data: formData,
        headers: {
          "content-type": "multipart/form-data",
        },
        mode: "no-cors",
      }).then((res) => {
        this.setState({ loading: false });

        // this.callAssignedApi()
        this.props.history.push("/dashboard");
      });
    }
    console.log(formData);
  }
  deletePauseSession(session_id) {
    this.setState({ loading: true });
    let formData = new FormData();
    formData.append("user_id", localStorage.getItem("token"));
    formData.append("session_id", session_id);
    axios({
      method: "post",
      url: API.DeletePauseUnit,
      data: formData,
      headers: {
        "content-type": "multipart/form-data",
      },
      mode: "no-cors",
    }).then((res) => {
      setInterval(() => {
        this.setState({ loading: false });
        this.props.history.push("/dashboard");
      }, 2000);
    });
  }
  alertAction() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="row">
            <div
              className="col-12"
              style={{
                width: 300,
                background: "#fff",
                padding: 20,
                boxShadow: "0px 0px 12px 3px #dbdbdb",
              }}
            >
              <h3 className="mb-5">AcuiZen</h3>
              <div className="flex justify-content-between">
                <span style={{ color: "red", fontSize: 12, cursor: 'pointer' }} onClick={onClose}>
                  CANCEL
                </span>
                <span
                  style={{ color: "#005284", fontSize: 14, cursor: 'pointer' }}
                  onClick={() => {
                    this.acuizenSubmitAction();
                    onClose();
                  }}
                >
                  {" "}
                  SUBMIT{" "}
                </span>
              </div>
            </div>
          </div>
        );
      },
    });
  }
  callAssignedApi() {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="row">
            <div
              className="col-12"
              style={{
                width: 300,
                background: "#fff",
                padding: 20,
                boxShadow: "0px 0px 12px 3px #dbdbdb",
              }}
            >
              <h3 className="">AcuiZen</h3>
              <p>Your session and responses (if any) have been recorded</p>
              <div className="flex justify-content-end">
                <span
                  style={{ color: "#005284", fontSize: 14, cursor: 'pointer' }}
                  onClick={() => {
                    this.props.history.push("/dashboard");
                    onClose();
                  }}
                >
                  {" "}
                  OK{" "}
                </span>
              </div>
            </div>
          </div>
        );
      },
    });
  }

  render() {
    console.log(this.state.currentStep);
    const datalength = 100 / this.props.data.length;
    return (
      <div className="">
        <IdleTimerLogout />
        <nav
          class="navbar navbar-top navbar-expand navbar-dark  border-bottom"
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1,
          }}
        >
          <div class="container pad_z">
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              {/* <div className="col-sm-3 px-0 "> */}
                {/* <i className="material-icons back-btn grey-text" onClick={() => this.props.history.goBack()}>arrow_back</i> */}
              {/* </div> */}

              {/* <div className="col-sm-6 text-center">
                <img
                  src={localStorage.getItem("logo")}
                  alt="az_logo"
                  className="responsive-img"
                  style={{ width: "100px", height: "auto" }}
                />
              </div> */}

              {this.props.location.state.checklist ? (
                <div className="col-12 pad_z mt-1" id="unit_name">
                  {this.props.title}
                </div>
              ) : (
                <div
                  className="col-9 pad_z mt-1"
                  id="unit_name"
                  style={{ color: "#005284", display: "flex" }}
                >
                  <div
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      color: "#afafaf",
                      alignSelf: "center",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    {this.props.area}
                  </div>
                  <div style={{ fontSize: 20 }}> {" > "}</div>
                  <div
                    style={{
                      fontSize: 12,
                      marginRight: 10,
                      marginLeft: 10,
                      color: "#afafaf",
                      alignSelf: "center",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}
                  >
                    {this.props.topic}
                  </div>
                  <div style={{ fontSize: 20 }}>{" > "}</div>
                  <div
                    style={{
                      fontSize: 13,
                      marginLeft: 10,
                      alignSelf: "center",
                      color: "#005284",
                      fontWeight: "600",
                    }}
                  >
                    {this.props.title}
                  </div>


                  {/* <img
                  src={localStorage.getItem("logo")}
                  alt="az_logo"
                  className="responsive-img"
                  style={{ width: "100px", height: "auto" }}
                /> */}
                </div>
              )}
              {/* {this.props.location.state.checklist ? <div className="col-12 pad_z mt-1" id="unit_name">{this.props.title}</div> :
                                <div className="col-9 pad_z mt-1" id="unit_name" style={{color:'black',display:'flex'}}> <div style={{fontSize:14,marginRight:10,color:'#d3d3d3',alignSelf:'center'}}>{this.props.area} </div> <div style={{fontSize:24}}> {" / "}</div> <div style={{fontSize:14,marginRight:10,marginLeft:10,color:'#d3d3d3',alignSelf:'center'}}>{this.props.topic} </div> <div style={{fontSize:24}}>{" / "}</div> <div style={{fontSize:16,marginLeft:10,alignSelf:'center'}}>  {this.props.title} </div> </div>} */}

              {/* <div className="col-sm-6 text-center pad_z"> <img src={localStorage.getItem('logo')} alt="wavemakers_logo" className="responsive-img" style={{ height: 50 }} /></div> */}

                                 {/* <div className="col-sm-6 text-center">
                <img
                  src={localStorage.getItem("logo")}
                  alt="az_logo"
                  className="responsive-img"
                  style={{ width: "100px", height: "auto" }}
                />
              </div> */}


              <div className="col-3 text-right  pad_z vertical-align justify-content-end">

                <i
                  className="material-icons mr-3"
                  style={{ cursor: "pointer", color: "#005284" }}
                  onClick={() => this.pauseSession()}
                >
                  pause
                </i>
                <i
                  className="material-icons"
                  style={{ cursor: "pointer", color: "#005284" }}
                  onClick={() =>
                    this.deletePauseSession(this.props.match.params.id)
                  }
                >
                  close
                </i>
              </div>
            </div>
          </div>
        </nav>
        <div style={{ height: 82 }}></div>
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 mb-5"
              style={{ background: "#ffffff", borderRadius: "8px" }}
            >
              {/* {this.props.location.state.checklist ? (
                <h4 className="col-12 pad_z mt-1" id="unit_name">
                  {this.props.title}
                </h4>
              ) : (
                <h4
                  style={{ color: "white" }}
                  className="col-12 pad_z mt-1"
                  id="unit_name"
                >
                  {this.props.area} {">"} {this.props.topic} {">"}{" "}
                  {this.props.title}
                </h4>
              )} */}
              <div className="pt-3 pb-3">
                <div className="col-12 steps pad_z">
                  {this.props.data.map((item, i) => {
                    if (this.state.currentStep >= i) {
                      return (
                        <span
                          key={i}
                          className={
                            this.state.currentStep === i
                              ? "active step"
                              : "done step"
                          }
                          style={{ width: datalength + "%", height: "3.2px" }}
                        ></span>
                      );
                    } else {
                      return (
                        <span
                          key={i}
                          className={
                            this.state.currentStep === i
                              ? "active step"
                              : "step"
                          }
                          style={{ width: datalength + "%", height: "3.2px" }}
                        ></span>
                      );
                    }
                  })}
                </div>
              </div>
              <div className="col-12 pad_z">
                {this.props.data.map((item, i) => {
                  return (
                    <div
                      key={i}
                      className="microstep col-12 pad_z"
                      id={"#" + i}
                      style={{
                        display:
                          this.state.currentStep === i ? "block" : "none",
                      }}
                    >
                      <h4
                        style={{
                          color: "#005284",
                          background: "#ffffff",
                          position: "relative",
                          display: "block",
                          padding: "26px 14px 36px 14px",
                          borderRadius: "10px",
                          textAlign: "center",
                          fontSize: "18px",
                          fontWeight: "600",
                        }}
                      >
                        {item.microstep_title}
                      </h4>

                      {item.microstep_data.map((item1, i) => {
                        return (
                          <div key={i} className="col-12 pad_z">
                            {this.layout(item1, i, item.microstep_id)}
                          </div>
                        );
                      })}
                      <div
                        className="step-btn w-100"
                        style={{ display: "initial" }}
                      >
                        <div className="container d-flex justify-content-center">
                          <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8 p-0">
                            <div className="flex pad_z">
                              {this.state.currentStep === 0 ? (
                                ""
                              ) : (
                                <button
                                  type="button"
                                  onClick={() => this._back(i)}
                                  className="step_back_btn"
                                >
                                  <i
                                    className="material-icons left"
                                    style={{ width: 25, color: "#005284" }}
                                  >
                                    arrow_backward
                                  </i>
                                  Back
                                </button>
                              )}

                              <button
                                type="button"
                                className={
                                  this.state.currentStep ===
                                  this.props.data.length - 1
                                    ? "bg-green"
                                    : ""
                                }
                                onClick={() =>
                                  this._next(i, this.props.data.length)
                                }
                              >
                                {this.state.currentStep ===
                                this.props.data.length - 1
                                  ? "Finish"
                                  : "Next"}
                                <i className="material-icons right">
                                  arrow_forward
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <Modal
          size="sm"
          show={this.state.visible}
          onHide={() => this.closeModal()}
          backdrop="static"
          centered
          animation
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Signature Panel
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <SignatureCanvas
              penColor="#005284"
              canvasProps={{ width: 300, height: 150, className: "sigCanvas" }}
              ref={(ref) => {
                this.sigPad = ref;
              }}
            />
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-content-between">
              <span
                onClick={() => this.closeModal()}
                variant={"p-1"}
                style={{ cursor: "pointer" }}
              >
                Cancel
              </span>
              <span
                onClick={() => this.sigPad.clear()}
                variant={"p-1"}
                style={{ cursor: "pointer" }}
              >
                Re-Sign
              </span>
              <span
                onClick={() => this.signTrim()}
                style={{ cursor: "pointer" }}
              >
                Done
              </span>
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          size="sm"
          show={this.state.audioVisible}
          onHide={() => this.closeAudioModal()}
          backdrop="static"
          centered
          animation
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Audio Recording
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="flex flex-column align-item-center justify-content-between">
              <ReactMic
                record={this.state.record}
                className="sound-wave"
                onStop={this.onStop}
                onData={this.onData}
                strokeColor="#000000"
                backgroundColor="#adb5bd"
                mimeType="audio/mp3"
              />
              <div className="">
                {this.state.record ? (
                  <img
                    onClick={() => this.stopRecording()}
                    style={{ width: 60, cursor: 'pointer' }}
                    src={require("../images/stop.png")}
                    alt="stop"
                    className="img-responsive"
                  />
                ) : (
                  <img
                    onClick={() => this.startRecording()}
                    style={{ width: 60, cursor: 'pointer' }}
                    src={require("../images/recording.png")}
                    alt="start"
                    className="img-responsive"
                  />
                )}
              </div>{" "}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="flex justify-content-between">
              <span style={{cursor: 'pointer'}} onClick={() => this.closeAudioModal()}>Cancel</span>
              {this.state.record ? (
                ""
              ) : (
                <span style={{cursor: 'pointer'}} onClick={() => this.saveAudio()}>Done</span>
              )}
            </div>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={this.state.videoVisible}
          onHide={() => this.closeVideoModal()}
          backdrop="static"
          centered
          animation
          aria-labelledby="example-modal-sizes-title-sm"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-sm">
              Video Recording
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <video
              width="100%"
              style={{ maxHeight: "450px" }}
              ref={(v) => {
                this.video = v;
              }}
            >
              Video stream not available.
            </video>
          </Modal.Body>
          <Modal.Footer>
            <div class="flex justify-content-between">
              <select onChange={(e) => this.changeCamera(e)}>
                {this.state.mediaDevice.map((media, i) => (
                  <option value={media.deviceId}>{`Camera ${i}`}</option>
                ))}
              </select>
              {!this.state.videoRecord && (
                <img
                  style={{ width: 50, cursor: 'pointer' }}
                  src={require("../images/media-record.png")}
                  alt="test"
                  onClick={(e) => this.startVideoRecord(e)}
                />
              )}
              {this.state.videoRecord && (
                <img
                  style={{ width: 50, cursor: 'pointer' }}
                  src={require("../images/stop.png")}
                  alt="test"
                  onClick={(e) => this.stopVideoRecord(e)}
                />
              )}
            </div>
          </Modal.Footer>
        </Modal>
        {this.state.loading && <Loader />}
        {this.props.loader && <Loader />}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.pause.data,
    session_id: state.pause.session,
    title: state.pause.title,
    area: state.pause.area,
    topic: state.pause.topic,
    current_step: state.pause.current_step,
    duration: state.pause.duration,
    loader: state.pause.loading,
    url: state.pause.url,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPauseData: (sessionId, program_id) =>
      dispatch(fetchData(sessionId, program_id)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PauseSession);
