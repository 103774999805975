import { FETCH_MICRO_DATA_REQUEST, FETCH_MICRO_DATA_SUCCESS, FETCH_MICRO_DATA_FAILURE } from './MicroTypes'
const initialState = {
    loading: false,
    data: [],
    session: '',
    title: '',
    error: ''
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MICRO_DATA_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_MICRO_DATA_SUCCESS:
            return {
                loading: false,
                data: action.payload.data,
                session: action.payload.session_id,
                title: action.payload.program_title,
                topic: action.payload.topic_title,
                area: action.payload.area_title
            }
        case FETCH_MICRO_DATA_FAILURE:
            return {
                loading: false,
                error: action.payload
            }

        default:
            return state;
    }
}
export default reducer;