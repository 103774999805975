import React, { Component } from "react";
import axios from "axios";
import Sidenav from "../pages/Sidenav";
import API from "../config_api";
import "../css/App.css";

import "../css/slideshow.css";
import "../css/slide_show.css";
import "../css/effect.css";

import Card from "../component/Card";
import { fetchData } from "../redux/assignedData/AssignAction";
import { connect, shallowEqual } from "react-redux";
// import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Doughnut } from "react-chartjs-2";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Loader from "../component/Loader";
import IdleTimerLogout from "./IdleTimerLogout";
import { auto } from "@popperjs/core";
import { Slide } from "react-slideshow-image";

class DashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      insights: "",
      red: "",
      blue: "",
      orange: "",
      green: "",
      percentage: "",
      Participationscore: "",
      reactivationscore: "",
      actionindex: "",
      loading1: false,
      loading2: false,
      actions: "",

      slidedata: [],
    };
  }

  async componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      let sen = await this.sliceIntoChunks(this.props.data, 5);
      this.setState({ slidedata: sen });

      // slidedata:[],
      // Participationscore: '',
      // reactivationscore: '',
      // actionindex:'',
    }
  }
  async componentDidUpdate(prevProps) {
    if (this.props.data !== prevProps.data) {
      let sen = await this.sliceIntoChunks(this.props.data, 5);
      this.setState({ slidedata: sen });
    }
  }

  componentWillMount() {
    if (!localStorage.getItem("token")) {
      window.location.href = "https://app.acuizen.com";
    } else {
      this.props.getAreaData();
      let formData = new FormData();
      formData.append("user_id", localStorage.getItem("token"));

      axios({
        method: "post",
        url: API.Dashboard,
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        mode: "no-cors",
      }).then((res) => {
        this.setState({
          loading1: true,
        });
        if (res.data.status === "success") {
          this.setState({ data: res.data.data });
          console.log(res.data.data);
        } else {
          alert(res.data.message);
        }
      });
      let formData1 = new FormData();
      formData1.append("user_id", this.userID());
      formData1.append("date", this.getDate());
      formData1.append(
        "time",
        new Date().toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
        })
      );

      axios({
        method: "post",
        url: API.UserReport,
        data: formData1,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        mode: "no-cors",
      }).then((res) => {
        console.log(res);
        this.setState({ loading2: true });

        this.setState({ insights: res.data.data.insights });
        this.setState({ percentage: res.data.data.overall_percentage });
        this.setState({ Participationscore: res.data.data.participation_rate });
        this.setState({ reactivationscore: res.data.data.reactivation_index });

        this.setState({ actionindex: res.data.data.action.acion_index });
        //    this.setState({ actionindex: res.data.data.action.acion_index })
        this.setState({ actions: res.data.data.action });

        console.log(res.data.data);
      });
    }
  }
  getDate = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };
  getTime = () => {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, "0");
    var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = today.getFullYear();

    return mm + "/" + dd + "/" + yyyy;
  };
  userID = () => {
    const userID = localStorage.getItem("token");
    const ID = userID.split("@");
    console.log(ID[0]);
    return ID[0];
  };
  units(item) {
    var len = 0;
    item.models.forEach((element1) => {
      len += element1.programs.length;
    });
    return len;
  }
  btnAction(models) {
    localStorage.setItem("area", models.name);
    this.props.history.push("/unit", { data: models });
  }

  sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  }
  render() {
    let { slidedata, sen } = this.state;
    //
    console.log(this.state.sen, this.state.slidedata);
    return (
      <div className=" ">
        <IdleTimerLogout />
        <Sidenav
          type={"main"}
          push={this.props.history}
          notification={this.state.data.notification > 0 ? true : false}
        />

        <div className="container">
          <div
            className="az-bg my-5 p-5"
            style={{ background: "#ffffff", borderRadius: "22px" }}
          >
            <div
              className="row align-items-center"
              style={{ paddingBottom: "36px" }}
            >
              <div className="col">
                <div>
                  <span style={{ fontSize: 15 }}>Hello </span>
                  <span
                    style={{
                      fontSize: 16,
                      fontWeight: "800",
                      letterSpacing: ".7px",
                      paddingLeft: "4px",
                      textTransform: "uppercase",
                    }}
                  >
                    {localStorage.getItem("firstname")}{" "}
                    {localStorage.getItem("lastname")}
                  </span>
                </div>
              </div>

              <div className="col">
                <div className="d-flex align-items-center justify-content-end">
                  <p
                    className="mb-0"
                    style={{
                      color: "#005284",
                      fontSize: 14,
                      fontWeight: "600",
                      textDecoration: "underline",
                      cursor: "pointer",
                    }}
                    onClick={() => this.props.history.push("/resume")}
                  >
                    Resume your modules
                    <img
                      src={require("../images/resume21.png")}
                      style={{ width: "18px", marginLeft: "10px" }}
                    />
                  </p>
                </div>
              </div>
            </div>

            <div className="row h-100">
              <div className="col-12 col-md-6">
                <div
                  className="mb-0 py-4"
                  style={{
                    borderRadius: 14,
                    background: "#f3f3f3",
                  }}
                >
                  <div>
                    <h4
                      style={{
                        color: "#005284",
                        textAlign: "center",
                        fontWeight: "600",
                        fontSize: 17.5,
                      }}
                      className="m-0"
                    >
                      Modules
                    </h4>
                  </div>

                  <div>
                    {slidedata.length !== 0 && (
                      <Slide
                        arrows={
                          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
                            navigator.userAgent
                          )
                            ? false
                            : true
                        }
                        indicators={true}
                        canSwipe={
                          /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile/i.test(
                            navigator.userAgent
                          )
                            ? true
                            : false
                        }
                        autoplay={false}
                      >
                        {slidedata.map((slideArea, j) => (
                          <div key={j} className="az-hover">
                            {slideArea.map((item) => {
                              return (
                                <div
                                  className="card az-hover-bg"
                                  style={{
                                    width: "80%",
                                    height: 100,
                                    position: "relative",
                                    margin: "18px auto",
                                    marginBottom: "10px",
                                    padding: 10,
                                    borderRadius: 10,
                                    display: "grid",
                                    gridTemplateColumns: "1.6fr 6fr 1fr",
                                    backgroundColor: "#ffffff",
                                    boxShadow: "none",
                                    cursor: "pointer",
                                  }}
                                >
                                  {item.image ? (
                                    <img
                                      src={item.image}
                                      alt="logo"
                                      className="responsive-img"
                                      style={{
                                        width: 50,
                                        height: 50,
                                        alignSelf: "center",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  ) : (
                                    <span
                                      className="letter"
                                      style={{
                                        width: 50,
                                        height: 50,
                                        fontSize: 16,
                                        alignSelf: "center",
                                        borderRadius: "50px",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {item.name.charAt(0)}
                                    </span>
                                  )}
                                  <div
                                    style={{ alignSelf: "center" }}
                                    onClick={() => this.btnAction(item)}
                                  >
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontWeight: "600",
                                        color: "#222222",
                                        fontSize: 13.5,
                                        textTransform: "capitalize",
                                        lineHeight: "initial",
                                      }}
                                    >
                                      {item.name}
                                    </p>

                                    <div
                                      className="topic"
                                      style={{
                                        display: "inline-flex",
                                        color: "#61727c",
                                        fontWeight: 700,
                                      }}
                                    >
                                      <p
                                        className="flex mZero"
                                        style={{
                                          fontSize: 11,
                                          marginRight: 5,
                                        }}
                                      >
                                        <span style={{ paddingRight: 5 }}>
                                          {item.models.length}{" "}
                                        </span>
                                        <span> Topic(s)</span>
                                      </p>{" "}
                                      |
                                      <p
                                        className="flex mZero"
                                        style={{
                                          fontSize: 11,
                                          marginLeft: 5,
                                        }}
                                      >
                                        <span style={{ paddingRight: 5 }}>
                                          1{" "}
                                        </span>
                                        <span> Unit(s)</span>{" "}
                                      </p>
                                    </div>
                                  </div>
                                  {/* <div style={{ display: "flex" }}>
                                      <div
                                        onClick={() =>
                                          this.props.history.push("/resume")
                                        }
                                        style={{
                                          alignSelf: "center",
                                          marginRight: "10px",
                                        }}
                                      >
                                        <img
                                          src={require("../images/resume21.png")}
                                          style={{ width: 30 }}
                                        />
                                      </div> */}
                                  {/* <div className=" text-right" style={{ alignSelf: 'center',border:'1px solid',height:25,width:25,borderRadius:'50%' }}>
                                                                                <i className="material-icons"> keyboard_arrow_right </i>
                                                                            </div> */}
                                  {/* </div> */}
                                </div>
                              );
                            })}
                          </div>
                        ))}
                      </Slide>
                    )}
                  </div>
                </div>
              </div>

              <div class="col-12 col-md-6">
                <div
                  className="py-4 px-3"
                  style={{
                    background: "#f3f3f3",
                    borderRadius: 14,
                  }}
                >
                  <h4
                    className="pb-2 m-0"
                    style={{
                      color: "#005284",
                      textAlign: "center",
                      fontWeight: "600",
                      fontSize: 17.5,
                    }}
                  >
                    Utilities
                  </h4>
                  <div>
                    <div className="row align-items-center justify-content-center px-2 px-md-0 az-hover">
                      <div className="col-6 col-md-4 px-1  pl-lg-2 mt-2 mt-md-3 ">
                        <div style={{ cursor: "pointer" }} className='az-hover-bg'>
                          <Card
                            type={"3"}
                            Title={"Collaborate"}
                            img={require("../images/group_icon1.png")}
                            Count={this.state.data.no_of_assigned_groups}
                            Push={this.props.history}
                            Url={"/groups"}
                            customFont="14px"
                            customFontWeight="500"
                            customCardHeight="85px"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-4 px-1  px-lg-2 mt-2 mt-md-3 ">
                        {/* <Card type={"3"} Title={"Resume"} img={require('../images/resume2.png')} Count={this.state.data.paused} Push={this.props.history} Url={"/resume"} /> */}
                        <div style={{ cursor: "pointer" }} className='az-hover-bg'>
                          <Card
                            type={"3"}
                            Title={"Actions"}
                            img={require("../images/action2.png")}
                            Push={this.props.history}
                            Url={"/action"}
                            customFont="14px"
                            customFontWeight="500"
                            customCardHeight="85px"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-4 px-1  pr-lg-2 mt-2 mt-md-3">
                        <div style={{ cursor: "pointer" }} className='az-hover-bg'>
                          <Card
                            type={"3"}
                            Title={"Checklists"}
                            img={require("../images/checkbox-multiple-marked-blue.png")}
                            Push={this.props.history}
                            Url={"/checklist"}
                            customFont="14px"
                            customFontWeight="500"
                            customCardHeight="85px"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-4 px-1  px-lg-2 mt-2 mt-md-3">
                        <div style={{ cursor: "pointer" }} className='az-hover-bg'>
                          <Card
                            type={"3"}
                            Title={"Documents"}
                            img={require("../images/forms.png")}
                            Push={this.props.history}
                            Url={"/suggestion"}
                            customFont="14px"
                            customFontWeight="500"
                            customCardHeight="85px"
                          />
                        </div>
                      </div>
                      <div className="col-6 col-md-4 px-1  px-lg-2 mt-2 mt-md-3">
                        <div style={{ cursor: "pointer" }} className='az-hover-bg'>
                          <Card
                            type={"3"}
                            Title={"Forms"}
                            img={require("../images/docs3.png")}
                            Push={this.props.history}
                            Url={"/observation"}
                            customFont="14px"
                            customFontWeight="500"
                            customCardHeight="85px"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className="py-4 px-3"
                  style={{
                    background: "#f3f3f3",
                    borderRadius: 14,
                    marginTop: 10,
                    // height: 300,
                  }}
                >
                  <h4
                    className="pb-2 m-0"
                    style={{
                      textAlign: "center",
                      color: "#005284",
                      fontWeight: "600",
                      fontSize: 17.5,
                    }}
                  >
                    Insights
                  </h4>

                  <div className="col-12 flex flex-column mt-3 az-hover">
                    <div
                      className="card mb-0 az-hover-bg"
                      onClick={() => this.props.history.push("/insight")}
                      style={{ cursor: "pointer" }}
                    >
                      <div className="row">
                        {/* <div className="col-md-12 text-center" style={{ paddingBottom: 2 }}>
                    <h4 >Insights</h4>
            </div> */}
                      </div>
                      <div className=" flex justify-content-between align-item-center">
                        <div
                          className="align-item-center justify-content-between"
                          style={{ width: "100%" }}
                        >
                          <div className="flex justify-content-center flex-column align-item-center">
                            <div
                              style={{
                                width: 128,
                                height: "auto",
                                position: "relative",
                              }}
                            >
                              <div className="index text-center">
                                <div>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontSize: "16.5px",
                                      fontWeight: 500,
                                    }}
                                  >
                                    {this.state.insights.insight_index ===
                                      null ||
                                    this.state.insights.insight_index === " " ||
                                    this.state.insights.insight_index === 0 ||
                                    this.state.insights.insight_index === "0"
                                      ? "No Data"
                                      : Math.round(
                                          this.state.insights.insight_index
                                        )}
                                  </p>
                                  <hr
                                    style={{
                                      margin: 0,
                                      borderTop: "1px solid rgb(0 0 0 / 26%)",
                                    }}
                                  />
                                  <p className="mb-0">
                                    <span
                                      style={{
                                        fontSize: 12.5,
                                        fontWeight: 400,
                                        color: "#222222",
                                      }}
                                    >
                                      Pull:{" "}
                                    </span>
                                    <span
                                      style={{ fontSize: 14, fontWeight: 500 }}
                                    >
                                      {Math.round(this.state.percentage)}%
                                    </span>
                                  </p>
                                </div>
                              </div>

                              {/* <div
                                className="index"
                                style={{
                                  display: "inline-block",
                                  bottom: "20px",
                                  top: "auto",
                                  textAlign: "center",
                                }}
                              >
                                {this.state.insights.insight_index === null
                                  ? "No Data"
                                  : Math.round(
                                      this.state.insights.insight_index
                                    )}
                                <div
                                  style={{
                                    height: 1,
                                    width: "60%",
                                    borderTop: "1px solid #d2d4d7",
                                    margin: "auto",
                                  }}
                                />
                                <hr></hr> 
                                <div
                                  style={{
                                    display: "flex",
                                    marginLeft: "30px",
                                  }}
                                >
                                  <div>
                                    {" "}
                                    <h6 style={{ fontSize: "12px" }}>Pull: </h6>
                                  </div>
                                  <div>
                                    {" "}
                                    <h6
                                      style={{ fontSize: "12px" }}
                                    >{`${Math.round(
                                      this.state.percentage
                                    )}%`}</h6>
                                  </div>
                                </div>
                              </div> */}

                              <Doughnut
                                data={{
                                  labels: [
                                    "High Risk",
                                    "Lucky",
                                    "Low Risk",
                                    "Risky",
                                  ],
                                  datasets: [
                                    {
                                      backgroundColor: [
                                        "#be1f24",
                                        "#005284",
                                        "#7ed321",
                                        "#f5a623",
                                      ],
                                      hoverBackgroundColor: [
                                        "#be1f24",
                                        "#005284",
                                        "#7ed321",
                                        "#f5a623",
                                      ],
                                      borderColor: [
                                        "#be1f24",
                                        "#005284",
                                        "#7ed321",
                                        "#f5a623",
                                      ],
                                      borderWidth: 1,
                                      data: [
                                        Math.round(this.state.insights.red),
                                        Math.round(this.state.insights.blue),
                                        Math.round(this.state.insights.green),
                                        Math.round(this.state.insights.orange),
                                      ],
                                      weight: 2,
                                    },
                                  ],
                                }}
                                width={140}
                                // height={100}
                                options={{
                                  title: {
                                    display: false,
                                  },
                                  legend: {
                                    display: false,
                                  },
                                  cutoutPercentage: 70,
                                }}
                              />
                            </div>

                            <h4
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#222222",
                              }}
                            >
                              Knowledge Index
                            </h4>
                          </div>

                          {/* <div className="flex justify-content-center flex-column align-item-center" >
                                                    <div style={{ width: 90, height: 'auto' }}>
                                                        <CircularProgressbar value={this.state.actionindex} text={`${Math.round(this.state.actionindex)}`} strokeWidth={9} />
                                                    </div>

                                                    <h4 style={{ margin: '2px' }}>Action Index</h4>
                                                </div> */}

                          <div className="flex justify-content-center flex-column align-item-center">
                            <div
                              style={{
                                width: 128,
                                height: "auto",
                                position: "relative",
                              }}
                            >
                              <div className="index">
                                <p
                                  className="mb-0"
                                  style={{
                                    fontSize: "16.5px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {this.state.actionindex === null ||
                                  this.state.actionindex === " " ||
                                  this.state.actionindex === 0 ||
                                  this.state.actionindex === "0"
                                    ? "No Data"
                                    : Math.round(this.state.actionindex)}
                                </p>
                              </div>

                              <Doughnut
                                data={{
                                  labels: [
                                    "High Risk",
                                    "Lucky",
                                    "Low Risk",
                                    "Risky",
                                  ],
                                  datasets: [
                                    {
                                      backgroundColor: [
                                        "#be1f244d",
                                        "#0052844d",
                                        "#7ed3214d",
                                        "#f5a6234d",
                                      ],
                                      hoverBackgroundColor: [
                                        "#be1f244d",
                                        "#0052844d",
                                        "#7ed3214d",
                                        "#f5a6234d",
                                      ],
                                      borderWidth: 0,
                                      data: [
                                        Math.round(this.state.actions.red),
                                        Math.round(this.state.actions.blue),
                                        Math.round(this.state.actions.green),
                                        Math.round(this.state.actions.orange),
                                      ],
                                      weight: 2,
                                    },
                                  ],
                                }}
                                width={120}
                                height={100}
                                options={{
                                  title: {
                                    display: false,
                                  },
                                  legend: {
                                    display: false,
                                  },
                                  cutoutPercentage: 80,
                                }}
                              />
                            </div>
                            <h4
                              style={{
                                fontSize: "12px",
                                fontWeight: 500,
                                color: "#222222",
                              }}
                            >
                              Action Index
                            </h4>
                          </div>

                          {/* pullrate */}
                          {/* <div style={{ width: 90, height: 'auto' }}>
                    <CircularProgressbar value={this.state.percentage} text={`${Math.round(this.state.percentage)}%`} strokeWidth={9} />
                </div> */}
                        </div>
                        <div className="align-item-center">
                          <span className="material-icons">
                            keyboard_arrow_right
                          </span>
                        </div>
                      </div>

                      <div>
                        <hr className="m-0" />

                        <h4
                          style={{
                            color: "#222222",
                            textAlign: "center",
                            fontSize: 14,
                          }}
                        >
                          Reactivation
                        </h4>
                      </div>

                      <div className="az-progress-chart">
                        <div>
                          <h5 className="mb-0">Participation :</h5>

                          <div className="d-flex align-items-center justify-content-start">
                            <div
                              style={{
                                width: "86%",
                                height: "100%",
                                border: "1px solid lightgrey",
                                borderRadius: 4,
                              }}
                            >
                              <div
                                style={{
                                  height: "12px",
                                  width: this.state.Participationscore + "%",
                                  background: "#005284",
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </div>

                            <p
                              className="mb-0 ml-3"
                              style={{
                                fontSize: 16,
                                fontWeight: 700,
                                color: "#005284",
                              }}
                            >{`${Math.round(
                              this.state.Participationscore
                            )}%`}</p>
                          </div>
                        </div>

                        <div>
                          <h5 className="mb-0">Score :</h5>

                          <div className="d-flex align-items-center justify-content-start">
                            <div
                              style={{
                                width: "86%",
                                height: "100%",
                                border: "1px solid lightgrey",
                                borderRadius: 4,
                              }}
                            >
                              <div
                                style={{
                                  height: "12px",
                                  width: this.state.reactivationscore + "%",
                                  background: "#005284",
                                  borderRadius: "4px",
                                }}
                              ></div>
                            </div>

                            <p
                              className="mb-0 ml-3"
                              style={{
                                fontSize: 16,
                                fontWeight: 700,
                                color: "#005284",
                              }}
                            >{`${Math.round(
                              this.state.reactivationscore
                            )}%`}</p>
                          </div>
                        </div>
                      </div>

                      {/* <div
                        style={{
                          flexDirection: "row",
                          padding: "5px",
                          marginBottom: 5,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ minWidth: "18%", height: "100%" }}>
                          <h5
                            style={{
                              marginBottom: "0px",
                              textAlign: "right",
                            }}
                          >
                            Participation :
                          </h5>
                        </div>
                        <div
                          style={{
                            minWidth: "70%",
                            height: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: 14,
                            marginLeft: 5,
                          }}
                        >
                          <div
                            style={{
                              height: "12px",
                              width: this.state.Participationscore + "%",
                              background: "#005284",
                              borderRadius: "4px",
                            }}
                          ></div>
                        </div>
                        <div style={{ minWidth: "7%", marginLeft: "5px" }}>
                          <h5 style={{ marginBottom: "0px" }}>{`${Math.round(
                            this.state.Participationscore
                          )}%`}</h5>
                        </div>
                      </div> */}

                      {/* <div
                        style={{
                          flexDirection: "row",
                          padding: "5px",
                          marginBottom: 5,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ minWidth: "18%", height: "100%" }}>
                          <h5
                            style={{
                              marginBottom: "0px",
                              textAlign: "right",
                            }}
                          >
                            Score :
                          </h5>
                        </div>
                        <div
                          style={{
                            minWidth: "70%",
                            height: "100%",
                            border: "1px solid lightgrey",
                            borderRadius: 14,
                            marginLeft: 5,
                          }}
                        >
                          <div
                            style={{
                              height: "12px",
                              width: this.state.reactivationscore + "%",
                              background: "#005284",
                              borderRadius: "4px",
                            }}
                          ></div>
                        </div>
                        <div style={{ minWidth: "7%", marginLeft: "5px" }}>
                          <h5 style={{ marginBottom: "0px" }}>{`${Math.round(
                            this.state.reactivationscore
                          )}%`}</h5>
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div
                    className="col-12 flex  flex-column"
                    style={{ padding: 5, marginTop: -5 }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {!this.state.loading1 && !this.state.loading2 && this.props.loader && (
          <Loader />
        )}

        {/* </ReactCSSTransitionGroup> */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    data: state.assign.data,
    loader: state.assign.loading,
    error: state.assign.error,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAreaData: () => dispatch(fetchData()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
