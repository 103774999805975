import React, { Component } from 'react';
import '../css/App.css';
import API from '../config_api'
import axios from 'axios'
import FormInput from '../component/FormInput';
import Button from '../component/Button';

class EnterpriseLogin extends Component {
    constructor(props) {
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this);
    }
    componentWillMount() {
        
        if (localStorage.getItem('token')) {
            this.props.history.push('/dashboard')
        }else{
            // if(window.location.href.includes('webapp.acuizen.com')){
            // window.location.href="https://app.acuizen.com";
            // }else{
            //     window.location.href="https://courses.wavemakers.in/wavelogin";
            // }
        }
        // if (localStorage.getItem('company_id')) {
        //     this.props.history.push('/login')
        // }
    }
    handleSubmit(event) {
        event.preventDefault();
        let formData = new FormData();
        formData.append('company_id', event.target[0].value)
        this.setState({ value: event.target[0].value });
        axios({
            method: 'post',
            url: API.Enterprise,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res)
                if (res.data.status === 'success') {

                    localStorage.setItem('logo', res.data.data.logo);
                    localStorage.setItem('company_id', this.state.value);
                    this.props.history.push('/splash');
                }
                else {
                    alert(res.data.message)
                }
            });

    }
    render() {
        return (
            <div className="main-content h-h align-item-center">
                
                <div className="container ">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-7">
                            <div className="card  border-0 mb-0 pad_z">

                                <div className="card-body px-lg-5 py-lg-5">
                                    <div className="text-center">
                                        <img src={require('../images/az_logo_all.png')} alt="az_logo" className="img-responsive mb-40" />
                                    </div>
                                    <div className="text-center text-muted mb-4">
                                        <small>Lets get Started</small>
                                    </div>
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-group mb-3">
                                            <div className="input-group input-group-merge">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text"><i className="ni ni-badge"></i></span>
                                                </div>
                                                <FormInput name={"enterpriseID"} placeholder={"Enter Enterprise ID"} type={"text"} />
                                            </div>
                                        </div>
                                        <div className="text-center">
                                            <Button color={"btn-primary az-color "} type={"submit"} variant={"my-4"} size={"large"}>Submit</Button>
                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default EnterpriseLogin;