import React, { Component } from "react";
import { Link } from "react-router-dom";
import Sidebar from "react-sidebar";
import "../css/App.css";
import "../css/sidebar.css";
import AcuiZenLogo from "../images/acuizen-logo.png";

class Sidenav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      style: "menu",
      menuStatus: "open",
      status: false,
      sidebarOpen: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }
  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  handleClick() {
    switch (this.state.menuStatus) {
      case "open":
        this.setState({
          menuStatus: "close",
          style: "menu active",
          status: true,
        });
        break;
      case "close":
        this.setState({
          menuStatus: "open",
          style: "menu",
          status: false,
        });
        break;
      default:
        break;
    }
  }

  render() {
    const navLinks = [
      { url: "/myaccount", name: "My Account" },
      { url: "/changepassword", name: "Change Password" },
      { url: "https://remote.acuizen.com/", name: "Remote" },
      { url: "/privacy", name: "Privacy Policy" },
      { url: "/about", name: "About Us" },
      { url: "/logout", name: "Logout" },
    ];

    const azSidebarList = {
      color: "red !important",
    };

    if (this.props.type === "main") {
      console.log(this.props);
      return (
        <div>
          {localStorage.getItem("company_no") === "457" ? (
            <Sidebar
              sidebar={
                <ul className="pt-4">
                  <li className="text-center pb-4">
                    <img
                      src={AcuiZenLogo}
                      style={{ width: "130px", height: "auto" }}
                    />
                  </li>
                  <li>
                    <Link to={"/myaccount"} className={"az-list-link"}>
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link to={"/changepassword"} className={"az-list-link"}>
                      Change Password
                    </Link>
                  </li>

                  <li>
                    <Link to={"/privacy"} className={"az-list-link"}>
                      Privacy Policy
                    </Link>
                  </li>
                  {/* <li  >
                                <Link to={'/about'}>About Us</Link>
                            </li> */}
                  <li>
                    <Link to={"/logout"} className={"az-list-link"}>
                      Logout
                    </Link>
                  </li>
                </ul>
              }
              open={this.state.sidebarOpen}
              onSetOpen={this.onSetSidebarOpen}
              sidebarClassName={"menu"}
              styles={{
                sidebar: {
                  background: "#F5FAFE",
                  zIndex: 3,
                  color: "#005284",
                },
              }}
              overlayClassName={"overclass"}
            />
          ) : (
            <Sidebar
              sidebar={
                <ul className="pt-4">
                  <li className="text-center pb-4">
                    <img
                      src={AcuiZenLogo}
                      style={{ width: "130px", height: "auto" }}
                    />
                  </li>
                  <li>
                    <Link to={"/myaccount"} className={"az-list-link"}>
                      My Account
                    </Link>
                  </li>
                  <li>
                    <Link to={"/changepassword"} className={"az-list-link"}>
                      Change Password
                    </Link>
                  </li>

                  <li>
                    <Link
                      to={
                        "https://remote.acuizen.com/?session=" +
                        window.btoa(localStorage.getItem("token")) +
                        "&id=" +
                        window.btoa(localStorage.getItem("firstname"))
                      }
                      className={"az-list-link"}
                    >
                      Remote
                    </Link>
                  </li>
                  <li>
                    <Link to={"/privacy"} className={"az-list-link"}>
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className={"az-list-link"}>
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to={"/logout"} className={"az-list-link"}>
                      Logout
                    </Link>
                  </li>
                </ul>
              }
              open={this.state.sidebarOpen}
              onSetOpen={this.onSetSidebarOpen}
              sidebarClassName={"menu"}
              styles={{
                sidebar: {
                  background: "#F5FAFE",
                  zIndex: 3,
                  color: "#005284",
                },
              }}
              overlayClassName={"overclass"}
            />
          )}

          <nav
            className="navbar navbar-top navbar-expand navbar-dark  border-bottom py-2"
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              zIndex: 1,
            }}
          >
            <div className="container px-0 px-md-3">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="col-3 px-0">
                  <i
                    className="material-icons"
                    onClick={() => this.onSetSidebarOpen(true)}
                    style={{
                      lineHeight: "2",
                      color: "#005284",
                      cursor: "pointer",
                    }}
                  >
                    menu
                  </i>
                </div>
                <div className="col-6 text-center">
                  {" "}
                  <img
                    src={localStorage.getItem("logo")}
                    alt="wavemakers_logo"
                    className="responsive-img"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="col-3 text-right  vertical-align justify-content-end pad_z">
                  <i
                    className="material-icons"
                    onClick={() => this.props.push.push("/search")}
                    style={{ paddingRight: 10, color: "#005284" }}
                  >
                    search
                  </i>
                  <i
                    onClick={() => this.props.push.push("/notification")}
                    className="material-icons"
                    style={{ color: "#005284" }}
                  >
                    notifications_none
                  </i>
                  {this.props.notification ? (
                    <i className="fa fa-circle text-danger Blink"></i>
                  ) : (
                    ""
                  )}{" "}
                </div>
              </div>
            </div>
          </nav>

          <div style={{ height: 82 }}></div>
        </div>
      );
    } else if (this.props.type === "second") {
      return (
        <div>
          <nav
            className="navbar navbar-top navbar-expand navbar-dark  border-bottom py-2"
            style={{
              position: "fixed",
              left: 0,
              right: 0,
              top: 0,
              zIndex: 1,
            }}
          >
            <div className="container px-0 px-md-3">
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="col-3 px-0">
                  {this.props.url === "back" ? (
                    <i
                      onClick={() => this.props.Push.goBack()}
                      className="material-icons back-btn grey-text"
                      style={{ lineHeight: "2" }}
                    >
                      arrow_back
                    </i>
                  ) : (
                    <Link to={this.props.url}>
                      <i
                        className="material-icons back-btn grey-text"
                        style={{ lineHeight: "2" }}
                      >
                        arrow_back
                      </i>
                    </Link>
                  )}
                </div>
                <div className="col-6 text-center">
                  {" "}
                  <img
                    src={localStorage.getItem("logo")}
                    alt="wavemakers_logo"
                    className="responsive-img"
                    style={{ width: "100px", height: "auto" }}
                  />
                </div>
                <div className="col-3 text-right  vertical-align justify-content-end pad_z"></div>
              </div>
            </div>
          </nav>
          <div style={{ height: 82 }}></div>
        </div>
      );
    }
  }
}

export default Sidenav;
