import React, { Component } from 'react';
import '../css/App.css';
import API from '../config_api'
import axios from 'axios'
import Sidenav from './Sidenav'
import MdPeople from 'react-ionicons/lib/MdPeople'
class Groups extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],

        };

    }

    componentWillMount() {
        if (!localStorage.getItem('token')) {
            this.props.history.push('/login')
        }
        let formData = new FormData();
        formData.append('userid', localStorage.getItem('token'))
        axios({
            method: 'post',
            url: API.Groups,
            data: formData,
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            },
            mode: 'no-cors',
        })
            .then(res => {
                console.log(res.data.data)

                this.setState({ data: res.data.data })

            });
    }
    btnAction(data) {
        console.log(data);
        this.props.history.push('/group/' + data.group_id, { data })
        localStorage.setItem('group_name', data.group_name)
    }


    render() {
        return (
            <div className="az-resume">
                <Sidenav type={'second'} url={'/dashboard'} />

                <div className="container">
                   
                <div className="row justify-content-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-10 col-xl-8">
                            <div className="p-4 mt-4" style={{ background: "#ffffff", borderRadius: "22px" }}>
                                <h5 className='text-center' style={{ fontSize: '16px',color: '#005284',fontWeight: '700',}}>Groups</h5>


                                <div className='row az-hover'>


{this.state.data.map((data, i) =>

                                <div className='col-12 ' style={{marginTop: '28px'}} key={i}>
                                    <div className='card py-4 az-hover-bg' style={{background: '#f3f3f3', cursor: 'pointer'}} onClick={() => this.btnAction(data)}>
                                        <div className='d-flex align-items-center justify-content-between'>
                                            <div className='d-flex align-items-center'>
                                                <div className='px-3'>
                                                    <MdPeople fontSize="50px" color="#D6D6D6" />
                                                        <span style={{
                                                            position: 'relative',
                                                            top: '-19px',
                                                            left: '-9px',
                                                            fontWeight: 500,
                                                            fontSize: '14px',
                                                        }}>{data.members}
                                                        </span>
                                                </div>
                                               <div>
                                               <p className="mb-0" 
                                               style={{
                                                fontSize: '15px',
                                                fontWeight: '600',
                                                color: '#005284',
                                                textTransform: 'capitalize',
                                               }}
                                               >{data.group_name}</p>
                                                <p className="mb-0" 
                                                style={{
                                                    fontSize: '12px',
                                                    fontWeight: '600',
                                                    color: '#3c3c3c',
                                                    textTransform: 'capitalize',

                                                }}
                                                >{data.facilitator_name ? data.facilitator_name : 'No Facilitator'} - {data.topic_count} topic(s)</p> 
                                                </div>
                                            </div>
                                            <div style={{border:'.75px solid #3c3c3c',color: '#3c3c3c',borderRadius:'50%',height:'25px',width:'25px'}}> <i className="material-icons">
                                            keyboard_arrow_right
                                         </i></div>
                                        </div>
                                    </div>
                                </div>


                            )}



                        {/* <div className="col-sm-9 " style={{margin:'auto'}}>
                            {this.state.data.map((data, i) =>
                                <div className="row" style={{ margin: '10px 0' }} key={i} onClick={() => this.btnAction(data)}>
                                    <div className="card mZero flex-row align-item-center col-md-12"   >
                                        <div className="col-md-2 text-center"><MdPeople fontSize="50px" color="#D6D6D6"/><span style={{
                                            position: 'relative',
                                            top: -21,
                                            left: 7
                                        }}>{data.members}</span></div>
                                        <div className="col-md-9"><h4 className=" mZero" >{data.group_name}</h4><p className="mZero">{data.facilitator_name ? data.facilitator_name : 'No Facilitator'} - {data.topic_count} topic(s)</p> </div>

                                        <div className="text-right" style={{border:'1px solid',borderRadius:'50%',height:'25px',width:'25px'}}> <i className="material-icons">
                                            keyboard_arrow_right
                 </i></div>

                                    </div>
                                </div>
                            )}
                        */}
                                </div>


</div>
</div>
</div>

</div>
</div>
        )
    }
}
export default Groups;